.redsoda-textarea {
  border: thin solid @Grey !important;
  padding: 10px;
  margin: 2rem auto;
  max-width: calc(100% - 4rem) !important;
  display: block;
}

.radio-inputs {
  &__wrapper {
    padding-left: 5rem;
    margin-top: 1rem;
  }
}

.input-search {
  &__variant-list {
    position: absolute;
    list-style: none;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1;
    padding: 0;
    box-shadow: 0 0.1rem 0.3rem;
    cursor: pointer;

    &-item {
      list-style: none;
      padding: 0.8rem 1rem;
      border-bottom: thin solid @Grey;
    }
  }

  &__wrapper {
    position: relative;
    line-height: 1.2;
  }
}

/* REDS-4835 */
.ant-input-number-handler-wrap {
  .ant-input-number-handler {
    &&:hover {
      height: 50% !important;
    }
  }

  &:hover {
    .ant-input-number-handler {
      height: 50% !important;
    }
  }
}
