.terms-conditions {
  &-link {
    && {
      text-transform: uppercase;
      font-size: 14px;
      line-height: 1;
      color: @Black;

      &:hover {
        color: @QuoteBuilderLinkHoverOrClicked;
      }
    }
  }
}
