.profile-avatar-upload {
  .size(160px);
  .rounded-borders();

  position: relative;
  transition: all @animation-duration-slow @ease-base-out;
  cursor: pointer;

  &:hover {
    filter: brightness(90%);
  }

  &-text {
    .rounded-borders();

    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 24px;
    color: @white;
  }

  &:hover &-text {
    display: inline;
  }

  .profile-avatar-img {
    .size(160px);
    .rounded-borders();
  }
}

.profile-user-status-text {
  .spacing(0, 0 @spacing-1);

  color: @white !important;
  border-radius: 20px;
}

.profile-mobile-input-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  .flex-display();
  .align-items(center);
}
