.navbar-profile-dropdown {
  .ant-dropdown-menu-submenu-title {
    padding-right: 48px;
    padding-left: 16px;

    &:hover {
      background-color: lighten(#f5f5f5, 1%);
    }
  }

  .ant-dropdown-menu-item {
    .padding-horizontal(16px);

    &:hover {
      background-color: lighten(#f5f5f5, 1%);
    }
  }

  .nav-horizontal-role-wrapper + .ant-dropdown-menu-submenu-arrow {
    right: 16px;
  }
}
