.react-tel-input input[type='tel'],
.react-tel-input input[type='text'] {
  border-radius: 0 !important;
}

.react-tel-input .flag-dropdown {
  border-radius: 0 !important;
}

.react-tel-input .selected-flag {
  border-radius: 0 !important;
}
