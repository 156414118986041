.notes {
  &-button {
    .button-primary;

    margin: 2rem auto;

    &.edit-notes {
      color: @primary-color;
      margin: 2rem auto 0;
    }
  }
}

.dragged-wrap {
  & > .opened[data-rbd-scroll-container-context-id] {
    h4 {
      span {
        color: @White;
      }
    }
  }
}

.booking {
  &-mobile-padded-row {
    @media (max-width: @screen-lg-max) {
      .padding-horizontal(16px);
    }
  }

  &-mobile-padded-row-10-16 {
    .padding-horizontal-desktop-mobile(10px, 16px);
  }

  &-mobile-padded-row-10-0 {
    .padding-horizontal-desktop-mobile(10px, 0);
  }

  &-content__wrap {
    flex-wrap: nowrap;
    overflow: hidden;
  }

  &-list {
    &-title {
      &:extend(.rk-title-wrap);

      @media (max-width: @screen-xs) {
        font-size: 16px;
        white-space: nowrap;
      }

      &-wrap {
        &:extend(.ph-8);

        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        align-items: center;

        .buttons-list-title-wrap {
          display: flex;
          margin-bottom: 3px;
        }
      }
    }
  }

  &-event-timeline {
    && {
      margin-left: 5rem;
      padding: 2rem 0 0 1rem;

      @media (max-width: @screen-lg-max) {
        margin-left: 5.3rem;
        padding-right: 0.4rem;
      }
    }

    &-item {
      && {
        user-select: none;
        position: relative;
        font-size: @UsualBookingFZ;
        padding-bottom: 1rem;
        color: @TransparentBlack;

        .rk-delete-icon {
          color: @DarkGrey2;
          transform: scale(0.5);
          opacity: 0;

          &:hover {
            color: @Red;
          }
        }

        &:hover {
          .rk-delete-icon {
            transform: scale(1);
            opacity: 1;
          }
        }

        @media (max-width: @screen-md-max) {
          .rk-delete-icon {
            opacity: 1;
            transform: scale(1);
          }
        }
      }

      &__wrapper {
        flex-wrap: nowrap;
      }

      &__info {
        display: inline-block;
        width: calc(100% - 36px);
      }

      &__description {
        line-height: normal;
      }

      &__time {
        display: inline-block;
        padding: 0 1rem;
        font-weight: bold;
        margin-bottom: 0.6rem;
        margin-right: 0.5rem;
        position: absolute;
        left: -7.7rem;
        top: 0;
      }
    }

    .ant-timeline-item-head {
      top: 0.5rem;
    }

    .ant-timeline-item-content {
      top: -1px;
      margin-left: 20px;
    }
  }

  &-payment {
    &__wrapper {
      margin-top: 4rem;
    }

    &__buttons-row {
      flex-direction: column;
      margin-top: 2rem;
    }
  }

  &-musician-offers {
    &__text {
      margin-top: 15px;
      .padding-horizontal(10px);

      @media (max-width: @screen-lg-max) {
        .padding-horizontal(16px);
      }
    }

    &__wrapper {
      .send-musician-offer-button {
        margin: auto;
        margin-top: 15px;
      }
    }
  }

  &-status-bar {
    &__wrapper {
      position: fixed;
      z-index: 100;
      left: 0;
      right: 0;
      bottom: 0;
      background: @Grey;

      &_is-musician {
        bottom: 50px;
      }

      @media (min-width: @screen-md) {
        display: none;
      }
    }

    &__icon-wrapper {
      margin: 0;
      padding: 1.5rem 1rem;
      background-color: #d8d8d8;
      color: @Text;
      cursor: pointer;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;

      &_active {
        background-color: @Primary;
        color: @White;
      }

      &_disabled {
        pointer-events: none;
        background-color: #f5f5f5;
        color: rgba(0, 0, 0, 0.25);
      }

      .svg-inline--fa {
        font-size: 16px;
      }
    }
  }

  &_section_between_margin {
    margin-top: 20px;
  }

  &-list-col-collapsed {
    &__section {
      position: relative;
      overflow: hidden;
    }
  }

  &-section {
    &.modal-section {
      margin: auto;
      pointer-events: auto;

      .ant-modal {
        &-content {
          overflow-y: scroll;
        }

        &-body {
          padding: 0;
          max-height: unset;
        }
      }
    }
  }
}

.timeline {
  &-event-date {
    &__wrapper {
      margin-top: 16px;
      .padding-horizontal(10px);

      @media (max-width: @screen-lg-max) {
        .padding-horizontal(16px);
      }
    }

    &__diff {
      &-text {
        color: @Text;
      }
    }
  }
}

.musics {
  &-panel {
    .collapse-panel__header-wrapper {
      padding-left: 10px;

      @media (max-width: @screen-lg-max) {
        padding-left: 0;
      }
    }

    .collapse-panel__expanding-wrapper {
      span {
        font-weight: 700;
      }
    }
  }

  &-expanded-area {
    &-wrapper {
      padding: 1.4rem 4rem 1rem 1rem;
      position: relative;
      border-bottom: thin solid @Grey;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      @media (max-width: @screen-lg-max) {
        padding: 1.4rem 4rem 1rem 0;
      }
    }

    &-content {
      font-size: @UsualBookingFZ;
    }
  }

  &-list-icon {
    margin-right: 0.8rem;
    color: @TransparentBlack;
  }

  &-key-songs {
    &-files-list {
      list-style: none;
      padding-left: 0;
      margin: 0;
    }
  }

  &-top-songs {
    &-number {
      color: @DarkGrey;
      margin-right: 0.5rem;

      &.active {
        color: @TransparentBlack;
      }
    }
  }

  &-delete-button {
    && {
      border: thin solid transparent;
      box-shadow: none;
      position: absolute !important;
      left: 0;
      bottom: 0;
      height: 36px;
    }
  }

  &-small-edit-icon-button {
    &:extend(.action-icon-button);

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;
    max-height: 4rem;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    max-width: 4rem;
    background: none;
    outline: none;
    cursor: pointer;

    @media (max-width: @screen-lg-max) {
      right: -1rem;
    }
  }

  &-small-delete-icon-button {
    &:extend(.action-icon-button);

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4rem;
    max-height: 4rem;
    position: absolute;
    top: 0.5rem;
    right: 0;
    max-width: 4rem;
    background: none;
    outline: none;
    cursor: pointer;

    @media (max-width: @screen-lg-max) {
      right: -1rem;
    }
  }

  &-delete-icon {
    right: 4rem;
  }
}

.add-price {
  &-button {
    &:extend(.button-wide-add);

    padding: 1.1rem 1rem;
    width: auto;
    margin-top: 0;
  }
}

.gig-title-wrap {
  margin-bottom: 1rem;
  position: relative;
  line-height: 1;

  & .rk-button__right {
    position: absolute;
    right: -10px;
    top: -10px;

    @media not all and (min-width: @screen-lg) {
      right: 0;
    }
  }
}

.gig-list {
  &__item {
    padding-bottom: 1rem;
    padding-top: 1rem;
    position: relative;
    width: 100%;

    &-musician {
      font-size: @UsualBookingFZ;
      color: @TransparentBlack;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: @screen-xl) {
        padding-left: 0;
      }
    }

    &-wrapper {
      border-bottom: thin solid @Grey;
      border-top: none;
      padding: 0;
      width: 100%;

      &.rk-without-border {
        border-bottom: none;
      }
    }

    &-overflow {
      text-overflow: ellipsis;
      width: 100%;
      padding: 0 10px;
      white-space: nowrap;
      overflow: hidden;
      line-height: 1;
      margin: auto;

      @media (max-width: @screen-lg-max) {
        width: 100%;
        padding: 0;
      }

      &:hover {
        overflow: visible;
        text-overflow: unset;
      }
    }

    &-time {
      font-size: 1rem;
      color: @DarkGrey;

      @media (max-width: @screen-xl) {
        padding-left: 0;
      }
    }

    &-content {
      padding: 8px 20px 4px;

      @media (max-width: @screen-lg-max) {
        padding: 8px 0 4px;
      }

      &-header {
        .padding-horizontal(10px);

        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        @media (max-width: @screen-lg-max) {
          .padding-horizontal(0);
        }

        &-title {
          white-space: nowrap;
          color: #9b9b9b;
          text-transform: uppercase;
          font: normal normal bold 12px/15px Lato;
        }

        &-buttons {
          white-space: nowrap;
          line-height: 1;

          .ant-btn {
            font-size: 12px;
            padding: 0;
            height: auto;
          }
        }

        &-payment-button {
          .svg-inline--fa {
            margin-right: 5px;
          }

          span {
            color: @Primary;
          }
        }
      }
    }
  }

  &__row-with-primary-bottom {
    padding: 1rem 0;
    position: relative;
    width: 100%;
    border-bottom: thin solid @Primary;

    @media (max-width: @screen-lg-max) {
      padding: 1rem;
    }
  }

  &__collapse {
    .collapse-panel__expanding-area {
      border-bottom: thin solid @BorderColor2;
      padding-bottom: 0;
    }

    .gig-list__item {
      padding: 0;

      &-wrapper {
        border-bottom: 0;

        .allocate-singer-icon {
          text-align: right;
          color: #b7964e;
          font-size: 16px;
        }
      }

      &-content-header {
        min-height: 35px;
        align-items: center;
        background-color: #fff;
      }

      &-musician {
        &.archived b {
          color: #004ce6;
        }
      }
    }

    .ersatz-table-row {
      .padding-horizontal(0);

      @media (max-width: @screen-lg-max) {
        .margin-horizontal(0);
        .padding-horizontal(10px);

        width: 100%;
      }
    }
  }
}

.position-payment-modal {
  position: relative;

  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .ant-modal-header {
    padding: 16px 14px 10px;
  }

  .ant-modal-title {
    font-size: 18px;
  }

  .ant-modal-body {
    padding: 20px 14px;
  }
}

.position-history {
  &__item {
    white-space: nowrap;
    font-size: 12px;
    display: flex;
    border-top: thin solid @Grey2;
    padding: 6px 10px;

    @media (max-width: @screen-lg-max) {
      .padding-horizontal(0);
    }

    &-date {
      width: 110px;
      margin-right: 8px;
    }

    &-status {
      width: 60px;
      margin-right: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-fullname {
      width: calc(100% - 186px);
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.musician-payment {
  &-table {
    &__column-header {
      line-height: 10px;
      vertical-align: bottom;
      opacity: 0.5;
      text-transform: uppercase;
    }
  }

  &-row {
    flex-wrap: nowrap;

    &-name-wrap {
      &:extend(.text-overflow);

      padding-right: 5px;

      b {
        &:extend(.text-overflow);

        margin-right: 10px;
      }
    }
  }

  &-panel {
    border-bottom: 1px solid @Primary;

    .collapse-panel__header-wrapper {
      padding-left: 10px;
    }
  }

  &-add-adjustment-btn {
    width: 100%;
    margin-top: 20px;
  }
}

.price {
  &-item {
    &-title {
      display: flex;
      align-items: center;

      &__tooltip {
        margin-left: 5px;
        position: relative;

        svg {
          color: #b7964e;
          font-size: 22px;
          max-width: 26px;
        }

        &--description {
          display: none;
          position: absolute;
          bottom: calc(100% + 11px);
          left: 50%;
          transform: translateX(-50%);
          background-color: #595959;
          min-width: 180px;
          color: #fff;
          font-size: 12px;
          text-align: center;
          padding: 8px;
          border-radius: 3px;
          z-index: 10;

          &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            background-color: transparent;
            width: 100%;
            height: 20px;
          }

          &::before {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #595959;
          }

          &__button {
            button {
              margin: 8px 0 4px;
              background-color: #b7964e;
              border: none;
              outline: none;
              padding: 5px 20px;
              line-height: 1;
              font-weight: 600;
              cursor: pointer;
              transition: all 0.3s;

              &:hover {
                background-color: #edbd54;
              }
            }
          }
        }

        &:hover &--description {
          display: block;
        }
      }
    }

    &-wrapper {
      border-bottom: thin solid @Grey2;

      & .rk-edit-icon {
        opacity: 1;
        transform: scale(1);
        border: none;
        padding: 0 4px;
        height: 24px;
        margin: 4px 8px 4px 0;
      }

      @media (max-width: @screen-lg) {
        padding-left: 0;
        padding-right: 0;

        & .rk-edit-icon {
          opacity: 1;
          transform: scale(1);
        }
      }

      &-title {
        font-size: @UsualBookingFZ;
        color: @TransparentBlack;
      }
    }
  }

  &-adjustment-modal {
    .adjustment-amount-box {
      height: 36px;

      .ant-input {
        height: 26px;
      }
    }
  }
}

.submit-inquiry-form {
  padding: 12% 17% !important;
}

.qb-text-primary-color {
  color: @Black !important;
}

.qb-go-to-website {
  font-size: 14px !important;
  padding-top: 15px;
  cursor: pointer;
}

.qb-note-information {
  margin-top: 5px;
  margin-bottom: 20px;
}

.reception-modal-item-des {
  &.qb-scroll {
    overflow-y: hidden;
  }

  &.qb-scroll.opened {
    overflow-y: auto;
  }

  p {
    color: #909090;
  }
}

.event-note-rich-text-editor {
  width: 100%;
}

.ql-editor {
  min-height: 20em;
}

.rich-text {
  &-note {
    &__description {
      font: normal normal normal 14px/17px Lato;
      letter-spacing: 0;
      color: @DarkGrey;
      margin-bottom: 16px;
      margin-top: 16px;
    }

    &__container {
      padding: 1rem 0 0;

      // display: table-caption;
      h1 {
        font-size: 2.5em;
      }

      h2 {
        font-size: 1.5em;
      }

      h4 {
        font-size: 0.75em;
      }

      blockquote {
        border-left: 4px solid #ccc;
        padding-left: 14px;
      }
    }
  }

  &-editor-modal {
    .ant-modal-body {
      padding: 18px;
    }
  }

  &-note__markDown-placeholder {
    text-align: left;
    color: #9b9b9b;
    font-size: 14px;
    padding: 10px 5px;
  }
}

.unlock-price-modal .ant-form {
  padding: 10% 17% !important;
}

.payment-history {
  &__table {
    width: 100%;
  }

  &__title {
    font-size: 1.4rem;
    font-weight: 550;
    padding-left: 1rem;

    @media (max-width: @screen-lg-max) {
      padding-left: 0;
    }
  }

  &__row {
    justify-content: left;
    padding: 0.5rem 1rem;
    font-size: 1.4rem;
    border-bottom: thin solid @Grey;
    display: flex;
    align-items: center;
    color: @TransparentBlack;
    width: 100%;

    @media (max-width: @screen-lg) {
      padding: 0.5rem 0;
    }

    span:not(:last-child) {
      padding-right: 0.8rem;
    }

    &-date {
      white-space: nowrap;
      width: 110px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-status {
      white-space: nowrap;
      width: 60px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-type {
      white-space: nowrap;
      width: calc(100% - 172px);
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.musician-missed-offer-message {
  margin: 2rem auto;
  text-align: center;

  &-title {
    color: @primary-color;
    font-size: 1.8rem;
    font-weight: bold;
  }
}

.ant-input-number.adjustment-amount-box {
  width: 100%;
}

.delete-adjustment-icon {
  color: #9b9b9b;
}

.delete-adjustment-icon:hover {
  color: #b74e4e;
}

.moment-name-input {
  width: 100%;
  margin-top: 1.8rem;
}

.ant-form-item.add-song-radio-group {
  flex-direction: row;
  width: 100%;
  margin-top: 1.8rem;

  .ant-form-item-label {
    margin: auto;
    padding-top: 5px;
  }
}

.add-key-song-input {
  width: 100%;
  margin-top: 0.5rem;
}

.timeline-key-song {
  display: block;

  .sub-text {
    color: lightgray;
    font-size: 1.6rem;
  }
}

.confirm {
  &-button {
    &__wrapper {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }
  }
}

.firm-price-adjustment-modal {
  .ant-modal-header {
    padding: 1.6rem;
  }

  .ant-modal-body {
    padding: 2.4rem 1.6rem;
  }

  .position-name-autocomplete {
    height: 36px;

    .ant-input {
      height: 28px;
    }

    .ant-select-selector {
      height: 36px;
    }

    .ant-select-selection {
      &-item {
        line-height: 36px;
      }

      &-placeholder {
        line-height: 36px;
      }
    }
  }

  .expanding-checkbox {
    margin-bottom: 1rem;
  }

  .collapse-panel__expanding-area {
    border-bottom: none;
    overflow: unset;
  }

  .ant-input-number-input {
    height: 36px;
  }

  .link-musician-payment {
    &-item {
      position: relative;
      border-top: thin solid #e6e6e6;
      margin-top: 12px;

      &:first-child {
        border-top: thin solid transparent;
        // margin-top: 0;
      }

      .deposit-request-fix-amount {
        display: flex;
        margin-top: 1rem;

        & > span {
          transform: translateY(3px);
          color: #595959;
        }

        .ant-form-item {
          margin: 0 0 0 8px;
        }
      }

      .close-btn {
        position: absolute;
        right: 0;
        top: 10px;
        color: @Primary;
        z-index: 10;
      }

      .hidden {
        display: none;
      }
    }

    .add-more-musician-payment {
      margin-top: 20px;
      font-size: 12px;
      font-weight: 600;
      width: 100%;
      color: #474747;
      transition: all 0.3s;

      &:not([disabled]):hover {
        color: @Primary;
      }

      &[disabled] {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }

  .subsection-title {
    align-items: center;
  }
}

.payment-request-modal {
  .ant-modal-body {
    padding: 10px 20px;
  }

  &-group {
    margin: 10px auto 24px;
    width: 100%;

    .ant-form-item {
      margin-bottom: 0;
    }

    .percentage-input {
      display: block;
      width: 80px;
      position: relative;

      .ant-input-number {
        line-height: 1;
        font-weight: 700;
        width: 100%;

        input {
          text-align: right;
          padding-right: 25px;
          height: 25px;

          &:disabled {
            color: rgba(0, 0, 0, 0.25);
          }
        }
      }
    }

    .currency-input {
      display: block;
      width: 16rem;
      position: relative;

      .ant-input-number {
        line-height: 1;
        font-weight: 700;
        width: 100%;

        input:disabled {
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }

    .ant-radio-wrapper {
      display: block;
      margin-top: 1rem;
      font-size: 14px;
    }

    &__radio {
      display: block;
      line-height: 1;

      &-label {
        white-space: nowrap;
      }

      &-wrapper {
        display: flex;
        align-items: center;
      }
    }
  }

  .amount-to-pay {
    &__wrapper {
      border-top: thin solid @Grey;
      border-bottom: thin solid @Grey;
      color: @TransparentBlack;
      font-weight: 700;
      text-align: center;
      padding: 0.5rem 0;
      margin-bottom: 2rem;
    }
  }
}

.add-key-song-input {
  .custom-require-mark {
    margin-left: 2px;
  }
}

.singer-list {
  &__subheading {
    font-size: @UsualBookingFZ;
    color: rgba(0, 0, 0, 0.45);
    text-transform: uppercase;
    margin-right: 10px;
  }
}

.price-row {
  padding: 8px 10px 9px;
}

.long-text-button {
  width: 280px !important;
}

.musician-accept-other-gig-warning {
  .ant-tooltip-inner {
    color: black !important;
  }
}

.customer {
  &-info-modal .ant-select-selection-search {
    .ant-input-suffix {
      color: #bfbfbf;
      font-size: 12px;
    }
  }

  &-info-modal
    .ant-select-single.ant-select-customize-input
    .ant-select-selector
    .ant-select-selection-placeholder {
    padding: 3px 11px;
  }

  &-info-modal
    .ant-select-single
    .ant-select-selector
    .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  &__inner {
    border-bottom: thin solid @Grey;
    padding: 1rem 0;

    &:last-child {
      border-bottom: none !important;
      padding-bottom: 0;
    }

    @media only screen and (max-width: 1200px) {
      & {
        padding: 1rem 1.6rem;
      }
    }
  }

  &__buttons-wrapper {
    position: absolute;
    right: 0;
    top: -10px;
  }
}

.parent-option-contain-sub-form {
  height: 30px;
}

.musician-preferences-input {
  width: 100%;
}

.preference-spin-loading {
  margin-right: 0.4rem;
}

.preference-section {
  padding-right: 20px 20px 20px 0;
}

.event-timeline-modal {
  .ant-dropdown-trigger {
    display: none;
  }

  .ant-list-item-meta-title {
    color: rgba(0, 0, 0, 0.45);
  }

  .ant-list-item {
    padding: 3px 0;
  }

  .ant-table-selection
    .ant-checkbox.ant-checkbox.ant-checkbox-checked
    .ant-checkbox-inner::after {
    left: 3px;
  }

  .selected-event-timeline .ant-list-item-meta-title {
    color: white;
    font-weight: 700;
  }

  .template-warper {
    max-height: 40vh;
    overflow-y: auto;
  }

  .ant-list-split .ant-list-item {
    cursor: pointer;
    padding-left: 5px;
  }

  .ant-modal-body {
    padding: 15px;
    min-height: 75vh;
  }

  .event-timeline-select.ant-select,
  .ant-input-group {
    width: 90%;
  }

  .ant-input.ant-input {
    height: fit-content;
    border: none;
    background-color: transparent;
  }

  .event-timeline-select.ant-select {
    .ant-select-selector {
      border: none;
      background-color: transparent;
    }
  }

  .action-text {
    cursor: pointer;
  }

  .action-text.svg-inline--fa:hover {
    color: #ff4d4f;
  }

  .ant-picker {
    border: none;
    background-color: transparent;
  }

  textarea {
    resize: none;
  }

  .ant-select-selection-overflow-item {
    max-width: 200px;
  }

  @media only screen and (max-width: 1400px) {
    .ant-select-selection-overflow-item {
      max-width: 150px;
    }
  }

  @media only screen and (max-width: 1200px) {
    .ant-select-selection-overflow-item {
      max-width: 100px;
    }
  }
}

.primary-text-color {
  color: @primary-color !important;
}

.ant-typography.ant-typography-secondary.selected-number {
  margin-right: 5px;
  background-color: @primary-color;
  color: white !important;
  border-radius: 50%;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  padding: 4px 8px;
}

.selected-event-timeline {
  cursor: pointer;
  background-color: @primary-color;

  .ant-typography.ant-typography-secondary {
    color: white !important;
    font-weight: 600;
  }

  .ant-list-item-meta-description {
    color: white !important;
    font-weight: 600;
  }

  .ant-typography.ant-typography-secondary.selected-number {
    background-color: white;
    color: @primary-color !important;
  }

  .musics-expanded-area-wrapper .musics-delete-icon {
    display: none;
  }

  .musics-expanded-area-wrapper:hover .musics-delete-icon {
    display: flex;
  }

  .musics-expanded-area-wrapper .musics-delete-icon:hover .button-icon-edit {
    color: #ff4d4f;
  }
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgb(155, 155, 155);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#booking-details__section {
  .ant-dropdown-trigger {
    .ant-btn:not([disabled]):hover,
    .ant-btn:focus {
      margin-right: -1px;
    }
  }
}

.adjust-pay-modal {
  p,
  input {
    color: #535252;
    font-size: 14px;
  }

  .ant-modal-title {
    font-size: 22px;
    color: #535252;
  }

  .ant-modal-body {
    padding: 20px;

    .new-amount {
      .ant-input-number {
        width: 100%;
      }
    }
  }

  &-previous-amount {
    height: 100%;
    position: relative;

    &-title {
      padding-bottom: 8px;
      color: #535252;
    }

    &-payable {
      margin-left: 14px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #d8d4d4;
      width: 100%;
      height: 1px;
    }
  }

  .ant-form-item-required::before {
    top: 2px;
  }

  &-reason {
    margin-top: 20px;
  }

  & > .ant-modal-content {
    .ant-modal-body {
      .ant-form-item {
        margin-bottom: 0;

        .ant-form-item-label {
          line-height: 1.3;

          & > label {
            font-size: 14px;
            color: #535252;
          }
        }
      }
    }
  }

  .ant-btn-primary {
    margin-top: 20px;
  }
}

.booking_section_between_margin.disabled {
  pointer-events: none;
}
