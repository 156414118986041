.tc {
  &-back-button {
    margin-left: 20px;
  }

  &-button {
    &__wrapper {
      padding-left: 36px;
      text-align: center;
      margin: 20px auto 0;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .button-primary {
        margin: 4px;
      }
    }
  }

  &-header-tac-border-bottom {
    text-align: center;
    border-bottom: 1pt solid black;
    font-size: 16px;
    font-weight: 700;
  }

  &-header {
    margin-left: 35.45pt;
    text-indent: -0.25in;

    + .tc-list-item__2 {
      margin-top: 0;
    }
  }

  &-horizontal-tab {
    margin: 0;
    font-size: 1.2rem;
  }

  &-list-item {
    &-us__1 {
      margin-top: 12pt;
      margin-left: 20pt;
      margin-bottom: 3pt;
      text-indent: -15pt;
      page-break-after: avoid;
      font-size: 1.2rem;
    }

    &__1 {
      margin-top: 12pt;
      margin-left: 35.45pt;
      margin-bottom: 3pt;
      text-indent: -18pt;
      page-break-after: avoid;
      font-size: 1.2rem;
    }

    &__2 {
      margin: 6pt 0 0 58pt;
      text-align: left;
      text-indent: -20pt;
      line-height: 12pt;
    }

    &__3 {
      margin: 6pt 0 0 78pt;
      text-align: left;
      text-indent: -0.25in;
      line-height: 12pt;
    }
  }

  &-modal {
    top: 100px;
    height: auto;

    .ant-modal-body {
      max-height: unset;
      padding-left: 15px;
    }

    @media only screen and (max-width: @screen-lg-max) {
      top: 80px;
    }
  }

  &-plain-text {
    font-size: 9pt;
    font-family: 'Arial', sans-serif;
    color: black;

    &-bold {
      font-weight: bold;
    }
  }

  &-paragraph {
    margin-left: 17pt;
    text-align: justify;
    line-height: 12pt;
  }

  &-page {
    background-color: @White;
    width: 100%;
    min-height: 100vh;
    max-width: 120rem;
    padding: 2rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  }

  &-tabulation {
    font: 7pt 'Times New Roman';
  }

  &-wrapper {
    h1 {
      font-size: 16px;
      font-weight: 700;
    }

    h2 {
      font-size: 16px;
      font-weight: 700;
    }

    table td {
      border: 1px solid black;
      vertical-align: top;
    }
  }
}

.download-tc {
  color: @Primary;
}
