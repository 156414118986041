@import '../variables.less';

#qbDetails {
  .ant-modal-root {
    .ant-modal-mask,
    .ant-image-preview-mask {
      background: #f9f6f2 0% 0% no-repeat padding-box;
      opacity: 0.9;
    }
  }

  .ant-form-item-explain-warning {
    color: @QuoteBookingBuilderPrimary;
    font: italic normal 500 10px/14px Montserrat;
    text-align: center;
  }
}

.qb-scroll {
  overflow-y: auto;

  &::-webkit-scrollbar {
    margin-top: 0;
    padding-top: 0;
    width: 6px;
    height: 6px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #ccc5c2;
  }

  &:hover {
    @media (min-width: @screen-lg) {
      &::-webkit-scrollbar-thumb {
        background: #afacac;
      }
    }
  }
}

.qb-scroll-has-track-piece {
  .qb-scroll;

  &::-webkit-scrollbar-track-piece {
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-radius: 2px;
    background: transparent;
    box-shadow: inset 0 0 5px #ccc5c2;
  }
}

.qb {
  &-logo {
    display: none;
  }

  &-button {
    padding: 0 30px;
    text-shadow: none;
    font: normal normal bold 12px/14px Montserrat;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.07px;
    border: 1px solid @QuoteBookingBuilderPrimary;
    height: 36px;
    white-space: normal;
    color: #f2f2f2;

    &:hover {
      background: @QuoteBuilderBookingButtonHover;
    }

    &.ant-btn-primary {
      background: @QuoteBookingBuilderPrimary;
      border-radius: 20px;

      &:focus {
        background-color: @QuoteBuilderBookingButtonHover;
        border-color: @QuoteBuilderBookingButtonHover;
        color: white;
      }

      &:hover {
        background-color: @QuoteBuilderBookingButtonHover;
        border-color: @QuoteBuilderBookingButtonHover;
        color: white;
      }

      &.ant-btn-primary[disabled] {
        color: #fff;
        background: #a8a8a8 0% 0% no-repeat padding-box;
        border-color: #a8a8a8;
      }

      &.ant-btn-background-ghost {
        &:hover,
        &:focus {
          color: @White;
          background: @Black !important;

          span,
          svg {
            color: @White;
          }
        }
      }
    }

    &.selected {
      background: none;
      border: 2px solid @QuoteBookingBuilderPrimary;
      color: @QuoteBookingBuilderPrimary;
      font-weight: bold;
    }
  }

  &-callback {
    &-outer-wrapper {
      width: 100%;
      background: @QuoteBuilderSection;
      padding-top: 20px;

      .qb-details-inner-wrapper {
        padding: 25px;
        border-top: 1px solid @QuoteBuilderBorder;
        border-bottom: 1px solid @QuoteBuilderBorder;
      }
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-title {
      font: normal normal normal 24px/1 Lato;
      color: black;
    }

    &-text {
      font: normal normal normal 16px/1 Lato;
      color: @QuoteBuilderGreyFont;
      margin-top: 15px;
    }

    &-phone-icon {
      margin-left: 5px;
    }

    &-left {
      .qb-callback-title {
        font: normal normal normal 28px/1 cardo;
      }
    }

    &-right {
      button {
        font-family: montserrat;
        font-size: 13px;

        &:hover {
          background-color: #2c2c2c !important;
          color: white !important;
        }
      }

      .qb-button.ant-btn-primary {
        background-color: black;
        border: none;
        font-weight: bold;
      }
    }
  }

  &-city-dropdown {
    &-menu {
      && {
        padding: 5px 0;
        background-color: @QuoteBookingBuilderBackground;

        .ant-dropdown-menu-item:hover,
        .ant-dropdown-menu-submenu-title:hover,
        .ant-dropdown-menu-item:hover,
        .ant-dropdown-menu-submenu-title:focus,
        .ant-dropdown-menu-item:hover,
        .ant-dropdown-menu-submenu-title:active {
          background-color: @QuoteBuilderDropdownHovergoundColor;
          color: @QuoteBuilderPlaceHolder;
        }
      }
    }

    &-option {
      && {
        background-color: @QuoteBookingBuilderBackground;
        padding: 5px 16px;

        .text {
          max-width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding-right: 12px;
          color: @QuoteBuilderPlaceHolder;
          font-family: montserrat;
        }
      }
    }

    &-trigger {
      && {
        border-color: #ccc5c2;
        color: @White;
        padding: 8px 24px;

        & > .ant-row {
          flex-wrap: nowrap;
        }
      }

      img {
        margin-right: 15px;
      }

      &-name {
        margin-right: 20px;
        font: normal normal 600 12px/14px montserrat;
        color: #505050;

        && + img {
          margin-right: 10px;
        }
      }
    }

    &-marker-icon {
      color: @Black;
      margin-right: 10px;

      &.fa-chevron-down {
        margin-right: 0;
        font-size: 12px;
        color: #505050;
      }
    }
  }

  &-details {
    &-outer-wrapper {
      .footer-container {
        .terms-conditions-link,
        .footer-copyright {
          font: normal normal normal 13px/16px Montserrat;
          color: @QuoteBuilderGreyFont;
          text-transform: none;
        }
      }
    }

    &-event-date {
      color: @QuoteBuilderGreyFont;
      flex-grow: 1;

      &-section {
        padding: 30px 0 0;
        margin-bottom: 24px;
        font: normal normal normal 14px/20px Lato;
      }

      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .ant-picker {
        background: none;
        border: 1px solid #ccc5c2;
        font: normal normal normal 14px/20px Lato;
        color: @QuoteBuilderGreyLightFont;
        padding: 10px;
        box-shadow: none;

        input {
          font: normal normal normal 11px/18px Montserrat;
          color: @Black;

          &::placeholder {
            font-style: italic;
            color: #505050;
          }
        }

        &-suffix {
          color: #505050;
          margin-top: -4px;
          font-size: 18px;
        }

        &.ant-picker:hover,
        &.ant-picker-focused {
          border-color: @QuoteBuilderBorder;
        }

        &.ant-picker-disabled {
          background: transparent;
          border: 1px solid @QuoteBuilderBorder;
          box-shadow: none;
          color: @QuoteBuilderGreyFont;
          opacity: 0.8;

          .ant-picker-suffix {
            color: @QuoteBuilderBorder;
          }
        }

        .ant-picker-input {
          > input[disabled] {
            color: @QuoteBuilderGreyFont;
          }
        }
      }

      .ant-form-item {
        margin-bottom: 0;

        .ant-picker {
          background: @white;
          border-radius: 5px;
        }

        &-has-warning {
          .ant-picker {
            border: 1px solid #be3c46;

            &:not([disabled]) {
              &:hover,
              &:focus {
                border-color: @QuoteBookingBuilderPrimary;
              }
            }
          }

          .ant-form-item-explain-warning {
            .icon {
              margin-right: 4px;
              color: @Black;
            }
          }
        }

        &-explain {
          margin-top: 4px;
          font-size: 14px;
          min-height: unset;
        }
      }

      @media (max-width: @screen-xs-max) {
        margin-left: 0;
        max-width: unset;

        &-wrapper {
          flex-direction: column;
          align-items: stretch;
        }
      }
    }

    &-gig {
      &-section {
        margin-bottom: 30px;

        .qb-details-gigs-head {
          margin-top: 0;
        }
      }
    }

    &-outer-wrapper {
      overscroll-behavior: contain;
      background-color: @QuoteBuilderBackground;
      min-height: 100%;

      &.qb-scroll {
        height: 100%;
      }

      .qb-details-item-field-control {
        background-color: @white;
        border-radius: 5px;
      }
    }

    &-inner-wrapper {
      max-width: @QuoteBuilderMaxWidth;
      width: calc(100% - 60px);
      margin: auto;
      padding-top: 50px;
      color: @QuoteBuilderGreyFont;
      position: relative;
      // margin-top: -50px; // temporal fix while request callback is hidden
      .ant-picker:hover,
      .ant-picker-focused {
        box-shadow: none !important;
      }

      &.fixed_header {
        padding-top: 118px;
      }

      .ant-tabs-nav-wrap {
        .padding-horizontal(30px);
      }

      .ant-tabs-nav-list {
        width: 100%;
        display: flex;
      }

      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: @QuoteBuilderPagingDisable !important;
        }
      }

      .ant-tabs-tab {
        text-transform: uppercase;
        margin-right: 0;
        flex-grow: 1;

        .ant-tabs-tab-btn {
          margin: auto;
          color: @QuoteBuilderGreyLightFont;
          font-family: Syne;
          font-weight: 700;
        }
      }

      &__first-screen {
        @media (max-width: @screen-lg-max) {
          width: auto;
          overflow-x: hidden;
        }
      }
    }

    &-title {
      text-align: center;
      font: normal normal normal 53px/57px Cardo;
      color: black;
      text-transform: capitalize;
      margin-bottom: 40px;
      margin-top: -25px;
    }

    &-subtitle {
      max-width: 940px;
      margin: -26px auto 0;
      font: normal normal normal 18px/23px Lato;
      color: @QuoteBuilderGreyLightFont;

      @media (max-width: @screen-lg-max) {
        padding: 0 30px;
        margin-bottom: 14px;
        font-size: 18px;
      }

      &__wrapper {
        position: relative;
        margin-bottom: 20px;

        @media (max-width: 1330px) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      &__button {
        position: absolute;
        right: 0;
        top: 0;

        @media (max-width: 1330px) {
          position: static;
          margin-top: 20px;
        }
      }
    }

    &-gigs {
      &-title {
        font: normal normal bold 12px/14px Syne;
        color: black;
        text-transform: uppercase;
        letter-spacing: 0.6px;
      }

      &-head {
        margin-bottom: 12px;
        margin-top: -8px;
      }

      &-item {
        .reception-modal-item-video-list {
          display: flex;

          .swiper {
            width: 100%;
          }
        }
      }
    }

    &-item {
      color: @Black;
      width: 100%;

      &__address {
        .qb-details-item-field-control {
          border: none;
          height: auto;
          background: transparent;
        }

        .ant-form-item {
          margin-bottom: 0;
          width: 100%;

          .ant-input {
            background: @white;
            border: 1px solid #ccc5c2;
            border-radius: 5px;
          }

          &-control-input {
            border: none;
          }

          &-has-warning {
            .ant-input {
              border: thin solid @QuoteBuilderGreyLightFont;
              transition:
                border 0.3s,
                box-shadow 0.3s;
              background: @white;

              &:hover {
                background: @white;
                border-color: @Primary;
              }

              &:focus {
                border-color: @PrimaryHover;
              }

              &:not([disabled]) {
                &:hover,
                &:focus {
                  background: @white;
                  border-color: @QuoteBuilderGreyLightFont;
                }
              }
            }

            .ant-input-affix-wrapper.ant-input-affix-wrapper-status-warning {
              border-color: @QuoteBookingBuilderPrimary;

              #auto-complete-ceremony,
              #auto-complete-reception {
                border-color: @QuoteBookingBuilderPrimary;
              }
            }

            .ant-form-item-explain-warning {
              color: @QuoteBookingBuilderPrimary;
              font: italic normal 500 10px/14px Montserrat;
              text-align: center;

              .icon {
                margin-right: 4px;
                color: @QuoteBookingBuilderPrimary;
              }
            }
          }

          &-explain {
            margin-top: 4px;
            min-height: unset;
            font: normal normal normal 14px/20px Josefin Sans;
          }
        }

        textarea.ant-input {
          background: none;
          resize: none;
          border: none;
          color: @Black;
          font: normal normal normal 11px/18px Montserrat;
          padding: 8px 46px 8px 10px;
          height: auto;

          &:focus,
          &:hover {
            border-color: @QuoteBuilderBorder;
            box-shadow: none;
          }

          &[disabled] {
            cursor: default;
          }

          &::placeholder {
            color: @QuoteBuilderPlaceHolder;
            font-style: italic;
          }
        }
      }

      &-title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 10px;
      }

      &-title {
        font-family: Syne;
        font-weight: 700;
        font-size: 13px;
        color: @Black;
        text-transform: uppercase;
        margin-right: 10px;
      }

      &-price {
        &-text {
          color: @Black;
          text-transform: uppercase;
          display: inline-block;
          margin-right: 14px;
          font-family: Syne;
          font-weight: bold;
          font-size: 12px;
        }

        &-number {
          font-family: Syne;
          font-weight: bold;
          font-size: 12px;
          color: @Black;
        }
      }

      &-location {
        &-kind {
          color: @QuoteBuilderGreyFont;
          font-weight: bold;
          text-transform: uppercase;
        }

        &-name {
          font: normal 18px/23px Lato;
        }
      }

      &-field {
        &-name {
          color: black;
          margin-bottom: 5px;
          font: normal normal bold 10px/14px Syne;
          letter-spacing: 0.5px;

          &.address-title,
          &.duration-title {
            margin-top: 15px;
          }
        }

        &-name::first-letter {
          text-transform: uppercase;
        }

        &-control {
          display: flex;
          align-items: center;
          border: 1px solid #ccc5c2;
          height: 58px;
          position: relative;
          background: @QuoteBuilderBackground 0 0 no-repeat padding-box;

          .ant-picker-dropdown {
            display: none;
          }

          &.name-field {
            height: 36px;
            justify-content: space-between;
            display: flex;
            align-items: center;
            background: @white;
            border-radius: 5px;

            &:focus,
            &:hover {
              border-color: @QuoteBuilderBorder;
            }

            .qb-details-gig-select {
              flex: 1;
              text-align: left;
              font: normal normal normal 11px/18px Montserrat;
              color: @Black;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              svg {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
              }

              &.ant-btn {
                width: 100%;
                padding: 0 10px;
              }

              &-placeholder {
                color: @QuoteBuilderPlaceHolder;
                font-style: italic;
                user-select: none;
              }
            }

            .qb-details-gig-name {
              font: normal normal normal 11px/18px Montserrat;
              color: @Black;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &.ant-btn {
                .padding-horizontal(0);
              }

              &-placeholder {
                color: @QuoteBuilderPlaceHolder;
                font-style: italic;
                user-select: none;
              }

              &-container {
                &-wrapper {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  flex: 1;
                  width: 50%;
                }

                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                padding: 0 10px;

                .icon-primary {
                  flex-shrink: 1;
                  height: auto;
                  width: auto;
                  padding: 0;
                  border: none;
                  margin: 0 8px;
                  color: @Black;
                }
              }
            }
          }

          &.duration-field {
            height: 36px;

            &:hover {
              border-color: @QuoteBuilderBorder;
            }

            a {
              color: @QuoteBookingBuilderPrimary;
            }

            .ant-btn-primary {
              color: #505050;
            }

            svg {
              font-size: 14px;
              shape-rendering: auto !important;
            }
          }

          &.filled:hover,
          &.focus&.filled {
            > .font-awesome-icon {
              display: none;
            }

            .ant-input-affix-wrapper .anticon-close-circle {
              opacity: 1;
              cursor: pointer;
            }
          }

          .ant-input-affix-wrapper {
            background: none;
            border: none;
            height: auto;
            box-shadow: none !important;

            .anticon {
              color: @Black;
              top: 50%;
              transform: translateY(-50%);
              right: 12px;
            }
          }

          &-duration {
            &-decrease,
            &-increase {
              width: 25%;
              text-align: center;
              background: @QuoteBuilderBackground;
              color: @QuoteBuilderYellow;
              border: none;
            }
          }

          .ant-form-item-control-input-content {
            display: flex;
          }

          .qb-details-item-field-control-duration-increase,
          .qb-details-item-field-control-duration-decrease {
            border-color: @QuoteBuilderBorder;

            &:hover,
            &:active,
            &:focus {
              border-color: @QuoteBuilderBorder;
            }

            &:active {
              background: @QuoteBuilderBorder !important;
              transition: all 0.3s ease;
            }
          }

          .qb-details-item-field-control-duration-input {
            background: none;
            border: none;
            pointer-events: none;
            height: auto;

            input {
              text-align: center;
              color: @Black;
              font: italic normal normal 11px/18px Montserrat;
            }
          }

          .ant-picker.ant-picker-disabled,
          .ant-btn-primary[disabled],
          .ant-btn-primary[disabled]:hover,
          .ant-btn-primary[disabled]:focus,
          .ant-btn-primary[disabled]:active {
            background: none;
            color: @QuoteBuilderSlickDisable;
            cursor: default;
            border-color: @QuoteBuilderBorder;

            input[disabled] {
              color: @QuoteBuilderSlickDisable;
              cursor: default;
            }
          }

          .ant-picker.ant-picker-disabled {
            background: @white;
          }
        }
      }

      &-empty {
        &-placeholder {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          justify-content: center;
          font-size: 16px;
          background: @QuoteBuilderBackground;

          .qb-button {
            margin-top: 20px;
          }

          @media (max-width: @screen-xs-max) {
            font-size: 14px;

            .qb-button {
              display: none;
            }
          }

          .svg-inline--fa {
            font-size: 46px;
            color: @Primary;
            margin-bottom: 20px;
          }
        }
      }

      &-main-img {
        img {
          display: block;
          width: 100%;
        }
      }

      &-1st-view {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: white;

        &-popup {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: transparent;
          padding: 30px 40px;
          display: flex;
          width: 100%;
        }

        &-detail {
          font: normal normal normal 12px/14px Syne;
          margin: 0 auto;

          &-title {
            font: normal normal bold 12px/16px Syne;
            letter-spacing: 0.6px;
            text-transform: uppercase;
            text-align: center;
          }
        }

        &-icon {
          margin-left: 20px;
          display: flex;
          align-items: center;

          .svg-inline--fa {
            transform: rotate(-45deg);
            font-size: 23px;
          }
        }
      }
    }

    &-singers-wrapper {
      .qb-singer-preferences-top {
        .qb-details-singers-subtitle--desktop {
          margin: 10px 0 -10px;
          font: normal normal normal 10px/18px Montserrat;
          color: #505050;
        }

        .qb-button {
          margin: 20px 0 28px;
        }
      }
    }

    &-section {
      background-color: @QuoteBuilderSection;
      padding: 20px 25px;
      margin-bottom: 25px;
      border: 1px solid @QuoteBuilderBorder;
      width: 100%;

      .qb-singer-list {
        max-height: 800px;
        margin: 0 -10px 0 0;
        padding: 0 10px 0 0;
      }

      &-title {
        font: normal normal bold 26px/30px Syne;
        letter-spacing: 1.1px;
        color: black;
        text-transform: uppercase;
        margin: 0;

        &.qb-details-question {
          text-align: center;
          margin-bottom: 30px;
        }
      }

      &-gigs {
        &-head {
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .font-awesome-icon {
        margin: 0 10px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);

        &.fa-pen {
          color: #505050;
          padding-bottom: 2px;
          border-bottom: 1px solid @black;
          font-size: 14px;
          transform: translateY(-18px);
        }
      }

      .google-map-prediction-container {
        color: @QuoteBuilderGreyFont;

        &:hover {
          background: #f5f5f5;
        }
      }
    }

    &-singers {
      &-wrapper {
        @media (max-width: @screen-xs-max) {
          .qb-button {
            width: 100%;
          }
        }
      }

      &-subtitle {
        font: normal normal normal 11px/18px Montserrat;
        color: #505050;
        margin: 30px 0;
      }
    }
  }

  &-group {
    &-modal {
      background: @QuoteBuilderBackground;
      padding-bottom: 0;

      .ant-modal-content {
        background: @QuoteBuilderBackground;

        .ant-modal-header {
          background: @QuoteBuilderSection;
          padding: 16px 0 18px;
          border: none;
        }

        .ant-modal-body {
          overflow: hidden;
          padding: 0;
          display: flex;
          flex-direction: column;

          .qb-group-modal-content {
            display: flex;
            flex-direction: column;
            flex: 1;
          }
        }
      }

      &-title {
        font-size: 13px;
        font-weight: 700;
        font-family: Syne;
        text-transform: capitalize;

        &-left {
          position: absolute;
          top: -2px;
          left: 0;

          .qb-button {
            color: @White;
          }
        }

        &-center {
          text-align: center;
          color: @White;
        }
      }

      &-content {
        .band-modal-content-ceremony-list-item {
          background: @QuoteBuilderSection;
          padding-bottom: 20px;

          &__title {
            padding: 0 10px 0 30px;
            text-transform: uppercase;
          }

          &__price {
            padding: 0 10px 0 30px;
            margin-bottom: 20px;
          }

          &__description {
            padding: 0 30px;
            margin: 0 0 30px;
          }
        }
      }
    }
  }

  &-navbar {
    &-wrapper {
      height: 68px;
      padding: 15px 50px;
      display: flex;
      justify-content: space-between;
      background: @QuoteBuilderBackground;
      top: -111px;

      &.fixed_header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
        transition: top @AnimEaseOutHeader;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
      }

      .qb-city-dropdown-trigger {
        padding: 0 25px;
        height: 100%;
      }

      .qb-telephone-city-wrapper {
        align-items: center;

        .tel {
          font: normal normal 600 14px/16px Montserrat;
          color: black;
          text-transform: uppercase;
          margin-right: 50px;
        }
      }

      .qb-navbar-logo {
        img {
          height: 100%;
          margin: 0;
          display: block;
        }
      }
    }

    &-logo {
      padding: 0;
      height: 100%;
    }
  }

  &-notes {
    &-textarea {
      && {
        padding: 10px 5px;
        margin: 0 0 20px;
        background: @white 0 0% no-repeat padding-box;
        border: 1px solid #ccc5c2;
        border-radius: 5px;
        resize: vertical;
        font-family: Montserrat;
        font-weight: 400;
        font-size: 11px;
        color: #505050;
        height: 82px;
        min-height: 82px;
      }

      &::placeholder {
        color: @QuoteBuilderPlaceHolder;
        font-style: italic;
      }
    }

    &-button {
      && {
        margin: auto;
        display: block;
      }

      &.saved {
        border-color: @Black;
        background-color: transparent;

        &:hover {
          background: #2c2c2c 0% 0% no-repeat padding-box;
          border-color: @Black;

          span,
          svg {
            color: @White;
          }
        }

        &:focus {
          background: transparent;
          border-color: @Black;

          span,
          svg {
            color: @Black;
          }
        }

        .svg-inline--fa {
          margin-left: 15px;
        }

        span,
        svg {
          color: @Black;
        }
      }
    }
  }

  &-notification {
    &-icon {
      color: @Black !important;
      font-size: 30px;
    }

    .ant-modal-content {
      background: @QuoteBuilderSection;
    }

    .ant-modal-confirm-title {
      color: @Black;
      font-size: 16px;
    }

    .ant-modal-confirm-content {
      color: @Black;
      font-size: 14px;
      margin-top: 15px;
    }

    .ant-modal-confirm-btns {
      .ant-btn {
        padding: 9px 32px 10px;
        font-size: 16px;
        border: 1px solid @QuoteBuilderBookingButtonHover;
        background: @QuoteBookingBuilderPrimary;
        height: 36px;
        border-radius: 20px;

        &:hover {
          background-color: @QuoteBuilderBookingButtonHover;
          border-color: @QuoteBuilderBookingButtonHover;
        }
      }
    }
  }

  &-modal {
    padding-bottom: 0;
    width: calc(100vw - 200px) !important;
    max-width: 1625px;

    &-wrapper {
      .qb-scroll;
    }

    // Remove asterisk for all modal
    .ant-form-item-label
      > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
      )::before {
      display: none;
    }

    // Customize phone number field
    .intl-tel-input {
      display: flex;
      background-color: @white;
      border-radius: 5px;
      border: 1px solid #ccc5c2;
      height: 28px;

      .ant-input {
        padding-left: 0 !important;
        border: none !important;
        padding-top: 0;
        padding-bottom: 0;
        height: unset !important;
      }

      .flag-container {
        width: auto !important;
        position: unset !important;

        .selected-flag {
          width: max-content !important;
          background-color: unset !important;

          .arrow {
            display: none;
          }

          .iti-flag {
            background-image: unset;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: unset;
            box-shadow: none;
          }

          .selected-dial-code {
            padding-left: 10px !important;
            padding-right: 2px !important;
            font: italic normal normal 11px/18px Montserrat;
          }
        }
      }

      .country-list {
        width: 100%;
        font: normal normal normal 11px/18px Montserrat;
        white-space: unset;

        .country {
          align-items: center;
          justify-content: left;
          display: flex;

          .flag-box {
            display: none;
          }

          .country-name {
            order: 1;
          }

          .dial-code {
            order: 0;
            width: 46px;
          }
        }

        &::-webkit-scrollbar {
          margin-top: 0;
          padding-top: 0;
          width: 5px;
          height: 8px;
        }

        &::-webkit-scrollbar-thumb:vertical {
          border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb:horizontal {
          border-radius: 2px;
        }
      }
    }

    &-title {
      text-transform: uppercase;
    }

    &-notification {
      max-width: 100%;

      .ant-modal-close {
        svg {
          font-size: 22px;
        }
      }

      & > .ant-modal-content {
        padding: 48px;
        background: #f9f6f1;
        height: 100%;
        border: 1px solid #a8a8a8;

        & > .ant-modal-body {
          padding: 0;

          & > .ant-row {
            align-items: center;
          }
        }
      }

      &-image {
        width: 100%;
      }

      &-title {
        font: normal normal bold 26px/28px Syne;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        color: @black;

        &.mobile {
          display: none;
        }
      }

      &-content {
        font: normal normal normal 14px/28px Montserrat;
        margin: 28px 0;
        color: @black;
      }
    }

    &-request-callback {
      width: 800px !important;

      &-image {
        width: 100%;
      }

      &-title {
        font: normal normal bold 26px/28px Syne;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        color: @black;

        &.mobile {
          display: none;
        }
      }

      &-content {
        font: normal normal normal 11px/18px Montserrat;
        margin: 20px 0 28px;
        color: #505050;
      }

      & > .ant-modal-content {
        & > .ant-modal-body {
          padding: 44px 46px;
          overflow: unset;

          & > .ant-row {
            align-items: center;
          }
        }
      }

      &__form {
        // Error message
        .ant-form-item-explain {
          min-height: unset;

          &-error {
            font: italic normal 600 10px/14px Montserrat;
            text-align: center;
          }
        }

        .ant-form-item-label {
          & > label {
            font: normal normal bold 12px/16px Syne;
            letter-spacing: 0.6px;
            text-transform: uppercase;
            color: @black;
          }
        }

        .ant-form-item {
          margin-bottom: 16px;
        }

        .ant-form-item-has-error {
          .ant-input {
            border-color: #ff4d4f;
          }
        }

        .ant-input {
          border: 1px solid #ccc5c2;
          border-radius: 5px;
          font: italic normal normal 11px/18px Montserrat;
          height: 28px;

          &::placeholder {
            color: #505050;
          }
        }

        .ant-form-item-has-error {
          &-phone-number {
            .intl-tel-input {
              border-color: #ff4d4f;
            }
          }
        }

        &-phone-number {
          display: flex;
        }
      }
    }

    &-successful-notification {
      max-width: 100%;

      &--enquiry {
        && {
          text-align: center;
        }

        &-title {
          font: normal normal bold 26px/35px Syne;
          letter-spacing: 1.3px;
          color: @Black;
          text-transform: uppercase;

          &.mobile {
            display: none;
          }
        }

        &-content {
          margin: 20px 0 25px;
          font: normal normal normal 14px/28px Montserrat;
          color: @Black;
        }
      }

      &--request-callback {
        &-title {
          font: normal normal bold 26px/35px Syne;
          letter-spacing: 1.3px;
          color: @Black;
          text-transform: uppercase;

          &.mobile {
            display: none;
          }
        }

        &-content {
          font: normal normal normal 11px/18px Montserrat;
          color: #505050;
          margin: 20px 0;
        }
      }

      &-image {
        width: 100%;
      }

      & > .ant-modal-content {
        padding: 48px;
        background: #f9f6f1;
        height: 100%;
        border: 1px solid #a8a8a8;

        & > .ant-modal-body {
          padding: 0;

          & > .ant-row {
            align-items: center;
          }
        }
      }
    }

    &-unlock-price {
      width: 800px !important;

      &-note {
        font: normal normal normal 11px/18px Montserrat;
        color: #505050;

        &-link {
          color: #505050;
          font-weight: 600;

          &:hover {
            color: #505050;
          }
        }
      }

      &__form {
        // Error message
        .ant-form-item-explain {
          min-height: unset;

          &-error {
            font: italic normal 600 10px/18px Montserrat;
            text-align: center;
          }
        }

        &-phone-number {
          display: flex;
        }

        .ant-form-item-label {
          padding: 0 0 2px;

          & > label {
            font: normal normal bold 12px/16px Syne;
            letter-spacing: 0.6px;
            text-transform: uppercase;
            color: @black;
          }
        }

        .ant-form-item {
          margin-bottom: 14px;

          &.ant-form-item-has-error {
            .ant-input-affix-wrapper,
            .ant-input {
              border-color: #ff4d4f;
            }

            .intl-tel-input {
              border-color: #ff4d4f;
            }
          }

          .ant-input-affix-wrapper {
            padding: 0 11px;
            border: 1px solid #ccc5c2;
            border-radius: 5px;
            box-shadow: none;
            height: auto;

            .ant-input {
              border: none;
            }
          }

          .ant-input {
            border: 1px solid #ccc5c2;
            border-radius: 5px;
            font: normal normal normal 11px/18px Montserrat;
            height: 26px;
            color: #505050;

            &::placeholder {
              color: #505050;
              font-style: italic;
            }
          }
        }

        .qb-button {
          margin-top: 14px;
        }
      }

      &-title {
        text-align: center;
        font: normal normal bold 26px/35px Syne;
        letter-spacing: 1.3px;
        color: @black;
      }

      &-image {
        width: 100%;
      }

      & > .ant-modal-content {
        & > .ant-modal-body {
          padding: 40px 38px;
          overflow: unset;

          & > .ant-row {
            margin: 20px 0;
            align-items: center;
          }
        }
      }
    }

    &.qb-modal-singers {
      width: calc(100vw - 60px) !important;

      .qb-modal-singers__row {
        max-height: 915px;
        height: calc(100vh - 200px);
        overflow: unset;

        .qb-modal-singers__col {
          height: 100%;
          display: flex;
          flex-direction: column;

          .qb-modal-singers-col {
            height: 100%;
            display: flex;
            flex-direction: column;
            position: relative;

            .qb-scroll-has-track-piece {
              margin: 0 -10px 60px;
              padding: 0 5px 0 10px;
            }
          }

          .qb-singer-info {
            height: 100%;
            display: flex;
            flex-direction: column;
          }
        }
      }

      .qb-apply-singer-button {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.ceremony-modal {
      width: calc(100vw - 60px) !important;

      .ant-modal-body {
        overflow: hidden;
      }

      .band-modal-content {
        min-height: 500px;
        height: calc(100vh - 150px);
        max-height: 850px;

        .band-modal-content-ceremony {
          width: 100%;
          display: flex;
          flex-direction: column;
          height: 100%;

          .band-modal-content-ceremony-list-wrapper {
            overflow-x: hidden;
          }
        }
      }
    }

    &.reception-modal {
      max-width: 1500px;
    }

    &-title {
      &-left {
        display: flex;
        align-items: center;

        > * {
          margin-right: 20px;
        }
      }

      &-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }

      &-right {
        .ant-btn-text {
          display: flex;
          align-items: center;

          .font-awesome-icon {
            margin-left: 5px;
            font-size: 18px;
          }
        }
      }
    }

    &-content {
      &-subtitle {
        && {
          font: normal normal bold 13px/16px Syne;
          color: @Black;
          letter-spacing: 0.65px;
          text-transform: uppercase;
        }
      }

      &-tabs {
        display: flex;
        flex-direction: column;

        &-title {
          font: normal normal bold 12px/16px Syne;
          color: #000;
          text-transform: uppercase;
        }

        &-buttons {
          .ant-btn {
            font-size: 10px !important;
            text-transform: capitalize;
            .padding-horizontal(0);
            .margin-horizontal(10px);

            padding-bottom: 3px;
            margin-bottom: -3px;
            color: @Black !important;

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }

            > span {
              &:focus {
                outline: none;
              }
            }

            &.active {
              position: relative;
              color: @QuoteBuilderPagingDisable !important;

              &::after {
                content: '';
                position: absolute;
                left: 0;
                top: calc(100% - 10px);
                height: 1px;
                width: 100%;
                background: @QuoteBuilderPagingDisable;
                transition: @AnimFromAntd;
              }

              &:hover {
                color: @QuoteBuilderPagingDisable !important;

                &::after {
                  background: @QuoteBuilderPagingDisable;
                }
              }
            }
          }
        }
      }
    }

    &-singers {
      &-list {
        && {
          margin: 0 -10px;
          padding: 3px 5px 5px 10px;
        }

        &.ant-list-split .ant-list-item {
          border-bottom: none;
        }

        &-header {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          transform: translateY(-24px);
        }

        &-choose {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background: transparent
            linear-gradient(121deg, #5d5d5d 0, #414141 100%) 0 0 no-repeat
            padding-box;

          &-text {
            text-align: center;
            font: normal normal normal 14px/16px Lato;
            color: @White;
          }

          .qb-modal-singers-music-icon {
            font-size: 35px;
            margin-bottom: 20px;
            color: @Black;
          }
        }

        .ant-spin-container {
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
          gap: 22px;
        }

        .ant-list-item {
          align-items: flex-start;
          padding: 0;
          border: none;

          &.selected {
            box-shadow: 0 0 0 3px #be3c46;
          }

          &.highlighted {
            box-shadow: 0 0 0 3px @QuoteBuilderReceptionModalBuilder;
          }
        }

        .ant-image {
          width: 100%;
        }

        .ant-card {
          height: 100%;
          background: @QuoteBuilderSection;
          cursor: pointer;
          width: 100%;
          border: 1px solid @QuoteBuilderReceptionModalBuilder;

          &-body {
            padding: 10px;
            color: @QuoteBuilderPlaceHolder;
            font: normal normal bold 13px/16px Syne;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            letter-spacing: 0.65px;
            justify-content: space-between;

            &::before,
            &::after {
              display: none;
            }
          }
        }
      }

      &-plus {
        &-icon {
          color: @Black;
          cursor: pointer;

          &.disabled {
            color: @QuoteBuilderGreyFont;
          }
        }
      }
    }

    &-location {
      color: @Black;
      padding-bottom: 0;

      &-content {
        &-btns {
          margin: 20px 0 30px;
        }
      }
    }

    .ant-modal-content {
      background: @QuoteBuilderBackground;
      height: 100%;
      border: 1px solid @QuoteBuilderBorder;
    }

    .ant-modal-body {
      padding: 40px 30px;
      height: auto;
      overflow: auto;
      &:extend(.qb-scroll);
    }

    .ant-modal-header {
      background: @QuoteBuilderSection;
      color: @White;
      padding: 12px 30px;
      border: none;
      border-bottom: 1px solid @QuoteBuilderBorder;
    }

    .ant-modal-title {
      color: @Black;
      font: normal normal bold 26px/30px Syne;
      top: 0;
      letter-spacing: 1.3px;
    }

    .ant-btn-text {
      font-size: 13px;
      line-height: 16px;
      font-family: Syne;
      font-weight: 700;
      padding-top: 5px;
      line-height: 1;
      color: @QuoteBookingBuilderSecondary;
      text-transform: uppercase;

      &:hover {
        background: transparent;
        color: @QuoteBuilderGreyLightFont;
      }
    }
  }

  &-package {
    &-item {
      display: flex;
      padding: 20px;
      background: @QuoteBuilderSection;
      margin-bottom: 20px;
      cursor: pointer;

      @media (max-width: @screen-lg-max) {
        padding: 30px 0;
        flex-direction: column;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &_selected {
        outline: 4px solid @QuoteBuilderBookingBorder;
      }

      &__left {
        width: 60%;

        @media (max-width: @screen-lg-max) {
          width: 100%;
        }
      }

      &__right {
        width: 40%;
        padding-left: 15px;
        overflow-y: hidden;
        margin-bottom: 10px;

        @media (max-width: @screen-lg-max) {
          width: 100%;
          overflow-y: auto;
          padding: 30px 30px 0;
          margin-bottom: 0;
        }
      }

      &__name {
        font: normal normal bold 20px/24px syne;
        color: @White;
        text-transform: capitalize;
      }

      &__moreInfo {
        font: italic normal normal 11px/18px Montserrat;
        color: @Grey2;
        margin-top: 10px;
      }

      &__button {
        display: block;
        margin-top: 30px;

        .qb-button {
          width: 100%;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }

    &-video {
      padding: 20px;
      background: @QuoteBuilderSection;

      &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
      }

      &__name {
        font: normal normal bold 26px/28px Lato;
        color: @White;
        text-transform: capitalize;
      }

      &__buttons {
        margin-top: 4px;
        display: flex;
        align-items: center;

        .qb-city-dropdown-trigger {
          margin-right: 40px;
        }
      }
    }
  }

  &-performance {
    &-list {
      &__video {
        &-outer {
          position: relative;
          width: 100%;
          padding-top: 56.25%;
          background: inherit;
        }

        &-inner {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
        }

        &-img {
          position: absolute;
          top: 0;
          width: 100%;
          height: auto;
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  &-prev-button {
    color: @White;
  }

  &-preference {
    &-item {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &-wrapper {
        &--has-performer {
          border: 1px solid #ccc5c2;
          background-color: #f9f6f1;
        }

        &.black {
          background: @White;
        }
      }

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 12px;
        text-transform: uppercase;
        font: normal normal bold 12px/16px Syne;
        letter-spacing: 0.6px;
        color: @Black;

        &--has-performer {
          border: 1px solid #ccc5c2;
          border-bottom: none;
        }

        & > svg {
          font-size: 24px;
        }
      }

      &-choose {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: #343434 0 0 no-repeat padding-box;

        &-icon {
          color: @Black;
          font-size: 45px;
        }

        .ant-image {
          width: 100%;
        }
      }

      &-footer {
        display: flex;
        padding: 20px 12px;
        justify-content: space-between;
        align-items: center;

        &-alias {
          font: normal normal bold 12px/16px Syne;
          letter-spacing: 0.6px;
          color: @QuoteBuilderPlaceHolder;
          text-transform: uppercase;
        }

        .svg-inline--fa {
          cursor: pointer;
          font-size: 18px;
          color: @QuoteBuilderGreyFont;
        }
      }

      &-surcharge {
        padding: 0 12px 20px;
        margin-top: -10px;
        font: italic normal normal 12px/20px Montserrat;
        color: #505050;
      }
    }

    &-placeholder {
      background: @white;
      height: 165px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &__icon {
        font-size: 45px;
        color: @Black;
      }

      &__text {
        font: normal normal bold 12px/16px Syne;
        color: @Black;
        text-transform: uppercase;
        text-align: center;
        line-height: 1.3;
        letter-spacing: 0.6px;
      }
    }
  }

  &-price {
    &-wrapper {
      font-size: 1.6rem;
      flex: 1;
    }

    &-item {
      &-wrapper {
        font-family: Montserrat;
        font-size: 11px;
        font-weight: 600;
        color: #505050;
        margin: 10px 15px;
      }

      .svg-inline--fa {
        display: none;
      }
    }

    &-tax-information {
      font-family: Montserrat;
      font-weight: 400;
      font-size: 9px;
      color: #505050;
      margin: 0 15px 20px;
      font-style: italic;
    }

    &-total {
      text-transform: capitalize;

      &-wrapper {
        font-family: Montserrat;
        font-size: 11px;
        font-weight: 600;
        color: #505050;
        border-color: @QuoteBuilderBorder;
        border-top: 1px solid;
        border-bottom: 1px solid;
        padding: 12px 0;
        margin: 15px 15px 10px;
        text-transform: uppercase;
      }
    }

    &-bonus {
      &-title {
        font: normal normal bold 20px/24px Lato;
        color: @White;
        text-transform: capitalize;
      }

      &-value {
        margin-top: 10px;
      }

      &-wrapper {
        margin-top: 40px;
      }
    }

    &-button-wrapper {
      text-align: center;
      margin: 20px auto 20px;
    }

    &-title {
      && {
        margin-bottom: 30px;
      }
    }

    &-blur {
      background: @white;
      border: 1px solid #ccc5c2;
      border-radius: 5px;

      .qb-price {
        &-item {
          .qb-price-bluring {
            &-wrapper {
              position: relative;
            }

            filter: blur(4px);
          }

          .svg-inline--fa {
            position: absolute;
            left: calc(50% - 24px);
            top: calc(50% - 21px);
            font-size: 48px;
            color: @QuoteBuilderGreyFont;
            display: block;
          }

          &.qb-price-button-wrapper {
            filter: none;
          }
        }
      }
    }
  }

  &-singer {
    &-info {
      &-card {
        && {
          width: 100%;
          background: transparent;
          color: @QuoteBuilderGreyFont;
          margin: 24px -10px 0;
          padding: 0 5px 0 10px;

          .ant-card-body:empty {
            background-color: @White;
          }

          .ant-card-body {
            background-color: @QuoteBuilderSection;
            padding: 18px 0 24px;
          }
        }

        .video-stream-container {
          background: none;
        }

        &-text {
          margin-top: 15px;
          font: normal normal normal 11px/18px Montserrat;
        }

        .testimonial-item {
          padding: 10px 0 0;
          margin: 0;
          background: @QuoteBuilderBackground;

          &-info-des {
            font: italic normal normal 14px/20px Lato;
            padding-right: 20px;
          }

          &-info-decor.bottom-decor {
            margin-right: 10px;
          }
        }

        .testimonial-item-square {
          margin-top: 5px;
          padding: 10px 0 0 10px;
        }

        .testimonial-item-avatar.testimonial-item-avatar.testimonial-item-avatar {
          width: calc(100% - 10px);
          height: calc(100% - 10px);
          padding: 0;
        }

        .slick {
          &-list {
            .margin-horizontal(-7px);

            position: relative;

            &::before,
            &::after {
              width: 7px;
              height: 100%;
              content: '';
              display: block;
              background: @QuoteBuilderSection;
              position: absolute;
              left: 0;
              top: 0;
              z-index: 1;
            }

            &::after {
              left: auto;
              right: 0;
            }
          }

          &-slide {
            padding: 0 5px;
          }

          &-arrow {
            width: 24px;
            height: 24px;
            background: @Primary;

            &.slick-disabled {
              background: @QuoteBuilderGreyBack;

              &::before {
                border-color: @White;
                opacity: 1;
              }
            }

            &::before {
              content: '';
              width: 10px;
              height: 10px;
              display: block;
              left: 9px;
              position: absolute;
              top: 7px;
              transform: rotate(45deg);
            }
          }

          &-prev {
            left: -12px;
            z-index: 1;

            &::before {
              border-bottom: 2px solid @White;
              border-left: 2px solid @White;
            }
          }

          &-next {
            right: -12px;
            z-index: 1;

            &::before {
              border-top: 2px solid @White;
              border-right: 2px solid @White;
              left: auto;
              right: 9px;
            }
          }
        }
      }

      &-template {
        .ant-card-body {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-top: 50px;
          text-align: center;
          height: 18rem;
        }

        &__icon {
          font-size: 50px;
          margin-bottom: 40px;
          color: @Black;
        }

        &__title {
          font: normal normal bold 16px/20px Lato;
          color: @Black;
        }

        &__subtitle {
          font: normal normal normal 14px/16px Lato;
          color: @Black;
          margin-bottom: 8px;
        }

        &__text {
          font: normal normal normal 12px/16px Lato;
          color: @QuoteBuilderGreyLightFont;
        }
      }

      &-photos {
        .ant-image {
          img {
            width: 100%;
          }
        }
      }

      &-title {
        font: normal normal bold 13px/16px Syne;
        color: @Black;
        text-transform: capitalize;

        &-highlight {
          color: @Black;
        }

        &-wrapper {
          display: flex;
          justify-content: space-between;
        }
      }

      &-hashtag {
        text-transform: uppercase;
        background: @QuoteBuilderBackground;
        font: normal normal bold 16px Lato;
        color: @White;
        padding: 5px 10px;
        margin: 18px 0 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &-add-btn {
        margin-top: 14px;

        &.un-select {
          background-color: transparent;
          border: 2px solid #be3c46;
          color: #be3c46;
        }
      }

      &-choose-text {
        margin-top: 20px;
        font: normal normal normal 14px/16px Lato;
        color: @QuoteBuilderGreyFont;
      }

      &-bio {
        font: normal normal normal 11px/18px Montserrat;
        color: #505050;
        margin-top: 20px;
      }

      &-surcharge {
        &-wrapper {
          display: flex;
          font: italic normal normal 11px/18px Montserrat;
          color: #505050;
          align-items: flex-end;
        }

        &-value {
          margin-right: 5px;
        }

        &-text {
          text-transform: capitalize;
        }
      }
    }

    &-modal {
      &-preferences {
        &-choose {
          .qb-singer-info-template__title {
            margin-bottom: 15px;
          }
        }
      }
    }

    &-preferences {
      &-explainer {
        display: flex;
        background-color: @QuoteBuilderSection;
        border: 1px solid @QuoteBuilderReceptionModalBuilder;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px 10px;
        margin-bottom: 20px;
        text-align: center;

        &__icon {
          font-size: 50px;
          color: @Black;
        }

        &__title {
          font: normal normal bold 16px/20px Syne;
          color: black;
          text-transform: capitalize;
          margin-top: 40px;
        }

        &__subtitle {
          font: normal normal normal 14px/16px Syne;
          color: @Black;
          margin-top: 14px;
        }

        &__text {
          font: normal normal normal 12px/16px Montserrat;
          color: @QuoteBuilderGreyLightFont;
          margin-top: 8px;
        }
      }

      &-minified {
        list-style: none;
        padding: 0;

        &__wrapper {
          border: 1px solid #ccc5c2;
          margin-top: 12px;
          background-color: @white;
          font: italic normal normal 11px/18px Montserrat;
          border-radius: 5px;
          overflow: hidden;

          &:hover {
            border-color: @QuoteBuilderBorder;
          }

          .qb-preference-item {
            border: none;
          }
        }

        &__item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 14px;
          background-color: @white;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          &_left {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            white-space: nowrap;
            max-width: calc(100% - 20px);
          }
        }

        &__button {
          &_add {
            text-align: start;
            width: 100%;
            display: flex;
            align-items: center;
            .padding-horizontal(10px);

            color: #505050;

            & > span {
              font: italic normal normal 11px/28px Montserrat;
              color: @QuoteBuilderPlaceHolder;
            }

            svg {
              font-size: 14px;
            }
          }

          &_add-more {
            text-align: start;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .padding-horizontal(10px);

            color: #505050;

            span {
              font: normal normal 600 11px/28px Montserrat;
              color: @QuoteBuilderPlaceHolder;
            }

            svg {
              font-size: 14px;
              shape-rendering: auto !important;
            }
          }
        }

        &__icon {
          &_grip-lines {
            margin-right: 16px;
            color: @Black;
          }

          &_plus {
            margin-right: 8px;
          }
        }

        .ordinal-index {
          margin-right: 5px;
          font-family: Montserrat;
          font-weight: 400;
          font-size: 11px;
          line-height: 1.2;
          font-style: normal;
          color: #505050;
        }

        .alias {
          font-family: Montserrat;
          font-weight: 400;
          font-size: 11px;
          line-height: 1.2;
          font-style: normal;
          color: #505050;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .qb-modal-singers__icon_plus {
          color: @QuoteBuilderGreyLightFont;
          cursor: pointer;
          font-size: 14px;

          &:active {
            color: @White;
          }
        }
      }
    }
  }

  &-summary {
    &-section {
      counter-reset: section-title-counter;

      .qb-details-section-title {
        text-align: center;
      }

      .qb-price-item {
        & > .qb-price-bluring-wrapper {
          background-color: @White;
          border: 1px solid @QuoteBuilderReceptionModalBuilder;
          border-radius: 5px;
        }
      }
    }

    &-title {
      text-transform: uppercase;
      color: @White;
      font-size: 18px;

      &::before {
        counter-increment: section-title-counter;
        content: counter(section-title-counter) '. ';
      }
    }

    &-subtitle {
      color: @White;
      font-weight: 700;
      font-size: 16px;
      margin-left: 18px;
    }

    &-text {
      margin-left: 18px;
      font-size: 16px;
    }

    &__qb-singer-preferences-minified {
      .qb-preference-item {
        margin-bottom: 0;
      }
    }
  }

  &-telephone-city-wrapper {
    display: flex;
  }
}

.band-modal {
  &-header {
    background: @QuoteBuilderBackground;
    padding: 30px;

    &-title {
      font: normal normal normal 26px/28px Lato;
      margin-right: 40px;
      float: left;
    }
  }

  &-close {
    float: right;
  }

  &-content {
    &.qb-scroll {
      display: flex;
    }

    &-ceremony {
      &-group {
        background: @QuoteBuilderBackground;
        padding-top: 50px;

        &-item {
          list-style-type: none;
          margin: 0 25px;

          &__wrapper {
            background: @QuoteBuilderBackground;
            padding: 10px 8px;
            border: 1px solid @QuoteBuilderReceptionModalBuilder;
            border-bottom: 3px solid @QuoteBuilderBookingBorder;
            position: relative;
            cursor: pointer;
          }

          &__name {
            padding: 20px 0 5px;
            text-align: center;
            font: normal normal bold 18px/18px Syne;
            color: #a8a8a8;
            text-transform: uppercase;
            letter-spacing: 1.3px;

            .font-awesome-icon {
              display: none;

              &.active-icon {
                margin-left: 5px;
              }
            }
          }

          &__button {
            display: none;
          }

          &.has-current {
            .band-modal-content-ceremony-group-item__name {
              color: @Black;

              .font-awesome-icon.active-icon {
                display: inline-block;
              }
            }
          }
        }

        &-wrapper.desktop {
          position: relative;
        }

        &-wrapper.desktop & {
          width: 82%;
          margin: 0 auto;
          display: grid;
          grid-template-columns: 25% 25% 25% 25%;

          &-item {
            &__wrapper {
              height: 100%;
            }

            &__thumb {
              width: 100%;
              height: auto;
              position: relative;

              img {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 60px;
                transform: translate(-50%, -50%);
              }

              &.no-cat {
                padding-top: 52.9%;

                > .band-modal-content-ceremony-group-item__thumb {
                  opacity: 0;
                }
              }
            }
          }
        }

        &-wrapper.desktop &-item.has-current &-item__wrapper {
          border: 3px solid @QuoteBuilderBookingBorder;
          border-bottom: none;
          z-index: 2;
        }

        &.mobile {
          display: none;
        }

        &::before {
          content: '';
          height: 3px;
          width: 100%;
          background: @QuoteBuilderBookingBorder;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 1;
        }
      }

      &-list {
        padding: 0 15px;
        justify-content: center;
        height: 100%;
        display: none;

        &-wrapper {
          background: @QuoteBuilderBackground;
          margin: 50px 10px 20px;
          position: relative;
          flex: 1;

          .no-cat__title {
            color: @Black;
            font: normal normal bold 26px/35px Syne;
            letter-spacing: 1.3px;
            text-transform: uppercase;
          }
        }

        &-item {
          background: @QuoteBuilderSection;
          padding-bottom: 20px;
          border: 1px solid @QuoteBuilderReceptionModalBuilder;

          &__header {
            display: flex;
            flex-wrap: wrap;
          }

          &__video {
            margin-bottom: 10px;

            .video-stream-container {
              background: none;
            }
          }

          &__title {
            font: normal normal bold 13px/16px Syne;
            margin-top: 5px;
            color: @Black;
            padding: 0 10px;
            height: 34px;
            text-transform: uppercase;
          }

          &__description {
            color: @Black;
            font-family: Montserrat;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            margin: 0 10px 20px;
            height: 100px;
            overflow-y: auto;
          }

          &__price {
            display: flex;
            flex-direction: column;

            &.mobile {
              display: none;
            }

            &-text {
              font: normal normal bold 12px/16px Syne;
              letter-spacing: 0.6px;
              color: @Black;
              text-transform: uppercase;
              padding-right: 5px;
            }

            &-number {
              font: normal normal bold 12px/16px Syne;
              color: @Black;
            }

            &-line {
              padding: 0 10px;
            }
          }

          &__button {
            display: flex;
            justify-content: flex-end;
            height: 100%;
            align-items: flex-end;

            button.selected {
              background-color: transparent;
            }

            .selected:hover {
              color: white;
            }
          }
        }

        &.active {
          display: flex;
          z-index: 10;
          opacity: 1;
        }
      }
    }

    &-no-cat {
      margin: 0 auto;
      padding: 40px 0 80px;
      background-color: @QuoteBookingBuilderBackground !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &__icon {
        margin: 0 auto 50px;
      }

      &__title {
        margin-bottom: 15px;
      }

      &__des {
        width: 500px;
        max-width: calc(100% - 20px);
        margin: 14px auto 20px;
        color: @QuoteBuilderGreyBack;
        font: normal normal normal 14px/28px Montserrat;
      }
    }
  }

  .ant-modal-body {
    padding: 0;
    overflow: auto;
  }
}

.testimonial {
  background: @QuoteBuilderBackground;
  padding: 0 10px 40px;

  .ant-carousel {
    .slick-slider {
      z-index: 123;

      .slick-prev,
      .slick-next {
        width: 24px;
        height: 24px;
        z-index: 10;
        background: @QuoteBuilderYellow;

        &::before {
          color: @White;
          font-size: 24px;
          position: absolute;
          top: -4px;
          left: 6px;
        }

        &.slick-disabled {
          background: @QuoteBuilderSlickDisable;

          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  .swiper {
    &-testimonials-arrow {
      position: absolute;
      width: 24px;
      height: 24px;
      background: #b39659;
      color: @White;
      transform: translate(0, -50%);
      z-index: 10;
      top: 50%;
      padding: 4px 0 0 8px;

      &.swiper-button-disabled {
        background: #5d5d5d;
      }

      &.swiper-arrow-next {
        left: auto;
        right: 0;
      }

      .svg-inline--fa {
        display: block;
        vertical-align: unset;
      }
    }
  }

  &-title {
    font: bold 26px/28px Lato;
    color: @White;
    margin-bottom: 10px;
    padding: 0 10px;
  }

  &-item {
    padding: 20px 20px 5px;
    background: @QuoteBuilderSection;
    margin: 0 10px;

    &-col-square {
      display: flex;
      align-items: center;
    }

    &-square {
      width: 100%;
      position: relative;

      &::after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }

    &-avatar {
      border-radius: 50%;
      position: absolute;
    }

    &-info {
      &-decor {
        font-size: 40px;
        color: @QuoteBuilderYellow;
        font-weight: bold;
        line-height: 13px;
        display: block;
        margin: 13px 0 -13px;

        &.bottom-decor {
          float: right;
          margin-bottom: -8px;
        }
      }

      &-des {
        font: italic 20px/27px Lato;
        color: @White;
        padding: 5px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }

      &-name {
        clear: both;
        color: @QuoteBuilderGreyLightFont;
        font: normal 14px/14px Lato;
      }
    }
  }
}

.end-same-line {
  display: flex;
  justify-content: flex-end;
}

.swiper-controller {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 40px;
  width: 75px;
  z-index: 10;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .swiper-pagination {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    max-height: 375px;

    .swiper-pagination-bullet {
      background: @QuoteBuilderReceptionModalBuilder;
      margin-bottom: 1rem;
      opacity: 1;
      width: 8px;
      height: 8px;
      display: inline-block;
      border-radius: 100%;
      cursor: pointer;

      &.current:not(.swiper-pagination-bullet-active) {
        border: 1px solid #b39659;
        width: 12px;
        height: 12px;
        padding: 1px;
        border-radius: 12px;
        overflow: hidden;
        position: relative;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 10px;
          height: 10px;
          border: 1px solid black;
          content: '';
          border-radius: 50%;
        }
      }

      &.swiper-pagination-bullet-active {
        background: @QuoteBookingBuilderPrimary;
        border-radius: 50%;
        border: 4px solid rgba(179, 150, 89, 0.17);
        box-sizing: content-box;
        background-clip: padding-box;
        width: 12px;
        height: 12px;
      }
    }
  }

  .swiper-arrow {
    color: @Black;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    align-items: center;
    cursor: pointer;
    margin-bottom: 35px;
    text-align: center;
    user-select: none;

    &-bottom,
    &-top {
      font: normal normal bold 12px/16px Syne;
    }

    svg {
      color: @QuoteBuilderReceptionModalBuilder;
    }

    &.swiper-button-disabled {
      color: @QuoteBuilderPagingDisable;
      cursor: default;
    }

    .svg-inline--fa {
      margin-bottom: 10px;
    }

    &.swiper-arrow-prev {
      margin-top: 35px;
      margin-bottom: 0;
    }

    .fa-chevron-right,
    .fa-chevron-down {
      margin-bottom: 0;
      margin-top: 10px;
    }

    .fa-chevron-left,
    .fa-chevron-right {
      display: none;
    }

    &-left,
    &-right {
      display: none;
    }

    &-top,
    &-bottom {
      display: block;
    }
  }
}

.reception-modal {
  &-content {
    position: relative;
    height: calc(100vh - 140px);
    min-height: 650px;
    max-height: 780px;
    padding: 30px 0;
    overflow: hidden;

    > .swiper {
      width: calc(100% - 155px);
      height: 100%;
      margin-left: 30px;
      overflow: hidden;
    }

    .swiper-slide {
      .reception-modal-item {
        top: 50%;
        bottom: unset;
      }
    }

    .swiper-slide-active {
      z-index: 1;

      &.swiper-slide {
        .reception-modal-item {
          max-width: 100%;
          transform: translateY(-50%);
          top: 50%;
          z-index: 1;

          &.expanded {
            height: auto;
          }

          &::before,
          &::after {
            content: '';
            position: absolute;
            width: 90%;
            height: 30%;
            background: transparent
              linear-gradient(0deg, @Black -90%, rgba(242, 242, 242, 0) 30%) 0 0
              no-repeat padding-box;
            top: -30%;
            left: 0;
            right: 0;
            margin: -1px auto;
          }

          &::after {
            transform: matrix(-1, 0, 0, -1, 0, 0);
            bottom: -30%;
            top: auto;
          }
        }

        &:first-child {
          ::before {
            display: none;
          }
        }

        &:last-child {
          ::after {
            display: none;
          }
        }
      }

      & ~ .swiper-slide {
        .reception-modal-item {
          top: -50%;
          height: 100%;
          overflow: hidden;
        }
      }
    }

    .swiper-arrow {
      line-height: 1.2;
    }
  }

  &-item {
    border: 1px solid @QuoteBuilderReceptionModalBuilder;
    background: @QuoteBuilderSection;
    padding: 30px;
    display: flex;
    position: relative;
    max-width: 90%;
    margin: 0 auto;

    &-video {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      overflow: hidden;

      &-wrapper {
        width: calc((2 / 3) * 100%);
        position: relative;
        margin-right: 30px;

        .dVjtWD {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: auto;
        }
      }

      &-placeholder {
        padding-top: 56%;
        display: block;
        width: 100%;
        position: relative;

        span {
          display: none;
        }
      }
    }

    .control-video-slider {
      display: none;
    }

    .video-list-swiper {
      display: none;
      margin: 40px 0 10px;

      .swiper-video-arrow {
        display: none;
      }
    }

    &-main-img {
      display: block;
      width: 100%;
      position: absolute;
    }

    &-title {
      font: normal normal bold 16px/16px Syne;
      letter-spacing: 0.8px;
      color: @Black;
      padding-right: 5px;
      text-transform: uppercase;
      margin-top: 7px;
    }

    &-price {
      font: normal normal bold 12px/16px Syne;
      color: @Black;
      text-transform: uppercase;
      margin-bottom: 40px;

      &-number {
        color: @Black;
        margin-top: 8px;
        margin-left: 5px;
      }
    }

    &-detail {
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      width: calc((1 / 3) * 100%);
      height: calc(100% - 60px);
      margin: 30px 0;
      padding: 0 30px;

      &-scroll {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-bottom: 30px;
      }

      &-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }

    &-des {
      font: normal normal normal 14px/20px Montserrat;
      height: 100%;
      overflow: hidden;

      p {
        margin-bottom: 20px;
        font: normal normal normal 14px/20px Montserrat;
        color: @Black;
      }

      strong {
        color: @Black;
        text-transform: uppercase;
        letter-spacing: 1.12px;
      }
    }

    &-show-more-box {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      height: 132px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      color: @Black;
      text-transform: uppercase;
      font: normal normal bold 12px/16px Syne;
      letter-spacing: 0.6px;
      line-height: 18px;
      cursor: pointer;

      svg {
        color: @QuoteBuilderReceptionModalBuilder;
        margin-left: 10px;
      }

      &.show-more {
        display: flex;
      }

      &-button {
        display: flex;
        align-items: center;
      }

      &.show-less {
        display: none;
      }
    }

    .testimonial {
      display: none;
    }

    &.expanded {
      .video-list-swiper {
        display: block;

        &:hover .swiper-video-arrow {
          display: flex;
        }
      }

      .reception-modal-item-video-placeholder {
        .control-video-slider {
          display: flex;
        }
      }

      .testimonial {
        background: @QuoteBuilderSection;
        display: block;
        padding: 30px 0 0;
        margin: 0 -10px;

        &-title {
          display: none;
        }

        &-item {
          background: @QuoteBuilderBackground;
        }
      }

      .reception-modal-item {
        &-show-more-box {
          &.show-more {
            display: none;
          }

          &.show-less {
            display: flex;
            height: 30px;
          }
        }

        &-video {
          &-list {
            display: flex;
          }
        }

        &-des {
          overflow-y: auto;
        }
      }
    }

    .band-modal-content-no-cat {
      min-height: 546px;
      width: 100%;
      background: @QuoteBuilderBackground;
      padding: 2% 0;

      &__centering {
        &.mobile {
          display: none;
        }
      }

      &__des {
        font: normal normal normal 14px/28px Montserrat;
        color: @Black;
      }
    }
  }
}

.detail-modal {
  height: auto;
  font: normal 18px/23px Lato;

  .ant-modal-close {
    top: 30px;
    right: 30px;

    &-icon {
      color: @QuoteBuilderGreyDetail;
    }

    &:hover {
      .ant-modal-close-icon {
        color: @QuoteBuilderGreyLightFont;
      }
    }
  }

  .ant-modal {
    &-body {
      padding: 0;
    }

    &-content {
      background: @QuoteBuilderSection;
    }
  }

  &-banner {
    position: relative;
    height: 100%;

    &-wrapper {
      display: flex;
      flex-direction: column;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.desktop {
        display: block;
      }

      &.mobile {
        display: none;
      }
    }

    &-quote {
      position: absolute;
      width: 405px;
      height: 175px;
      bottom: 85px;
      padding: 32px 148px 32px 17px;
      display: none; // wait for background img from design
    }
  }

  &-form {
    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }

    &-title {
      color: @Black;
      margin-bottom: 20px;
      font: normal normal normal 18px/22px Lato;
      text-transform: uppercase;
    }

    &-des {
      color: @Black;
      margin-bottom: 30px;
      font: normal 14px/23px Lato;
    }
  }

  .ant-form {
    padding: 0 17%;

    &-item {
      margin-bottom: 20px;

      &.form-button-item {
        margin-bottom: 0;
      }

      &-label {
        > label {
          color: @QuoteBuilderGreyLightFont;
          text-transform: uppercase;
          font: normal normal normal 14px/14px Lato;

          &.ant-form-item-required:not(
              .ant-form-item-required-mark-optional
            )::before {
            color: @QuoteBookingBuilderPrimary;
            right: 0;
          }
        }
      }

      .ant-input {
        background: @QuoteBuilderBackground;
        border: 1px solid @QuoteBuilderBorder;
        font: italic normal normal 11px/18px Montserrat;
        color: @Black;
        padding: 8px 10px;

        &::placeholder {
          color: @QuoteBuilderGreyLightFont;
        }
      }

      .ant-input-affix-wrapper {
        background: none;
        border: 1px solid @QuoteBuilderBorder;
        padding: 0;

        .ant-input {
          border: none;
          font: italic normal normal 11px/18px Montserrat;
          color: @Black;
        }

        .ant-input-suffix {
          margin: 0;
          //background: @QuoteBuilderBackground;
          padding: 0 15px;
        }
      }

      .ant-input-affix-wrapper:focus,
      .ant-input-affix-wrapper::selection {
        box-shadow: none;
      }

      .ant-select-selection-placeholder {
        color: @QuoteBuilderGreyLightFont;
        font: italic normal normal 11px/18px Montserrat;
        height: 100%;
        display: inline-flex;
        align-items: center;
      }

      &-has-error {
        .ant-input,
        .ant-input-affix-wrapper,
        .ant-input:hover,
        .ant-input-affix-wrapper:hover {
          background: none;
        }
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        -webkit-text-fill-color: @Black;
        -webkit-box-shadow: 0 0 0 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
      }

      .qb-button {
        margin: 10px auto 0;
        width: 214px;
        display: flex;
        justify-content: center;
        padding-top: 11px;
        padding-bottom: 9px;
      }
    }
  }

  &.unlock-price-modal {
    height: auto;
    padding-bottom: 0;

    .ant-modal-content {
      background: @QuoteBuilderGreyDetail;
    }
  }

  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active {
    color: @QuoteBuilderGreyBack;
    background-color: transparent;
    border-color: @QuoteBuilderGreyBack;
  }
}

.ant-modal-root {
  .ant-modal-mask,
  .ant-image-preview-mask {
    background-color: rgba(57, 57, 57, 0.72);
    backdrop-filter: blur(3px);
  }
}

.blur-item {
  filter: blur(5px);
  user-select: none;
}

.no-cat {
  text-align: center;
  display: block;
  background-color: @QuoteBuilderItemsBackgoundColor;

  &__wrapper {
    height: 100%;
    background-color: @QuoteBuilderItemsBackgoundColor;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font: normal normal bold 26px/35px Syne;
    letter-spacing: 1.3px;
    color: @Black;
    text-transform: uppercase;
    margin: 8px 0;
  }

  &__icon {
    display: block;
    position: relative;
    margin: 0 auto;

    img {
      width: 60px;
      margin: 0 auto;
    }
  }
}

@media only screen and (max-height: 650px) and (min-width: 1200px) {
  .qb-modal.qd-customize-small-size-popup {
    padding-bottom: 0;
    min-height: 100%;
  }

  .qb-modal-singers-list.qb-modal-singers-list {
    max-height: calc(100vh - 190px);
  }

  .qb-singer-info-card.qb-singer-info-card {
    max-height: calc(100vh - 200px);
  }

  .ant-modal-wrap {
    height: 100%;
  }

  .ant-modal-body {
    max-height: 100% !important;
  }
}

.ant-form-item-has-error {
  .qb-callback__mobile-wrapper {
    .ant-select {
      &:not(.ant-select-disabled) {
        &:not(.ant-select-customize-input) {
          .ant-select-selector {
            background: @QuoteBuilderBackground;
          }
        }
      }
    }
  }
}

.video-stream-container {
  height: 100%;

  > div {
    height: 100%;
  }

  stream {
    height: 100%;
  }
}

.unlock-price-modal .detail-modal-form-wrapper {
  background: @QuoteBookingBuilderBackground;

  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
}

.qb-details-outer-wrapper {
  .ant-btn-link {
    color: @Black;
  }

  ::selection {
    background: @QuoteBookingBuilderPrimary !important;
  }
}

.qb-details-event-date .ant-picker-input > input::placeholder {
  color: @QuoteBuilderPlaceHolder;
}

.qb-price-blur .qb-price-button-wrapper {
  margin: 50px auto 75px;
}

.qb-details-section-item-price-number {
  font: italic normal normal 13px/18px Montserrat;
  display: block;
}

.reception-modal-content {
  font-family: Syne;
  flex: 1;
}

.qb-modal-singers-list-header .ant-btn-text:not(.active) {
  color: @QuoteBuilderGreyFont !important;
}

.footer-container.qb-details-inner-wrapper {
  font: normal normal normal 14px/18px Montserrat;
}

.qb-details-inner-wrapper {
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: @QuoteBookingBuilderPrimary;
  }
}

#qb-package-details-preferences > div {
  background: transparent !important;
}

#qb-singer-modal-preferences > div {
  background: transparent !important;
}

.qb-singer-preferences-minified.qb-summary__qb-singer-preferences-minified {
  background: transparent !important;
}

.qb-page,
.submit-inquiry-form,
.unlock-price-modal {
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: red !important;
  }

  ::selection {
    background: @QuoteBookingBuilderPrimary !important;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: @QuoteBookingBuilderPrimary;
    color: @White;
  }
}

.qb-page {
  .ant-modal.ant-modal-confirm {
    .ant-modal-body {
      background-color: @QuoteBuilderBackground;
    }

    .ant-modal-confirm-body {
      & > .anticon {
        color: @QuoteBookingBuilderPrimary;
      }
    }

    .ant-btn-primary {
      color: @White;
      background: @QuoteBookingBuilderPrimary;
      background-color: @QuoteBookingBuilderPrimary;

      &:hover,
      &:focus {
        background: @QuoteBookingBuilderPrimary;
        background-color: @QuoteBookingBuilderPrimary;
      }

      padding: 11px 30px 8px;
      text-align: center;
      font-size: 14px;
      line-height: 14px;
      font-family: 'Josefin Sans', sans-serif;
      text-transform: uppercase;
      border: 1px solid @QuoteBookingBuilderPrimary;
      border-radius: 20px;
      height: 36px;
    }
  }

  div,
  p {
    &::-moz-selection {
      /* Code for Firefox */
      color: @White;
      background: @QuoteBookingBuilderPrimary;
    }

    &::selection {
      color: @White;
      background: @QuoteBookingBuilderPrimary;
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border: 1px solid @QuoteBookingBuilderPrimary;
    outline: 0;
    box-shadow: 0 0 2px @QuoteBookingBuilderPrimary;
  }
}

.quote-loading-wrapper {
  .ant-spin {
    color: @QuoteBookingBuilderPrimary;
  }

  .ant-spin-dot-item {
    background-color: @QuoteBookingBuilderPrimary;
  }
}

// Video Swiper List
.video-list-swiper {
  display: block;
  position: relative;
  margin: 10px 0;
  overflow: hidden;

  &-thumb {
    cursor: pointer;
    width: 100%;

    img {
      display: block;
      width: 100%;
      max-height: 100%;
    }

    &-wrap {
      position: relative;
      width: 100%;
    }

    &.active &-wrap {
      &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        border: 3px solid @QuoteBuilderReceptionModalBuilder;
        content: '';
        top: 0;
      }
    }
  }

  .swiper-video-arrow {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
    padding: 10px;
    background: @White;
    color: @QuoteBuilderReceptionModalBuilder;
    border: none;
    outline: none;
    transition: all 0.3s;

    &.swiper-button-disabled {
      opacity: 0.6;
      cursor: default;

      &:hover {
        background: @White;
      }
    }

    &-next {
      right: 0;
    }
  }

  &:hover .swiper-video-arrow {
    display: flex;
  }
}

.control-video-slider {
  &-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
    padding: 12px 4px;
    background: @White;
    align-items: center;
    justify-content: center;
    color: @QuoteBuilderReceptionModalBuilder;
    border: none;
    outline: none;
    transition: all 0.3s;

    &.swiper-button-disabled {
      opacity: 0.6;
      cursor: default;

      &:hover {
        background: @White;
      }
    }

    &-next {
      right: 0;
    }
  }
}

.singer-modal-container {
  overflow: unset;
}
