.subsection-title-text {
  color: @Primary;
  font-family:
    Josefin Sans,
    sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  white-space: nowrap;
}

.subsection-title-extracontent-narrow {
  color: @Primary;
  font-family:
    Josefin Sans,
    sans-serif;
  font-size: 1.8rem;
}

.subsection-title-extracontent-tab-inactive {
  color: @DarkGrey;
  font-family:
    Josefin Sans,
    sans-serif;
  font-size: 1.5rem;
  padding-bottom: 2px;
  cursor: pointer;
}

.subsection-margin-right {
  margin-right: 2rem;
}

.subsection-title-extracontent-tab {
  color: @Primary;
  font-family:
    Josefin Sans,
    sans-serif;
  font-size: 1.5rem;
  font-weight: 550;
  border-bottom: thin solid rgba(0, 0, 0, 0.45);
  cursor: pointer;
}

.text-button {
  line-height: 1.5715 !important;
  padding: 0;
  border: none;
  height: auto;

  &.ant-btn-text:hover,
  &.ant-btn-text:focus {
    color: @PrimaryHover;
    background: transparent;
  }
}
