@media (max-width: @screen-xl-max) {
  .qb {
    &-callback {
      &-text {
        font-size: 15px;
      }
    }
  }
}

// max-width > 1199px
@media (max-width: @screen-lg-max) {
  .qb {
    &-button {
      font-size: 14px;
      padding: 0 26px;

      &-change {
        width: 100%;
      }

      &.saved {
        border: thin solid @Black;
        padding: 0 20px;

        .svg-inline--fa {
          margin-top: -3px;
          margin-left: 15px;
        }
      }
    }

    &-callback {
      &-outer-wrapper {
        padding-top: 0;

        .qb-details-inner-wrapper {
          width: auto;
          border: none;
          padding: 0;
          margin: 30px 24px;
        }
      }
    }

    &-details {
      &-title {
        margin-top: -15px;
        font-size: 39px;
      }

      &-gigs {
        &-head {
          .qb-button {
            margin: 25px 0 10px;
          }
        }
      }

      &-outer-wrapper {
        display: flex;
        flex-direction: column;

        .ant-tabs-top,
        .ant-tabs-bottom {
          > .ant-tabs-nav {
            margin-top: 0;
            position: relative;

            &::before {
              display: block;
              bottom: 100%;
              border-width: 2px;
              border-color: @QuoteBuilderReceptionModalBuilder;
            }
          }
        }

        .footer-container {
          width: auto;
          margin: 0 24px;
          padding: 18px 0;

          .terms-conditions-link {
            font-family: Montserrat;
            font-size: 11px;
            line-height: 14px;
            color: @QuoteBuilderGreyFont;
          }
        }

        .rk-default-footer-row {
          flex-direction: column;
        }

        .footer-social_links,
        .footer-list,
        .footer-separator {
          display: none;
        }

        .footer-copyright {
          font-size: 11px;
          color: @QuoteBuilderGreyFont;
          line-height: 1;
        }
      }

      &-inner-wrapper {
        &.mobile {
          display: flex;
          flex-direction: column;
          padding-top: 24px;
          width: 100%;
          max-width: @QuoteBuilderMobileMaxWidth;
          margin: 0 auto;

          &.fixed_header {
            //padding-top: 141px;
          }

          .qb-details-event-date-section {
            margin-bottom: 30px;
            padding-top: 10px;
          }

          .ant-tabs {
            flex-grow: 1;
            background: @QuoteBuilderBackground;

            &-nav {
              background: @QuoteBuilderBackground;
              position: relative;
            }

            &.fixed_tabbar {
              padding-bottom: 49px;

              .ant-tabs-nav {
                position: fixed;
                bottom: 0;
                top: auto;
                width: 100%;
                max-width: @QuoteBuilderMobileMaxWidth;
                z-index: 999;
              }
            }
          }

          .ant-tabs-top > .ant-tabs-nav {
            margin-bottom: 0;
          }
        }

        .qb-details-gigs-head {
          // margin-top: -10px !important;
        }
      }

      &-section {
        padding-bottom: 20px;
        margin: 0 22px 25px;
        width: auto;

        .qb-singer-preferences-top {
          display: flex;
          margin-bottom: 15px;
          justify-content: space-between;
          align-items: center;

          .qb-button {
            width: max-content;
            margin: 0;
          }
        }

        .qb-singer-list {
          max-height: unset;
        }

        .qb-preference-items {
          .qb-preference-placeholder {
            &__text {
              font: normal normal bold 12px/17px Syne;
              letter-spacing: 0.6px;
              margin-top: 6px;
            }
          }
        }

        &-title {
          font-size: 22px;
          font-weight: bold;
          text-align: center;

          &.qb-price-title {
            margin-bottom: 10px;
          }

          &.qb-details-question {
            margin-bottom: 10px;
          }
        }

        &-item {
          &-title {
            font-size: 20px;
          }

          &-price {
            &-text {
              display: block;
              font-size: 12px;
            }

            &-number {
              font: normal 16px/18px Lato;
            }
          }

          &-location {
            &-kind {
              font-size: 12px;
            }

            &-name {
              font: normal 14px/16px Lato;
            }
          }

          &-field {
            margin-bottom: 15px;

            &-name {
              font: normal 12px/15px Lato;
            }

            &-control {
              font: normal 14px/14px Lato;
              height: 30px;

              .ant-picker,
              .ant-btn {
                height: 28px;
              }

              textarea.ant-input {
                font: normal 14px/14px Lato;
                padding: 9px 0 0 14px;
                height: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: calc(100% - 40px);
              }

              &.address-field {
                height: 44px;
              }
            }
          }
        }
      }

      &-singers {
        height: 90vh;

        &-subtitle {
          margin-top: 20px;
        }

        &-col {
          .qb-scroll {
            overflow: auto;
          }
        }
      }
    }

    &-group-modal {
      .ant-modal-content {
        .ant-modal-header {
          padding: 10px 20px;
          border-bottom: 1px solid #a8a8a8;

          .ant-modal-title {
            .qb-group-modal-title-wrapper {
              display: flex;
              align-items: center;

              .qb-group-modal-title-left {
                position: unset;

                .qb-button {
                  border: none;
                  padding: 10px 20px 10px 0;
                }
              }

              .qb-group-modal-title-center {
                color: @Black;
              }
            }
          }
        }
      }

      &-title {
        font: normal normal bold 13px/16px Syne;
        letter-spacing: 0.65px;
        text-transform: uppercase;
      }

      .band-modal-content-ceremony-list-item {
        display: block;
        padding: 30px 0;
        border: none;
        border-bottom: 1px solid #ccc5c2;

        &-no-cat {
          &__icon {
            padding-top: 50%;
            position: relative;
            background-color: #dad3d0;

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          &__info {
            padding: 20px 36px;
            text-align: left;

            .band-modal-content-no-cat__title {
              font: normal normal bold 13px/16px Syne;
            }

            .band-modal-content-no-cat__des {
              width: 100%;
              max-width: none;
              font: normal normal normal 11px/18px Montserrat;
              margin: 22px auto 30px;
            }
          }
        }
      }
    }

    &-logo {
      display: block;
      margin-top: 14px;

      img {
        margin: auto;
        height: 47px;
        display: block;
      }

      &.fixed_header {
        padding-top: 64px;
      }
    }

    &-modal {
      width: 100% !important;
      top: 0;
      max-width: @QuoteBuilderMobileMaxWidth;
      margin: 0 auto;

      .intl-tel-input .country-list {
        bottom: 100%;
      }

      &-title {
        &-left {
          flex-direction: column;
          align-items: flex-start;
          margin: -4px 0;

          .qb-city-dropdown-trigger.qb-city-dropdown-trigger {
            margin: 0 0 10px;
          }
        }
      }

      &.ant-modal {
        height: 850px;
        width: 100% !important;
        max-height: 100vh;
        max-height: -webkit-fill-available;
        max-width: 100vw;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: transparent;

        .ant-modal-content {
          margin: 0 auto;
          width: 100%;
          max-width: 600px;
          display: flex;
          flex: 1;
          flex-direction: column;
          overflow: hidden;
        }
      }

      &.band-modal {
        .ant-modal-content {
          display: flex;
          flex-direction: column;
          flex: 1;

          .ant-modal-body {
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow: hidden;

            .band-modal-content:not(.qb-scroll) {
              display: flex;
              flex-direction: column;
              overflow: hidden;

              .reception-modal-content {
                &.qb-scroll {
                  overflow-y: auto;
                  min-height: auto;
                  max-height: auto;
                }

                .swiper {
                  .swiper-slide {
                    &.swiper-slide-active {
                      .reception-modal-item {
                        position: unset;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.ceremony-modal {
        &.ant-modal {
          max-width: 600px;
        }

        .ant-modal-content {
          display: flex;
          flex-direction: column;
        }

        .band-modal-content {
          flex: 1;
        }
      }

      &-singers {
        .ant-modal-body {
          .qb-modal-singers__row {
            height: auto;
            overflow-y: auto;

            .qb-modal-singers__col {
              height: auto;
            }
          }
        }

        &.ant-modal {
          .qb-modal-title-left {
            .qb-modal-title {
              font-size: 26px;
            }

            .qb-city-dropdown-trigger.qb-city-dropdown-trigger {
              margin: 10px 0;
            }
          }

          .ant-modal-content {
            display: flex;
            flex-direction: column;
            height: unset;
            flex: 1;
            max-height: unset;
            overflow: hidden;
          }
        }

        .ant-modal-body {
          display: flex;
          flex: 1;
          overflow: hidden;
          padding: 0;
        }

        &.qb-modal-singer-info {
          .ant-modal-body {
            overflow: hidden;

            .qb-singer-info {
              display: flex;
              overflow: hidden;
            }

            > div {
              flex: 1;
            }
          }
        }

        &-list {
          && {
            padding: 0;
            max-height: unset;
            margin: 0;
          }

          &-choose {
            &-text {
              font: normal normal normal 10px/12px Lato;
            }

            .qb-modal-singers-music-icon {
              font-size: 25px;
              margin-bottom: 10px;
            }
          }

          &-header {
            padding: 0 30px;
            transform: translateY(0);
            align-items: flex-start;

            .qb-modal-content-tabs {
              transform: translateY(-8px);

              .qb-modal-content-tabs-buttons {
                span {
                  font-size: 10px;
                }
              }
            }
          }

          &.qb-scroll {
            padding-right: 0 !important;
            max-height: unset;
            min-height: unset;
            margin-right: 0;
            padding-left: 1px;
            padding-bottom: 30px;
          }

          .ant-spin-container {
            grid-template-columns: 1fr 1fr;
            gap: 0;
          }

          .ant-list-item {
            margin: 0;

            &.selected {
              box-shadow: none;

              .qb-singers-card.selected {
                border: 2px solid @QuoteBookingBuilderPrimary;
              }
            }
          }

          &-mobile {
            margin-bottom: 30px !important;

            .ant-list-item {
              padding: 12px 0 12px 12px;
              background: @QuoteBuilderSection;
              border-bottom: none !important;
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: 0;
              }

              .ordinal-index {
                margin-right: 10px;
              }

              .alias {
                color: @White;
              }

              .ant-btn-link {
                color: @White;
              }
            }

            .ant-empty {
              display: none;
            }
          }

          .qb-singers-card {
            .ant-card-body {
              justify-content: left;
              font: normal normal bold 8px/10px Syne;
            }
          }
        }

        &-check-icon {
          font-size: 17px;
          color: @QuoteBookingBuilderPrimary;
          margin-left: 10px;
        }

        .ant-btn-text:hover {
          border-color: transparent;
        }

        .qb-details-singers-subtitle {
          margin-top: 0;
        }
      }

      &-singer-info {
        .qb-modal-content-subtitle {
          display: none;
        }

        .ant-modal-body {
          padding: 0;
        }
      }

      &-content {
        &-subtitle {
          && {
            font: normal normal bold 13px/16px Syne;
          }
        }
      }

      &-notification {
        max-width: 500px;
        padding: 25px;
        text-align: center !important;

        .ant-modal-content {
          padding: 48px 20px;
        }

        &-image {
          width: 80%;
        }

        &-title {
          margin-bottom: 20px;

          &.desktop {
            display: none;
          }

          &.mobile {
            display: block;
          }
        }

        &-content {
          margin: 18px 0;
          font: normal normal normal 13px/22px Montserrat;
        }

        .ant-modal-close {
          svg {
            color: #a8a8a8;
          }
        }
      }

      &-successful-notification {
        max-width: 500px;
        padding: 20px;
        text-align: center !important;

        &-image {
          width: 80%;
        }

        .ant-modal-content {
          padding: 48px 20px;
        }

        &--request-callback {
          &-content {
            font: normal normal normal 13px/22px Montserrat;
          }

          &-title {
            margin-bottom: 20px;

            &.mobile {
              display: block;
            }

            &.desktop {
              display: none;
            }
          }
        }

        &--enquiry {
          &-content {
            font: normal normal normal 13px/22px Montserrat;
            margin: 30px 0;
          }

          &-title {
            margin-bottom: 20px;

            &.mobile {
              display: block;
            }

            &.desktop {
              display: none;
            }
          }
        }
      }

      &-location {
        &-content {
          &-btns {
            margin: 14px 0;
          }
        }
      }

      &-request-callback {
        max-width: 400px !important;
        padding: 20px !important;
        text-align: center !important;
        height: 760px !important;

        &-image {
          width: 80%;
        }

        &-title {
          margin-bottom: 20px;

          &.desktop {
            display: none;
          }

          &.mobile {
            display: block;
          }
        }

        &-content {
          display: none;
        }

        & > .ant-modal-content {
          & > .ant-modal-body {
            padding: 30px 20px;
            overflow-y: auto;
          }
        }

        &__form {
          margin: 20px 0;

          .ant-form-item-label {
            padding: 0;
          }
        }
      }

      &-unlock-price {
        max-width: 400px !important;
        padding: 20px !important;
        text-align: center !important;
        height: 760px !important;

        &-note {
          text-align: left;
        }

        .qb-button {
          width: 100%;
        }

        & > .ant-modal-content {
          & > .ant-modal-body {
            padding: 30px 20px;
            overflow-y: auto;
          }
        }
      }

      .ant-modal-header {
        padding: 10px 20px 10px 36px;

        .qb-city-dropdown-trigger {
          &-name {
            margin-right: 30px;
          }
        }
      }

      .ant-modal-content {
        max-height: 100vh;
      }

      .ant-modal-title {
        font-size: 18px;
        line-height: 35px;
        letter-spacing: 1.3px;
      }

      .ant-btn-text {
        padding: 0;
        align-items: flex-start;

        .font-awesome-icon {
          &.fa-times {
            font-size: 26px;
          }
        }
      }
    }

    &-navbar {
      &-wrapper {
        justify-content: center;
        flex-direction: column-reverse;
        height: auto;
        align-items: center;
        padding: 0;
        width: 100%;
        max-width: @QuoteBuilderMobileMaxWidth;
        margin: 0 auto;

        .qb-telephone-city-wrapper {
          width: 100%;
          justify-content: space-between;
          padding: 10px 15px;
          flex-wrap: wrap;

          .tel {
            font-size: 12px;
            font-family: Montserrat;
            font-weight: 600;
            margin: 5px 10px 5px 0;
          }

          button {
            margin: 5px 0;
          }
        }

        .qb-city-dropdown-trigger {
          padding: 4px 18px;
          height: auto;

          &-name {
            font-size: 12px;
            font-weight: 600;
          }
        }

        .qb-navbar-logo {
          padding: 10px;
          display: none;

          img {
            margin: auto;
            height: 47px;
          }
        }
      }
    }

    &-singer {
      &-info {
        &-card {
          && {
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 0;

            .ant-card-body {
              padding: 16px 36px;
              flex: 1;
            }

            .qb-singer-info-add-btn {
              width: 100%;

              &.un-select {
                background-color: @QuoteBookingBuilderBackground;
                color: @QuoteBookingBuilderPrimary;
              }
            }
          }
        }

        &-template {
          &__icon {
            margin-bottom: 0;
          }
        }
      }

      &-modal {
        &-preferences {
          &-choose {
            padding: 15px 30px;
            display: flex;
            margin: 30px 0;

            .qb-singer-info-template__icon {
              margin-right: 30px;
            }
          }

          &-mobile {
            margin-bottom: 30px;
            background-color: #f9f6f1 !important;

            .qb-preference-item {
              margin-bottom: 10px;
            }

            .qb-singer-preferences-minified__item {
              background-color: #f9f6f1;
              border: 1px solid #ccc5c2;

              &_left {
                .ordinal-index,
                .alias {
                  font: normal normal bold 9px/11px Syne;
                  text-transform: uppercase;
                  letter-spacing: 0.45px;
                  color: #505050;
                }
              }
            }

            &-wrapper {
              padding: 30px 30px 0 30px;

              .qb-details-singers-subtitle {
                min-width: 80%;
                width: 260px;
                margin-bottom: 20px;
              }
            }

            .qb-modal-singers__icon_plus {
              color: @QuoteBuilderGreyLightFont;
              cursor: pointer;

              &:active {
                color: @White;
              }
            }
          }
        }
      }
    }
  }

  .band {
    &-modal {
      &-content {
        &-ceremony {
          // height: unset !important;

          .ant-carousel {
            .slick-slider.band-modal-content-ceremony-group {
              &.desktop {
                display: none;
              }
            }
          }

          &-group {
            padding-top: 30px;

            &-item {
              padding: 0;
              margin: 0;

              &.has-current &__button {
                span,
                svg {
                  color: @QuoteBookingBuilderPrimary;
                }

                span {
                  font-style: normal;
                  font-weight: 600;
                }
              }

              &__wrapper {
                border: none;
                padding: 0;
                background: @QuoteBuilderSection;
              }

              &__thumb {
                width: 100%;
                height: auto;
                object-fit: cover;
                min-height: 161px;
                position: relative;

                .font-awesome-icon {
                  position: absolute;
                  width: 50px;
                  height: 50px;
                  border: 1px solid @QuoteBuilderGreyFont;
                  border-radius: 50%;
                  color: @QuoteBuilderGreyFont;
                  top: 50%;
                  left: 50%;
                  margin-top: -25px;
                  margin-left: -25px;
                  display: block;
                }
              }

              &__name {
                text-align: left;
                padding: 15px 0;
                font-size: 13px;
                line-height: 1;
                color: @Black;

                span,
                svg {
                  color: @QuoteBookingBuilderSecondary;
                }
              }

              &__button {
                display: flex;
                float: right;
                padding: 0;
                height: auto;
                transform: translateY(-2px);

                > span {
                  margin-right: 8px;
                  font-family: Montserrat;
                  font-size: 11px;
                  font-style: italic;
                  color: #505050;
                }

                .font-awesome-icon {
                  display: block;
                  font-size: 10px;
                }
              }

              &-no-cat {
                background: @QuoteBuilderSection;
                display: flex;
                flex-direction: column;
                padding: 0;
                border: none;
                cursor: pointer;

                .band-modal-content-no-cat {
                  display: flex;
                  flex-direction: column;

                  &__icon-container {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #dad3d0;
                    min-height: 161px;
                    width: 100%;
                  }

                  &__icon {
                    margin: 0;
                  }

                  &__wrap-icon {
                    border-radius: unset;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 15px;
                    height: 105px;
                    padding: 0;
                  }

                  &__text {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 15px 0;
                    width: 100%;
                  }

                  &__selected-label {
                    color: @QuoteBookingBuilderPrimary;
                    font-size: 11px;
                    font-weight: 700;

                    span {
                      margin-right: 6px;
                    }
                  }

                  &__title {
                    text-transform: uppercase;
                    font-size: 13px;
                    color: @Black;
                    line-height: 1.4;
                    margin: 0;

                    .font-awesome-icon.active-icon {
                      margin-left: 5px;
                      display: none;
                    }
                  }
                }

                &.has-current {
                  .band-modal-content-no-cat__title {
                    color: @Black;

                    .font-awesome-icon.active-icon {
                      display: inline-block;
                    }
                  }
                }

                .qb-button {
                  padding: 0 15px;
                }
              }
            }

            &-wrapper.desktop {
              display: none;
            }

            &.mobile {
              display: grid;
              padding: 30px 36px 50px;
              grid-template-rows: repeat(4, 1fr);

              &::before {
                display: none;
              }
            }
          }

          &-list {
            &-wrapper {
              display: none;
            }

            &-item {
              display: none;

              &__header {
                display: block;
              }

              &__video {
                margin-bottom: 10px;
              }

              &__title {
                font-weight: 700;
                font-size: 13px;
                color: @Black;
                font-family: Syne;
                margin: 20px 0 0;
                height: auto;
              }

              @media (min-width: 1024px) and (orientation: landscape) {
                &__price {
                  display: none;
                }

                &__price-line {
                  .ant-col-lg-12 {
                    max-width: 100% !important;
                    flex: 0 0 100% !important;
                    margin-bottom: 1rem;
                  }
                }

                &__description {
                  margin-bottom: 0 !important;
                  height: 90px;
                }
              }

              &__price {
                align-items: flex-end;

                &-text {
                  font: normal normal bold 12px/16px Syne;
                }

                &-number {
                  font: normal normal bold 12px/16px Syne;
                  color: @Black;
                }

                &.mobile {
                  display: block;
                }
              }

              &__description {
                font-size: 11px;
                line-height: 18px;
                color: #505050;
                height: auto;
              }

              &__button {
                padding: 0 30px;

                button.selected {
                  background-color: white;
                }
              }
            }
          }

          &-no-cat {
            display: none;
          }
        }

        &.qb-scroll {
          height: auto !important;
          min-height: unset !important;
        }
      }
    }
  }

  .reception-modal {
    &-content {
      height: auto;
      padding: 0;

      .swiper-slide-active {
        &.swiper-slide {
          .reception-modal-item {
            margin-top: 0;
            top: unset;
            bottom: unset;
            transform: none;
          }
        }
      }

      > .swiper {
        width: 100%;
        height: auto;
        margin: 0 0 110px;

        .video-list-swiper {
          margin: 10px 0;
        }
      }

      .swiper-controller {
        margin: 0 auto;
        max-width: 600px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: @QuoteBuilderBackground;
        border-top: 2px solid #6a6a6a;
      }
    }

    &-item {
      display: block;
      padding: 36px;
      min-width: 100%;
      border: none;
      border-bottom: 1px solid #ccc5c2;

      &::after {
        display: none;
      }

      .band-modal-content-no-cat {
        min-height: unset;
        padding: 0;

        &__centering {
          display: none;
          width: 100%;

          &.mobile {
            display: block;

            .band-modal-content-no-cat {
              &__icon {
                width: 100%;
                position: relative;
                background-color: #dad3d0;
                max-width: unset;
                border-radius: 0;
                padding-top: 56.2313%;

                img {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }

              &__title {
                text-align: left;
                font: normal normal bold 13px/16px Syne;
                letter-spacing: 0.65px;
                margin: 15px 0;
              }

              &__des {
                text-align: left;
                width: 100%;
                font: normal normal normal 11px/18px Montserrat;
                color: #505050;
                margin: 22px 0;
                max-width: unset;
              }
            }
          }
        }
      }

      &-title {
        font: normal normal bold 13px/17px Syne;
        letter-spacing: 0.65px;
        margin-top: 0;
      }

      &-price {
        font: normal normal bold 9px/18px Syne;
        margin-bottom: 20px;
        text-align: right;
      }

      &-video {
        &-wrapper {
          width: 100%;
          margin: 0 auto;
        }
      }

      .testimonial {
        display: block;
        padding: 0;
        background: @QuoteBuilderSection;

        &-title {
          display: none;
        }

        &-item {
          margin: 0;
          background: #262626;
        }
      }

      &-detail {
        overflow: unset;
        position: relative;
        width: 100%;
        padding: 0;
        margin: 10px 0;

        &-scroll {
          max-height: 500px;
          overflow: unset;

          .reception-modal-item-detail-header {
            display: flex;
            flex-direction: column;

            .qb-button-change {
              width: 100%;
              margin-bottom: 20px;
              padding: 11px 10px 8px;
              font: normal normal bold 12px/14px Montserrat;
              letter-spacing: 0.72px;
            }
          }

          .reception-modal-item-des {
            margin: 0 -10px;
            padding: 0 10px;
          }
        }
      }

      &.expanded {
        .reception-modal-item-detail {
          padding-bottom: 0;
        }
      }
    }

    &-item,
    &-item.expanded {
      &-show-more-box {
        &.show-more,
        &.show-less {
          display: none;
        }
      }
    }
  }

  .swiper-controller {
    position: relative;
    transform: none;
    width: 100%;
    right: auto;
    top: auto;
    padding: 20px 30px;
    height: 110px;
    display: block;

    .swiper-pagination {
      flex-direction: row;
      width: 100%;
      height: 20px;
      justify-content: space-between;

      .swiper-pagination-bullet {
        margin: 0;
      }
    }

    .swiper-arrow {
      position: absolute;
      bottom: 0;
      flex-direction: row;
      align-items: center;
      right: 30px;

      &.swiper-arrow-prev {
        left: 30px;
        right: auto;
        margin-top: 0;
        margin-bottom: 35px;
      }

      .fa-chevron-left {
        display: block;
        margin: 0 25px 0 0;
      }

      .fa-chevron-right {
        display: block;
        margin: 0 0 0 25px;
      }

      .fa-chevron-down,
      .fa-chevron-up {
        display: none;
      }

      &-left,
      &-right {
        display: block;
      }

      &-top,
      &-bottom {
        display: none;
      }
    }
  }

  .swiper-video-arrow {
    display: none !important;
  }
}

@media (max-width: @screen-md-max) {
  .detail-modal {
    height: auto;
    max-width: 576px;

    &.unlock-price-modal {
      height: auto;
      width: 100% !important;

      .ant-modal-close-x {
        color: black;
      }
    }

    .ant-form {
      padding: 40px 30px 25px;
    }

    .ant-form-item .qb-button {
      margin: 0;
      width: 100%;
      font: normal normal bold 14px/16px Lato;
      height: auto;
      padding: 8px 10px;
    }
  }

  .qb {
    &-details {
      &-section {
        &-gigs {
          &-head {
            margin-top: 0;
          }
        }

        &-no-cat__icon {
          left: 50%;
          transform: translateX(-50%);
        }

        &.qb-scroll {
          height: auto;
        }
      }
    }
  }

  .qb-details-item-1st-view-icon .svg-inline--fa {
    transform: rotate(-90deg);
  }
}

// > 767px
@media (max-width: @screen-sm-max) {
  .detail-modal {
    max-width: 100%;
  }

  .ant-modal {
    margin: 0;
  }
}

// > 575px
@media (max-width: @screen-xs-max) {
  .qb {
    &-details {
      &-title {
        padding: 0 30px;
        margin: 4px 0 24px;
      }

      &-section {
        &-title {
          margin-bottom: 10px;
        }

        .mobile-item {
          width: 95%;
        }

        &-gigs-head {
          flex-direction: column;
        }

        .qb-singer-preferences-top {
          display: block;
          margin-bottom: 15px;

          .qb-button {
            width: 100%;
          }
        }

        .qb-price-submitted {
          .qb-price-button-wrapper {
            .qb-button {
              width: 100%;
            }
          }
        }
      }

      &-item {
        &-price {
          display: flex;
          align-items: center;

          &-text {
            font-size: 9px;
            margin-right: 4px;
            color: #505050;
          }

          &-number {
            font-size: 9px;
            color: #505050;
          }
        }

        &-field-control-duration {
          &-decrease,
          &-increase {
            width: 20%;
          }
        }
      }
    }

    &-callback {
      &-wrapper {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &-text {
        font-size: 14px;
        line-height: 16px;
      }

      &-right {
        .qb-button {
          margin: 0 auto;
          padding: 0 40px;
        }
      }
    }

    &-modal {
      &-notification {
        max-width: 100%;
      }

      &-successful-notification {
        max-width: 100%;
      }
    }
  }

  .reception-modal-content {
    > .swiper {
      margin: 0 0 64px;
    }
  }

  .detail-modal {
    &-banner {
      img {
        &.desktop {
          display: none;
        }

        &.mobile {
          display: block;
        }
      }
    }

    &-form {
      &-title {
        font: normal normal normal 18px/36px Lato;
      }

      &-des {
        font: normal normal normal 14px/16px Lato;
        margin-bottom: 30px;
      }
    }
  }

  .control-video-slider {
    .control-video-slider-arrow {
      padding: 2px;

      svg {
        font-size: 14px;
      }
    }
  }

  .swiper-controller {
    padding: 10px 20px 20px;
    height: 64px;

    .swiper-arrow {
      font: normal normal bold 10px/12px Syne;

      &.swiper-arrow-prev {
        left: 20px;
        margin-bottom: 10px;

        .fa-chevron-left {
          margin-right: 5px;
        }
      }

      &.swiper-arrow-next {
        right: 20px;
        margin-bottom: 10px;

        .fa-chevron-right {
          margin-left: 5px;
        }
      }
    }

    .swiper-pagination {
      height: 12px;

      .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
      }
    }
  }

  .band-modal-content-ceremony {
    &-group-item-no-cat {
      .band-modal-content-no-cat__icon {
        background-color: transparent;

        .font-awesome-icon {
          border-width: 3px;
          height: 35px;
          width: 35px;
        }
      }

      .band-modal-content-no-cat__text {
        .qb-button {
          height: 28px;
        }
      }
    }
  }

  .no-cat__title {
    font-size: 12px;
    line-height: 1.4;
  }
}

#qbDetails {
  .ant-tabs-ink-bar {
    background: @QuoteBookingBuilderPrimary;
    height: 3px;
  }
}

// Can't not add qb-scroll class into ant-modal-body for
// qb-modal-unlock-price,qb-modal-notification,qb-modal-request-callback => css manually for it
.qb-modal-unlock-price,
.qb-modal-notification,
.qb-modal-request-callback,
.qb-modal-successful-notification {
  .ant-modal-body {
    &::-webkit-scrollbar {
      margin-top: 0;
      padding-top: 0;
      width: 6px;
      height: 6px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: #ccc5c2;
    }

    &:hover {
      @media (min-width: @screen-lg) {
        &::-webkit-scrollbar-thumb {
          background: #afacac;
        }
      }
    }
  }
}

.video-list-swiper {
  .swiper-video-arrow {
    padding: 6px;
  }
}
