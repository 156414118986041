.preferred-musicians {
  &-order {
    &-item {
      flex-basis: calc(100% - 50px);
      overflow: hidden;
    }

    &-name {
      flex-basis: calc(100% - 28px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.select-musician {
  &-row {
    min-height: 4.4rem;

    &:extend(.ersatz-table-row);

    @media (max-width: @screen-lg) {
      width: calc(100% - 40px);
    }
  }
}
