.register-form {
  width: 100%;

  .button-primary {
    width: 100%;
  }

  &__performer-select {
    &.ant-select-disabled
      .ant-select-selection--multiple
      .ant-select-selection__choice {
      background-color: #faf9f9;
      color: #555;
    }

    &.ant-select-disabled .ant-select-selection {
      background-color: #faf9f9;
      cursor: default;
    }
  }

  .google-map-prediction-container {
    text-align: left;
  }
}

.invitation-modal {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-selector {
    height: 36px;
  }

  .ant-select-single.ant-select-show-arrow {
    .ant-select-selection-placeholder {
      padding-top: 2px;
    }
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-top: 2px;
  }
}
