.ant-menu {
  &&.ant-menu-dark {
    .ant-menu-sub {
      background: @Sidebar;

      &.ant-menu-inline {
        box-shadow: none;
      }
    }
  }
}

.ant-form-item {
  .ant-form-item-label {
    overflow: visible;

    label {
      color: #595959;
      font-size: 16px;

      .ant-typography {
        color: #595959;

        &.ant-typography-danger {
          color: #ff4d4f;
        }
      }
    }
  }

  .ant-form-item-explain-error {
    font-size: 14px;
  }

  .ant-picker-input > input {
    font-size: 14px;
    color: #595959;
  }

  .ant-form-item-required {
    &::before {
      position: absolute;
      margin: 0;
      top: -2px;
      right: 2px;
      line-height: unset !important;
    }
  }
}

.ant-col-xs-24 {
  min-height: 0;
}

.ant-card-head-title {
  font-size: 2rem;
}

.ant-select-dropdown-menu-item-group-title {
  font-size: 1em;
  font-weight: bold;
}

.ant-dropdown-menu-submenu-title {
  .ant-dropdown-menu-submenu-expand-icon {
    top: 50%;
    transform: translateY(-50%);
  }
}

small {
  font-size: 1.2rem;
}

.ant-dropdown {
  z-index: 500 !important;

  &-placement-bottomRight {
    z-index: 1050 !important;
    position: fixed !important;
  }
}

.ant-dropdown-menu-item-group-list {
  list-style: none;
  padding-left: 0;
}

.ant-radio {
  .ant-radio-inner {
    box-sizing: content-box;
    border-width: thin;

    &::after {
      width: 12px;
      height: 12px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) !important;
      margin: 0;
    }
  }
}

.ant-checkbox-inner {
  && {
    border-width: thin !important;
  }
}

.ant-checkbox-input {
  && {
    border-width: thin !important;

    &:focus {
      box-shadow: none !important;
    }
  }
}

.ant-input-affix-wrapper {
  height: 36px;

  .anticon-close-circle {
    opacity: 0;
    transition: 0.15s ease;
  }

  &:hover {
    .anticon-close-circle {
      opacity: 1;
      cursor: pointer;
    }
  }

  & > input.ant-input {
    height: 100%;
  }
}

.ant-input {
  height: 36px;
  font-size: 14px;
  color: #595959;

  &-number-input {
    && {
      height: 36px;
      color: #595959;
      font-size: 14px;
    }
  }

  &:focus {
    box-shadow: none !important;
  }
}

// Remove default browser css
input.ant-input[type],
input.ant-calendar-picker-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type='email'].ant-input:read-only,
input[type='email'].ant-input:disabled,
input[type='text'].ant-input:read-only,
input[type='text'].ant-input:disabled {
  background-color: #faf9f9 !important;
}

.ant-btn {
  &-link {
    padding: 0;
    font-size: inherit;
  }

  &-text {
    && {
      background-color: transparent;
    }
  }

  &-primary {
    box-shadow: none;

    &:hover,
    &:focus {
      color: #fff;
      background-color: #eaca96;
      border-color: #eaca96;
    }
  }

  &-background-ghost {
    border-color: @BorderColor;
    color: @Black;
  }

  && {
    line-height: 1;
  }
}

.ant-modal {
  @media (max-width: 767px) {
    && {
      top: 0;
      margin: 0 auto;
    }
  }

  &-title {
    font-family:
      Josefin Sans,
      sans-serif;
    position: relative;
    font-size: 18px;
    color: #595959;
    top: 4px;
  }

  &-content {
    height: 100%;
  }

  &-header {
    border-bottom: 1px solid #707070;

    @media (max-width: @screen-lg-max) {
      padding: 16px;
    }
  }

  &-close {
    color: #4a4a4a;

    &-x {
      width: 48px;
      height: 48px;
      line-height: 50px;
    }
  }

  &.ant-modal-confirm {
    height: auto;
    top: 200px;

    @media only screen and (max-width: 1000px) {
      top: 80px;
    }
  }

  &-centered {
    .ant-modal {
      height: auto;

      &.ant-modal-confirm {
        top: 0;
      }
    }
  }

  &-body {
    padding: 26px 22px;

    .ant-form-item {
      margin-bottom: 2rem;

      .ant-form-item-margin-offset {
        margin: 0 !important;
      }

      .ant-form-item-label {
        line-height: 1rem;
      }
    }

    @media not all and (min-width: @screen-md) {
      padding: 20px;
    }

    @media (max-width: @screen-lg-max) {
      padding: 20px 16px;
    }
  }
}

.ant-confirm-info .ant-confirm-body > .anticon {
  color: #b7964e !important;
}

.ant-popover-inner-content {
  padding: 8px !important;
}

.ant-picker {
  width: 100%;
  height: 36px;
}

.rk-scrollbar-horizontal-table {
  > .ant-spin-nested-loading {
    > .ant-spin-container {
      > .ant-table {
        padding-bottom: 1rem;
        overflow-x: auto;
        color: @CustomizeAntText;

        &::-webkit-scrollbar {
          cursor: pointer;
          height: 0.2rem;
          background-color: @Grey2;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #b7964e;
        }

        &:hover {
          padding-bottom: 0.2rem;

          &::-webkit-scrollbar {
            height: 1rem;
          }
        }

        &.rk-inactive {
          padding-bottom: 0;

          &:hover {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

.ant-table-wrapper {
  width: 100%;
}

.ant-table-filter-column.ant-table-filter-column {
  justify-content: flex-start;

  .ant-table-filter-column-title {
    flex: none;
    display: inline;
    padding-right: 8px;
  }

  .ant-table-filter-trigger-container {
    position: relative;
  }
}

@media (max-width: 768px) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
  }

  .ant-table-tbody > tr > td,
  .ant-table-tbody > tr > th,
  .ant-table-thead > tr > td,
  .ant-table-thead > tr > th {
    white-space: pre;
  }

  .ant-table-tbody > tr > td > span,
  .ant-table-tbody > tr > th > span,
  .ant-table-thead > tr > td > span,
  .ant-table-thead > tr > th > span {
    display: block;
  }
}

.ant-checkbox {
  && {
    input,
    .ant-checkbox-inner {
      height: 1.6rem;
      width: 1.6rem;
      border-radius: 0;
    }

    .ant-checkbox-inner {
      &::after {
        border-width: thin;
        width: 4px;
        top: 5px;
        left: 3px;
        height: 10px;
      }
    }
  }

  &.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #fff;

      &::after {
        border-color: #b7964e;
      }
    }
  }
}

.ant-select-selection--multiple {
  .ant-select-selection__choice {
    margin-right: 1.2rem !important;
    border-radius: 1.2rem !important;
  }
}

@media (max-width: 575px) {
  .ant-form-item {
    .ant-form-item-required {
      &::before {
        position: absolute;
        margin: 0;
        top: 0;
        right: 0;
      }
    }
  }
}

.ant-checkbox-indeterminate.ant-checkbox-indeterminate {
  .ant-checkbox-inner::after {
    top: 50%;
    width: 8px;
  }
}

.ant-table-selection .ant-checkbox-indeterminate.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  width: 8px;
  height: 8px;
  left: 50%;
  top: 50%;
}

// readonly styled
.rsd-readonly {
  .ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    border-color: #b7964e !important;
    background-color: #faf9f9 !important;

    &::after {
      border-color: #b7964e;
    }
  }

  &.ant-select.ant-select-disabled {
    .ant-select-selector {
      background-color: #faf9f9 !important;
    }

    .ant-select-selection-item-content {
      color: #555;
      border-color: #ccc;
    }

    .ant-select-selection--multiple {
      .ant-select-selection__choice {
        color: #555;
        border-color: #ccc;
      }
    }
  }

  &.ant-checkbox-wrapper-disabled.ant-checkbox-wrapper-disabled {
    cursor: auto;
  }

  .ant-checkbox-disabled {
    cursor: auto;
    opacity: 0.5;

    & .ant-checkbox-input {
      cursor: auto;
    }

    & + span {
      cursor: auto;
    }
  }
}

.ant-breadcrumb > span:last-child a {
  white-space: nowrap;
}

.ant-pagination-next,
.ant-pagination-prev {
  .ant-pagination-item-link {
    position: relative;

    .anticon {
      top: calc(50% - 6px);
      left: calc(50% - 5px);
      position: absolute;

      &-left {
        left: calc(50% - 7px);
      }
    }
  }
}

.ant-tooltip {
  .ant-tooltip-inner {
    font-size: 14px;
    font-family: 'Lato', sans-serif;
  }
}

.user-role-popup-hidden {
  display: none;
}
