@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.slick-list {
  .slick-slide {
    img {
      height: 400px;
      overflow: hidden;
      padding-left: 2px;
      padding-right: 2px;
      width: auto;

      @media (max-width: 1024px) {
        height: 350px;
      }
    }
  }
}

.slick-prev {
  height: 30px;
  left: -30px;
  width: 30px;

  &::before {
    color: red;
    font-size: 30px;
  }
}

.slick-next {
  height: 30px;
  right: -30px;
  width: 30px;

  &::before {
    color: red;
    font-size: 30px;
  }
}
