.venues-map {
  &-wrapper {
    &:extend(.ph-20);

    @media not all and (min-width: @screen-sm) {
      flex-direction: column-reverse;
    }
  }
}

.recent {
  &-column {
    @media not all and (min-width: @screen-sm) {
      margin-top: 20px;
      &.empty {
        display: none;
      }
    }
  }

  &-title {
    background-color: @DeartyWhite;
    border-bottom: thin solid @Grey;
    text-align: center;
    padding: 1rem;
    font-size: 1.4rem;
    color: @TransparentBlack;
  }
}
