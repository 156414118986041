.modal {
  &-subtitle {
    &-row {
      margin-bottom: 1rem;
    }

    font-size: 2rem;
    color: @TransparentBlack;
    text-align: center;

    @media not all and (min-width: @screen-sm) {
      font-size: 1.8rem;
    }
  }

  &-button {
    &-row {
      position: relative;
      padding-top: 2rem;
      justify-content: center;
      display: flex;

      @media not all and (min-width: @screen-xs) {
        flex-direction: column;
        align-items: center;
      }

      .ant-btn {
        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          margin-left: 10px;
        }

        @media not all and (min-width: @screen-xs) {
          margin: 0 10px 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &-logo {
    &-wrapper {
      text-align: center;

      img {
        margin-bottom: 30px;
      }

      > h2,
      > p {
        &:last-child {
          margin-bottom: 30px;
        }
      }
    }

    &-description {
      font-size: @UsualBookingFZ;
    }
  }

  &_without_header {
    .ant-modal-body {
      @media (max-width: @screen-lg-max) {
        padding: 20px 16px;
        overflow-y: auto;
        max-height: 100vh;
      }
    }
  }

  &_centered {
    &.ant-modal {
      height: auto;
      top: 100px;
    }
  }
}

.musician-selection__modal {
  .modal-button-row {
    margin-top: -1.6rem;
  }
}

.ant-modal-root {
  .ant-modal-confirm-btns {
    .ant-btn-primary {
      background: @Primary;

      &:focus {
        background: @Primary;
      }
    }
  }
}

.package-modal {
  .ant-btn.ant-btn-background-ghost {
    color: #2f2f2f;
  }

  &__time-picker {
    &-popup {
      .ant-picker-time-panel-column {
        &:last-child {
          &::after {
            height: auto;
          }
        }
      }
    }
  }

  &-duration-field {
    display: flex;
    align-items: center;
    border: 1px solid @BorderColor;

    .anticon {
      line-height: 0;
    }
  }

  &-duration-input {
    border: none !important;
    pointer-events: none;

    &.ant-picker-focused {
      border: none;
      box-shadow: none;
    }

    input {
      text-align: center;
    }
  }

  &-duration-button-increase,
  &-duration-button-decrease {
    border-color: @QuoteBuilderBorder;
    width: 25%;
    text-align: center;
    color: #a9a6a6;
    background-color: transparent;
    border: none;
    transition: all 0.3s ease;
    outline: none;

    &[disabled] {
      pointer-events: none;
    }

    &:hover,
    &:active,
    &:focus {
      color: #a9a6a6;
      background-color: transparent;
    }
  }

  .ant-modal-content {
    height: auto;

    .ant-modal-close-x {
      width: 45px;
      height: 45px;
      line-height: 45px;
    }

    .font-awesome-icon {
      color: #595959;
    }

    .ant-modal-header {
      padding: 11px 22px;
      border-bottom: 1px solid #707070;

      .ant-modal-title {
        font-size: 18px;
        color: #595959;
      }
    }

    .ant-modal-body {
      padding: 26px 22px;

      .ant-form {
        .ant-form-item {
          input {
            font-size: 14px;
            color: #595959;

            &::placeholder {
              color: #9b9b9b;
            }
          }

          .ant-form-item-label {
            label {
              color: #595959;
              font-size: 16px;
            }
          }

          .package-modal-duration-field {
            &.disabled {
              border: 1px solid #ebebeb;
            }

            button {
              background-color: #fff;
              color: #a9a6a6;

              &:hover {
                color: #a9a6a6;
              }

              &:disabled {
                background-color: #fff;
              }
            }

            .package-modal-duration-input {
              padding: 5px 11px;
              height: 34px;

              &.ant-picker-disabled {
                background-color: #fff;

                input {
                  color: rgba(0, 0, 0, 0.25);

                  &::placeholder {
                    color: #e6e6e6;
                  }
                }
              }
            }
          }

          .ant-picker-disabled {
            background-color: #fff;
            border: 1px solid #ebebeb;

            input {
              color: rgba(0, 0, 0, 0.25);

              &::placeholder {
                color: #e6e6e6;
              }
            }
          }

          .ant-form-item-explain {
            .ant-form-item-explain-error {
              font-size: 14px;
            }
          }

          .ant-picker {
            height: 36px;
          }

          &:not(.ant-select-customize-input) {
            .ant-select-selector {
              height: 36px;

              .ant-select-selection-item {
                font-size: 14px;
                line-height: 36px;
                color: #595959;
              }

              .ant-select-selection-search-input {
                height: 36px;
              }

              .ant-select-selection-placeholder {
                font-style: italic;
                line-height: 36px;
              }
            }
          }

          .ant-select-item {
            font-size: 14px;
          }

          .ant-select-arrow {
            color: #595959;
          }
        }
      }
    }
  }

  .ant-input-affix-wrapper {
    &-disabled {
      background: #fff 0% 0% no-repeat padding-box;
      border: 1px solid #ebebeb;

      input[type='text'].ant-input.ant-input-disabled {
        &:disabled::placeholder {
          color: #e6e6e6;
        }
      }
    }

    & > input.ant-input {
      height: auto;
    }

    .ant-input-suffix {
      font-size: 12px;
      color: #bfbfbf;
    }
  }

  .gig-detail {
    &__title {
      color: #9c9c9c;
      text-align: center;
      font-weight: 600;
      font-size: 17px;
      letter-spacing: 1.1px;
    }

    &__fieldset {
      legend {
        color: #d2d2d2;
        font-size: 16px;
        margin-bottom: 25px;
      }

      .ant-select {
        color: #d2d2d2;
      }
    }

    &__not-sure-when {
      .ant-checkbox-wrapper {
        color: #595959;
        font-size: 14px;
      }
    }

    &__location {
      margin-bottom: 0;

      &-not-sure-where {
        margin-bottom: 10px;

        .ant-radio-group {
          width: 100%;
          display: flex;

          .ant-radio-wrapper {
            width: 50%;
            margin-left: 10px;
            color: #000000a6;
            font-size: 14px;

            .ant-radio-inner {
              border-color: #707070;
            }

            .ant-radio-disabled {
              .ant-radio-inner {
                border-color: #d9d9d9;
              }
            }
          }
        }
      }

      &-address {
        margin: 4px 0 16px;

        input[type='text'].ant-input:disabled {
          background-color: transparent !important;
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}
