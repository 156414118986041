.ersatz-table-row {
  padding: 1rem 1rem;
  font-size: 1.4rem;
  height: 4.4rem;
  border-bottom: thin solid @Grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: @TransparentBlack;
  position: relative;
  width: 100%;
  @media (max-width: @screen-lg) {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
    width: calc(100% - 32px);
    padding: 0;
  }
}
