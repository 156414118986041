/* containers/Artists/SingerContainer.jsx */
.singers {
  height: 100%;
  display: flex;
  flex-direction: column;

  &-board {
    flex: 1;
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0 1px 15px #0003;
    padding: 16px;
    overflow: hidden;

    & > .ant-row {
      height: 100%;

      & > .ant-col {
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .singers {
    &-board {
      box-shadow: none;
    }
  }
}

@media screen and (max-width: 1199px) {
  .singers {
    height: calc(100vh - 6rem - 4px);
  }
}

@media screen and (max-width: 575px) {
  .singers {
    height: 100%;

    &-board {
      height: auto;

      & > .ant-row {
        height: auto;
      }
    }
  }
}

/* components/Artists/Singers/PublishedSingerProfiles.jsx */
.published-singer-profiles {
  height: 100%;
  display: flex;
  flex-direction: column;

  ul {
    list-style-type: none;
    padding: 0 10px 0 0;
    height: calc(100% - 100px);
    margin: 10px 10px 0 0;
    overflow: auto;

    .ant-checkbox-wrapper {
      line-height: 1;
    }

    &::-webkit-scrollbar-thumb {
      background: #b7964e;
    }

    & > li {
      display: flex;
      border-bottom: thin solid #e6e6e6;
      padding: 10px 10px;

      & > p {
        margin-left: 14px;
        color: #2f2f2f;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .published-singer-profiles {
    margin-bottom: 20px;

    ul {
      height: 50vh;
      margin: 0;
      padding: 0;
    }
  }
}

/* components/Artists/Singers/DndDisplayedSinger */
.singers-displayed {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__dnd {
    margin: 10px 10px 0 0;
    padding: 0 10px 0 0;
    height: calc(100% - 100px);
    overflow: auto;

    &::-webkit-scrollbar-thumb {
      background: #b7964e;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e6e6e6;
      padding: 10px 0 10px;

      &--name {
        display: flex;
        align-items: center;
        overflow: hidden;

        &-alias {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #2f2f2f;
          font-size: 14px;
        }

        svg {
          margin-right: 10px;
        }
      }

      &--close-btn {
        border: none;
        outline: none;
        -webkit-tap-highlight-color: transparent;
        background-color: transparent;
        cursor: pointer;
        padding: 0 20px;

        svg {
          color: #4a4a4a;
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .singers-displayed {
    &__dnd {
      height: 50vh;
      margin: 0;
      padding: 0;
    }
  }
}
