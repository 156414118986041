.open-invites {
  &-buttons {
    &-wrapper {
      padding-left: 20px;
      white-space: nowrap;

      .action-icon-button {
        background-color: transparent;
      }
    }
  }
}

.update-active-statuses-modal {
  &__button-primary {
    margin: auto;
  }
}

.skill-selector {
  .ant-select-selector {
    height: auto;
    min-height: 36px;
  }
}

.user-avatar-image {
  border-radius: 50%;
  height: 120px;
  vertical-align: middle;
  line-height: 120px;
  width: 120px;
}

.user-avatar-button {
  margin-left: 16px;
  vertical-align: middle;
}

.user-status {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 24px;
  line-height: 1;
}
