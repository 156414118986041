.form {
  &-wrapper {
    padding: 1rem;

    @media (min-width: @screen-md) {
      padding: 1.6rem;
    }

    @media (min-width: @screen-xl) {
      padding: 2rem;
    }
  }
}

.asterisk {
  color: #ff4d4f;
}

.ant-form-item.ant-form-item-has-error {
  .intl-tel-input input {
    border: 1px solid #ff4d4f;
  }
}
