.page-section {
  padding: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

  @media not all and (min-width: @screen-md) {
    box-shadow: none;
  }

  &:not(:last-child) {
    margin-bottom: 0;
  }
}

.payment-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;

  .page-section {
    flex: 1;
  }
}

.page-section-title {
  && {
    font-size: 1.8rem;
    font-family:
      Josefin Sans,
      sans-serif;
    color: rgba(0, 0, 0, 0.65);
    user-select: none;
    &:extend(.tac);

    white-space: nowrap;
  }
}

.page-header {
  &:extend(.page-section);

  margin-bottom: 20px !important;

  & > .ant-row {
    width: 100%;
    flex-wrap: nowrap;
  }

  @media not all and (min-width: @screen-xl) {
    margin-bottom: 0 !important;
    box-shadow: none;
    border-bottom: thin solid @Grey;
  }

  @media (min-width: @screen-md) {
    padding-right: 1.6rem;
  }

  @media (min-width: @screen-xl) {
    padding-right: 2.6rem;
  }

  &-scroll-row {
    && {
      overflow: hidden;
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.page-header-extra-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .ant-dropdown-trigger {
    height: 36px;
  }
}
