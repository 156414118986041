.main-layout {
  height: 100vh;

  .ant-layout-sider-children {
    .flex-display();
    .flex-direction(column);
  }

  &-header {
    padding: 0 @layout-spacing !important;
    box-shadow: @box-shadow-1;
  }

  &-content {
    min-height: auto !important;
    padding: @layout-spacing;
  }
}

.sidebar-menu-item-icon-wrapper {
  .flex-display(flex-inline);
  .justify-content(center);

  width: 20px;
  margin-right: @spacing-1;
}

.layout-background {
  background-color: #e6e6e6;
  min-height: 100vh;
  height: auto !important;
}
