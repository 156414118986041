.collapse {
  &__wrapper {
    width: 100%;
  }
}

.collapse-panel {
  &__header-wrapper {
    padding: 0.8rem 0;
    font-size: @UsualBookingFZ;
    color: @TransparentBlack;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background: transparent;
    border: none;
    width: 100%;
    align-items: center;
    outline: none;
  }

  &__expanding {
    &-icon {
      && {
        transition: transform 0.3s ease-in-out;
        color: @TransparentBlack;
        font-size: @UsualBookingFZ;

        &.opened {
          transform: rotate(180deg);
        }
      }

      &-wrapper {
        padding: 0.4rem 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-wrapper {
      width: calc(100% - 30px);
      text-align: left;
    }

    &-area {
      transition:
        max-height,
        padding-bottom 0.5s,
        0.2s ease-in-out;
      transform-origin: top;
      padding-bottom: 0;

      &.opened {
        padding-bottom: 20px;
      }
    }
  }
}
