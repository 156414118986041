.button-icon-edit {
  color: @TransparentBlack;
}

.button-wide-add {
  border: thin dashed @Grey;
  padding: 1.1rem 0;
  text-align: center;
  cursor: pointer;
  height: 3.6rem;
  margin-top: 2rem;
  line-height: 1;
  font-size: 1.4rem;
  font-weight: 700;
  color: @TransparentBlack;
  white-space: nowrap;
  display: block;
  width: 100%;
  background: transparent;
  outline: none;
  text-transform: capitalize;

  &:hover,
  &:focus,
  &:active {
    border-style: solid;
    border-color: @Primary;
    color: @Primary;
  }

  .svg-inline--fa {
    vertical-align: -2px;
  }
}

.button-wide-add.without-border-button {
  border: none;
  margin-top: 0;
  color: @primary-color;
}

.button-primary {
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  padding: 0.5rem 0;
  text-shadow: none;
  width: 200px;
  font-size: @UsualBookingFZ;
  height: 3.6rem;
  border-width: thin;

  &__wrapper {
    .button-primary {
      .margin-horizontal(auto);
    }
  }
}

.change-password-button {
  &:extend(.mb-40);
}

.action-icon-button {
  font-size: @UsualBookingFZ;
  border: none;
  box-shadow: none;
  width: 2rem;
  height: 3.6rem;
  padding: 0;
  color: @Primary;

  &:hover {
    color: @PrimaryHover;
  }
}

.buttons-instead-dropdown {
  display: flex;
  align-items: center;

  &.ant-btn-background-ghost {
    color: #000;
    border-color: #d9d9d9;
  }

  & > * {
    .space-for-items-right(20px);
  }
}
