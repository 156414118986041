.offers-wrap {
  flex-direction: column;
}

.offers-modal-buttons {
  flex-direction: column;
  align-items: center;
  background-color: @DeartyWhite;
  padding: 20px 0;
  border-top: thin solid @BorderColor2 !important;
}

.offer-carousel-empty-container {
  min-height: 300px;
}

.next-gig-empty-container {
  min-height: 60vh;
  margin: 0 auto;
}

@media only screen and (max-width: 1200px) {
  .offer-carousel-empty-container {
    min-height: 180px;
  }

  .next-gig-empty-container {
    min-height: unset;
  }
}

.musician-guide-modal {
  .detail-modal-banner {
    width: 100%;
  }

  div {
    background-color: white;
  }
}

.musician-guide-modal .ant-modal-close-icon {
  padding: 3px;
  font-size: 1.5rem;
  background-color: lightgray;
}

.musician-guide-carousel-button {
  width: 120px;
  cursor: pointer;
}

.musician-guide-carousel-button:focus {
  background-color: @Primary;
}

.musician-guide-carousel {
  padding: 2.5rem;

  .ant-typography {
    color: #808080;
  }

  .slick-dots li.slick-active {
    width: 38px !important;
  }

  .slick-dots li {
    width: 28px !important;
  }

  .slick-dots {
    margin-bottom: 1rem;
  }

  .slick-dots button::before {
    width: 12px;
    height: 12px;
  }

  .slick-dots li.slick-active button::before {
    color: @Primary;
    background-color: @Primary;
  }

  .slick-dots li button::before {
    color: #696969;
    background-color: #696969;
  }

  .ant-carousel .slick-dots li button {
    border-radius: 0;
  }

  .ant-carousel .slick-dots {
    justify-content: left;
    margin-left: 0;
    bottom: -25px;
  }

  .slick-track {
    height: 18rem;
  }

  @media only screen and (max-width: 480px) {
    .slick-track {
      height: 25rem;
    }
  }
}
