.musician {
  &-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    gap: 8px;
  }

  &-airwallex {
    &-button {
      width: auto;
      padding: 0 20px;
    }
  }

  &-outstanding-payment {
    &-button {
      && {
        width: auto;
        padding: 0 20px;
      }
    }
  }
}
