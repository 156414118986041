@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/*
  Custom variables definitions
*/
/* Spacing */
/* Colors */
/* Shadows */
/*
  Mixin definitions
*/
/* Sizing */
/* Spacing */
/* Borders */
/* Basic and common styles applied to a variety of components */
/* Sizing */
.full-width {
  width: 100%;
}
.third-width {
  width: 33.3%;
}
/* Background */
.success-bg {
  background-color: #52c41a;
}
.primary-bg {
  background-color: #b7964e;
}
/* Borders */
.rounded-borders {
  border-radius: 50%;
}
/* Style definitions for common components like buttons, inputs, tables */
/* Ant classes */
.ant-dropdown-trigger {
  height: auto;
}
/* Custom classes */
.icon-success-lg {
  color: #52c41a;
  font-size: 60px;
}
/* Icon types */
.icon-primary {
  color: #b7964e;
}
.icon-success {
  color: #52c41a;
}
.icon-danger {
  color: #ff4d4f;
}
/* Icon sizes */
.icon-large {
  font-size: 60px;
}
.main-layout {
  height: 100vh;
}
.main-layout .ant-layout-sider-children {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.main-layout-header {
  padding: 0 30px !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
.main-layout-content {
  min-height: auto !important;
  padding: 30px;
}
.sidebar-menu-item-icon-wrapper {
  display: -webkit-flex-inline;
  display: -ms-flex-inlinebox;
  display: -ms-flex-inline;
  display: flex-inline;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  width: 20px;
  margin-right: 10px;
}
.layout-background {
  background-color: #e6e6e6;
  min-height: 100vh;
  height: auto !important;
}
.ant-select,
.ant-input-group {
  text-align: left;
}
.modal-form-wrapper {
  margin: 40px;
  padding: 40px;
  background-color: #fff;
  width: 360px;
  text-align: center;
}
@media screen and (max-width: 480px) {
  .modal-form-wrapper {
    margin: 10%;
    padding: 10%;
    width: 80%;
  }
}
.modal-form-wrapper-large {
  margin: 40px;
  padding: 40px;
  background-color: #fff;
  width: 540px;
  text-align: center;
}
@media screen and (max-width: 480px) {
  .modal-form-wrapper-large {
    margin: 10%;
    padding: 10%;
    width: 80%;
  }
}
.modal-form-button {
  width: 100%;
}
.third-width-input {
  width: 33.3% !important;
}
.ant-form-item-label > label {
  padding-right: 4px;
  margin-right: 16px;
}
.ant-form-item-required::before {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  transform: translateX(150%);
}
.ant-form-item-required::after,
.ant-form-item-label > label::after {
  display: none;
}
.org-select {
  line-height: normal;
  display: inline-block;
}
.org-select.has-border {
  margin-right: 10px;
  border-right: thin solid #e8e8e8;
}
.org-select .select-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
  display: inline-block;
}
.org-select .select-wrapper .select2-container {
  min-width: 300px;
  width: 100% !important;
}
.org-select .select-wrapper .select2-container--default .select2-selection--single {
  border: none;
  outline: none;
}
.org-select .select-wrapper .select2-container--default .select2-selection--single:hover,
.org-select .select-wrapper .select2-container--default .select2-selection--single:focus,
.org-select .select-wrapper .select2-container--default .select2-selection--single:active {
  outline: none;
}
.org-select .select-wrapper .select2-container--default .select2-selection--single .select2-selection__rendered {
  font-size: 24px;
}
.org-select .select-wrapper .select2-container--default .select2-selection--single .select2-selection__rendered:hover,
.org-select .select-wrapper .select2-container--default .select2-selection--single .select2-selection__rendered:focus,
.org-select .select-wrapper .select2-container--default .select2-selection--single .select2-selection__rendered:active {
  outline: none;
}
.org-select .select-wrapper .select2-container--focus,
.org-select .select-wrapper .select2-container--focus:hover,
.org-select .select-wrapper .select2-container--focus:focus,
.org-select .select-wrapper .select2-container--focus:active {
  outline: none;
}
.org-select .select-wrapper .select2-container--default .select2-selection--multiple {
  border: thin solid #d9d9d9;
  border-radius: 0;
}
.org-select .select-wrapper .selection {
  line-height: 1.5;
}
.org-select .select-wrapper .select2-container--default .select2-selection--multiple .select2-selection__choice {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 0;
}
.org-select .select-wrapper .select2-search__field::placeholder {
  color: #bfbfbf;
}
.org-select .select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #e6f7ff;
  color: rgba(0, 0, 0, 0.65);
  font-weight: bold;
}
.org-select .select2-container--default .select2-results__option[aria-selected=true]:hover,
.org-select .select2-container--default .select2-results__option[aria-selected=true]:focus,
.org-select .select2-container--default .select2-results__option[aria-selected=true]:active {
  background-color: #e6f7ff;
  color: rgba(0, 0, 0, 0.65);
}
.org-select .select2-results {
  position: relative;
}
#org-select-dropdown .select2-dropdown {
  border-radius: 0 !important;
  border: thin solid #d9d9d9;
  width: 300px !important;
}
#org-select-dropdown .select2-container--default .select2-search--dropdown .select2-search__field:hover,
#org-select-dropdown .select2-container--default .select2-search--dropdown .select2-search__field:focus,
#org-select-dropdown .select2-container--default .select2-search--dropdown .select2-search__field:active {
  outline: none;
  border: thin solid #47a9ff;
}
#org-select-dropdown .select2-results__options .select2-results__option:not(.select2-results__message):not(.loading-results):first-of-type {
  color: #1f8efe;
  font-weight: bold;
  border-bottom: thin solid #e8e8e8;
}
#org-select-dropdown .select2-results__options .select2-results__option:not(.select2-results__message):not(.loading-results):first-of-type.select2-results__option--highlighted[aria-selected] {
  background-color: #e6f7ff;
  color: rgba(0, 0, 0, 0.65);
}
.page-section,
.page-header {
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
.page-section:not(:last-child) {
  margin-bottom: 30px;
}
.page-header-extra-container > *:not(:first-child) {
  margin-left: 20px;
}
.message-page-wrapper {
  min-height: 100vh;
  min-width: 100vw;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.message-page-wrapper .message-page-inner-container {
  width: 600px;
  text-align: center;
  margin: 20px;
  padding: 20px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.message-page-wrapper .error-page-inner-container {
  margin: 20px;
  padding: 20px;
}
@media screen and (max-width: 576px) {
  .message-page-wrapper .error-page-inner-container {
    text-align: center;
  }
}
.message-page-wrapper .error-page-inner-container .error-page-title {
  font-size: 72px;
}
@media screen and (max-width: 576px) {
  .message-page-wrapper .error-page-inner-container .error-page-title {
    font-size: 52px;
  }
}
.message-page-wrapper .error-page-inner-container .error-page-description {
  font-size: 20px;
}
@media screen and (max-width: 576px) {
  .message-page-wrapper .error-page-inner-container .error-page-description {
    font-size: 18px;
  }
}
.dropdown-select-icon {
  color: #b7964e;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.dropdown-select-icon-active {
  transform: rotateX(180deg);
}
.dropdown-select-wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
}
.dropdown-select-wrapper:hover .dropdown-select-icon {
  color: #9d7f40;
}
.dropdown-select-inner {
  margin: 0 10px;
  padding: 0 10px;
  cursor: pointer;
}
.dropdown-select-title {
  margin: 0 !important;
  padding: 0 !important;
}
.ant-modal-body {
  margin: 0;
  padding: 40px;
}
.sidebar-splitted-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
}
.selectable-rows .ant-table-tbody tr {
  cursor: pointer;
}
.m-10 {
  margin: 10px;
}
.mb-0 {
  margin-bottom: 0 !important;
}
/* Style definitions to specific components */
.profile-avatar-upload {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  position: relative;
  transition: all 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  cursor: pointer;
}
.profile-avatar-upload:hover {
  filter: brightness(90%);
}
.profile-avatar-upload-text {
  border-radius: 50%;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 24px;
  color: #fff;
}
.profile-avatar-upload:hover .profile-avatar-upload-text {
  display: inline;
}
.profile-avatar-upload .profile-avatar-img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
}
.profile-user-status-text {
  margin: 0;
  padding: 0 10px;
  color: #fff !important;
  border-radius: 20px;
}
.profile-mobile-input-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.users-status-wrapper {
  width: 160px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.users-status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}
.users-status-dot-enabled {
  background-color: #52c41a;
}
.users-status-dot-onboarding {
  background-color: #faad14;
}
.users-status-dot-archived {
  background-color: #d9d9d9;
}
.org-select-wrapper {
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
.org-select-input-wrapper {
  margin: 0;
  padding: 4px;
}
.org-select-divider {
  margin: 0 !important;
  padding: 0 !important;
}
.org-select-list-wrapper {
  width: 100%;
  min-width: 360px;
  max-height: 360px;
  overflow: auto;
}
.org-select-list-item {
  margin: 0 !important;
  padding: 10px !important;
  cursor: pointer;
}
.org-select-list-item-selected {
  background-color: #ebe1cd;
}
.org-select-list-item:hover {
  background-color: #f5f5f5;
}
.google-map-container,
.google-map {
  width: 100%;
  height: 100%;
}
.payment-checkout-title-wrapper {
  padding: 10px;
}
.payment-checkout-title-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 10px;
}
.payment-checkout-card-icon {
  width: 40px;
  display: inline-block;
}
.ant-menu.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #434343;
}
.ant-menu.ant-menu.ant-menu-dark .ant-menu-sub.ant-menu-inline {
  box-shadow: none;
}
.ant-form-item .ant-form-item-label {
  overflow: visible;
}
.ant-form-item .ant-form-item-label label {
  color: #595959;
  font-size: 16px;
}
.ant-form-item .ant-form-item-label label .ant-typography {
  color: #595959;
}
.ant-form-item .ant-form-item-label label .ant-typography.ant-typography-danger {
  color: #ff4d4f;
}
.ant-form-item .ant-form-item-explain-error {
  font-size: 14px;
}
.ant-form-item .ant-picker-input > input {
  font-size: 14px;
  color: #595959;
}
.ant-form-item .ant-form-item-required::before {
  position: absolute;
  margin: 0;
  top: -2px;
  right: 2px;
  line-height: unset !important;
}
.ant-col-xs-24 {
  min-height: 0;
}
.ant-card-head-title {
  font-size: 2rem;
}
.ant-select-dropdown-menu-item-group-title {
  font-size: 1em;
  font-weight: bold;
}
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  top: 50%;
  transform: translateY(-50%);
}
small {
  font-size: 1.2rem;
}
.ant-dropdown {
  z-index: 500 !important;
}
.ant-dropdown-placement-bottomRight {
  z-index: 1050 !important;
  position: fixed !important;
}
.ant-dropdown-menu-item-group-list {
  list-style: none;
  padding-left: 0;
}
.ant-radio .ant-radio-inner {
  box-sizing: content-box;
  border-width: thin;
}
.ant-radio .ant-radio-inner::after {
  width: 12px;
  height: 12px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  margin: 0;
}
.ant-checkbox-inner.ant-checkbox-inner {
  border-width: thin !important;
}
.ant-checkbox-input.ant-checkbox-input {
  border-width: thin !important;
}
.ant-checkbox-input.ant-checkbox-input:focus {
  box-shadow: none !important;
}
.ant-input-affix-wrapper {
  height: 36px;
}
.ant-input-affix-wrapper .anticon-close-circle {
  opacity: 0;
  transition: 0.15s ease;
}
.ant-input-affix-wrapper:hover .anticon-close-circle {
  opacity: 1;
  cursor: pointer;
}
.ant-input-affix-wrapper > input.ant-input {
  height: 100%;
}
.ant-input {
  height: 36px;
  font-size: 14px;
  color: #595959;
}
.ant-input-number-input.ant-input-number-input {
  height: 36px;
  color: #595959;
  font-size: 14px;
}
.ant-input:focus {
  box-shadow: none !important;
}
input.ant-input[type],
input.ant-calendar-picker-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type='email'].ant-input:read-only,
input[type='email'].ant-input:disabled,
input[type='text'].ant-input:read-only,
input[type='text'].ant-input:disabled {
  background-color: #faf9f9 !important;
}
.ant-btn-link {
  padding: 0;
  font-size: inherit;
}
.ant-btn-text.ant-btn-text {
  background-color: transparent;
}
.ant-btn-primary {
  box-shadow: none;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #eaca96;
  border-color: #eaca96;
}
.ant-btn-background-ghost {
  border-color: #d9d9d9;
  color: #000;
}
.ant-btn.ant-btn {
  line-height: 1;
}
@media (max-width: 767px) {
  .ant-modal.ant-modal {
    top: 0;
    margin: 0 auto;
  }
}
.ant-modal-title {
  font-family: Josefin Sans, sans-serif;
  position: relative;
  font-size: 18px;
  color: #595959;
  top: 4px;
}
.ant-modal-content {
  height: 100%;
}
.ant-modal-header {
  border-bottom: 1px solid #707070;
}
@media (max-width: 1199px) {
  .ant-modal-header {
    padding: 16px;
  }
}
.ant-modal-close {
  color: #4a4a4a;
}
.ant-modal-close-x {
  width: 48px;
  height: 48px;
  line-height: 50px;
}
.ant-modal.ant-modal-confirm {
  height: auto;
  top: 200px;
}
@media only screen and (max-width: 1000px) {
  .ant-modal.ant-modal-confirm {
    top: 80px;
  }
}
.ant-modal-centered .ant-modal {
  height: auto;
}
.ant-modal-centered .ant-modal.ant-modal-confirm {
  top: 0;
}
.ant-modal-body {
  padding: 26px 22px;
}
.ant-modal-body .ant-form-item {
  margin-bottom: 2rem;
}
.ant-modal-body .ant-form-item .ant-form-item-margin-offset {
  margin: 0 !important;
}
.ant-modal-body .ant-form-item .ant-form-item-label {
  line-height: 1rem;
}
@media not all and (min-width: 768px) {
  .ant-modal-body {
    padding: 20px;
  }
}
@media (max-width: 1199px) {
  .ant-modal-body {
    padding: 20px 16px;
  }
}
.ant-confirm-info .ant-confirm-body > .anticon {
  color: #b7964e !important;
}
.ant-popover-inner-content {
  padding: 8px !important;
}
.ant-picker {
  width: 100%;
  height: 36px;
}
.rk-scrollbar-horizontal-table > .ant-spin-nested-loading > .ant-spin-container > .ant-table {
  padding-bottom: 1rem;
  overflow-x: auto;
  color: #2f2f2f;
}
.rk-scrollbar-horizontal-table > .ant-spin-nested-loading > .ant-spin-container > .ant-table::-webkit-scrollbar {
  cursor: pointer;
  height: 0.2rem;
  background-color: #e8e8e8;
}
.rk-scrollbar-horizontal-table > .ant-spin-nested-loading > .ant-spin-container > .ant-table::-webkit-scrollbar-thumb {
  background-color: #b7964e;
}
.rk-scrollbar-horizontal-table > .ant-spin-nested-loading > .ant-spin-container > .ant-table:hover {
  padding-bottom: 0.2rem;
}
.rk-scrollbar-horizontal-table > .ant-spin-nested-loading > .ant-spin-container > .ant-table:hover::-webkit-scrollbar {
  height: 1rem;
}
.rk-scrollbar-horizontal-table > .ant-spin-nested-loading > .ant-spin-container > .ant-table.rk-inactive {
  padding-bottom: 0;
}
.rk-scrollbar-horizontal-table > .ant-spin-nested-loading > .ant-spin-container > .ant-table.rk-inactive:hover {
  padding-bottom: 0;
}
.ant-table-wrapper {
  width: 100%;
}
.ant-table-filter-column.ant-table-filter-column {
  justify-content: flex-start;
}
.ant-table-filter-column.ant-table-filter-column .ant-table-filter-column-title {
  flex: none;
  display: inline;
  padding-right: 8px;
}
.ant-table-filter-column.ant-table-filter-column .ant-table-filter-trigger-container {
  position: relative;
}
@media (max-width: 768px) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
  }
  .ant-table-tbody > tr > td,
  .ant-table-tbody > tr > th,
  .ant-table-thead > tr > td,
  .ant-table-thead > tr > th {
    white-space: pre;
  }
  .ant-table-tbody > tr > td > span,
  .ant-table-tbody > tr > th > span,
  .ant-table-thead > tr > td > span,
  .ant-table-thead > tr > th > span {
    display: block;
  }
}
.ant-checkbox.ant-checkbox input,
.ant-checkbox.ant-checkbox .ant-checkbox-inner {
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 0;
}
.ant-checkbox.ant-checkbox .ant-checkbox-inner::after {
  border-width: thin;
  width: 4px;
  top: 5px;
  left: 3px;
  height: 10px;
}
.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fff;
}
.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #b7964e;
}
.ant-select-selection--multiple .ant-select-selection__choice {
  margin-right: 1.2rem !important;
  border-radius: 1.2rem !important;
}
@media (max-width: 575px) {
  .ant-form-item .ant-form-item-required::before {
    position: absolute;
    margin: 0;
    top: 0;
    right: 0;
  }
}
.ant-checkbox-indeterminate.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  width: 8px;
}
.ant-table-selection .ant-checkbox-indeterminate.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  width: 8px;
  height: 8px;
  left: 50%;
  top: 50%;
}
.rsd-readonly .ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
  border-color: #b7964e !important;
  background-color: #faf9f9 !important;
}
.rsd-readonly .ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #b7964e;
}
.rsd-readonly.ant-select.ant-select-disabled .ant-select-selector {
  background-color: #faf9f9 !important;
}
.rsd-readonly.ant-select.ant-select-disabled .ant-select-selection-item-content {
  color: #555;
  border-color: #ccc;
}
.rsd-readonly.ant-select.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  color: #555;
  border-color: #ccc;
}
.rsd-readonly.ant-checkbox-wrapper-disabled.ant-checkbox-wrapper-disabled {
  cursor: auto;
}
.rsd-readonly .ant-checkbox-disabled {
  cursor: auto;
  opacity: 0.5;
}
.rsd-readonly .ant-checkbox-disabled .ant-checkbox-input {
  cursor: auto;
}
.rsd-readonly .ant-checkbox-disabled + span {
  cursor: auto;
}
.ant-breadcrumb > span:last-child a {
  white-space: nowrap;
}
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  position: relative;
}
.ant-pagination-next .ant-pagination-item-link .anticon,
.ant-pagination-prev .ant-pagination-item-link .anticon {
  top: calc(50% - 6px);
  left: calc(50% - 5px);
  position: absolute;
}
.ant-pagination-next .ant-pagination-item-link .anticon-left,
.ant-pagination-prev .ant-pagination-item-link .anticon-left {
  left: calc(50% - 7px);
}
.ant-tooltip .ant-tooltip-inner {
  font-size: 14px;
  font-family: 'Lato', sans-serif;
}
.user-role-popup-hidden {
  display: none;
}
.subsection-title-text {
  color: #b7964e;
  font-family: Josefin Sans, sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  white-space: nowrap;
}
.subsection-title-extracontent-narrow {
  color: #b7964e;
  font-family: Josefin Sans, sans-serif;
  font-size: 1.8rem;
}
.subsection-title-extracontent-tab-inactive {
  color: #9b9b9b;
  font-family: Josefin Sans, sans-serif;
  font-size: 1.5rem;
  padding-bottom: 2px;
  cursor: pointer;
}
.subsection-margin-right {
  margin-right: 2rem;
}
.subsection-title-extracontent-tab {
  color: #b7964e;
  font-family: Josefin Sans, sans-serif;
  font-size: 1.5rem;
  font-weight: 550;
  border-bottom: thin solid rgba(0, 0, 0, 0.45);
  cursor: pointer;
}
.text-button {
  line-height: 1.5715 !important;
  padding: 0;
  border: none;
  height: auto;
}
.text-button.ant-btn-text:hover,
.text-button.ant-btn-text:focus {
  color: #eaca96;
  background: transparent;
}
.slick-list .slick-slide img {
  height: 400px;
  overflow: hidden;
  padding-left: 2px;
  padding-right: 2px;
  width: auto;
}
@media (max-width: 1024px) {
  .slick-list .slick-slide img {
    height: 350px;
  }
}
.slick-prev {
  height: 30px;
  left: -30px;
  width: 30px;
}
.slick-prev::before {
  color: red;
  font-size: 30px;
}
.slick-next {
  height: 30px;
  right: -30px;
  width: 30px;
}
.slick-next::before {
  color: red;
  font-size: 30px;
}
.react-tel-input input[type='tel'],
.react-tel-input input[type='text'] {
  border-radius: 0 !important;
}
.react-tel-input .flag-dropdown {
  border-radius: 0 !important;
}
.react-tel-input .selected-flag {
  border-radius: 0 !important;
}
.redsoda-textarea {
  border: thin solid #e6e6e6 !important;
  padding: 10px;
  margin: 2rem auto;
  max-width: calc(100% - 4rem) !important;
  display: block;
}
.radio-inputs__wrapper {
  padding-left: 5rem;
  margin-top: 1rem;
}
.input-search__variant-list {
  position: absolute;
  list-style: none;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1;
  padding: 0;
  box-shadow: 0 0.1rem 0.3rem;
  cursor: pointer;
}
.input-search__variant-list-item {
  list-style: none;
  padding: 0.8rem 1rem;
  border-bottom: thin solid #e6e6e6;
}
.input-search__wrapper {
  position: relative;
  line-height: 1.2;
}
/* REDS-4835 */
.ant-input-number-handler-wrap .ant-input-number-handler.ant-input-number-handler-wrap .ant-input-number-handler:hover {
  height: 50% !important;
}
.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 50% !important;
}
.button-icon-edit {
  color: #595959;
}
.button-wide-add,
.add-price-button {
  border: thin dashed #e6e6e6;
  padding: 1.1rem 0;
  text-align: center;
  cursor: pointer;
  height: 3.6rem;
  margin-top: 2rem;
  line-height: 1;
  font-size: 1.4rem;
  font-weight: 700;
  color: #595959;
  white-space: nowrap;
  display: block;
  width: 100%;
  background: transparent;
  outline: none;
  text-transform: capitalize;
}
.button-wide-add:hover,
.button-wide-add:focus,
.button-wide-add:active {
  border-style: solid;
  border-color: #b7964e;
  color: #b7964e;
}
.button-wide-add .svg-inline--fa {
  vertical-align: -2px;
}
.button-wide-add.without-border-button {
  border: none;
  margin-top: 0;
  color: #b7964e;
}
.button-primary {
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  padding: 0.5rem 0;
  text-shadow: none;
  width: 200px;
  font-size: 1.4rem;
  height: 3.6rem;
  border-width: thin;
}
.button-primary__wrapper .button-primary {
  margin-left: auto;
  margin-right: auto;
}
.action-icon-button,
.musics-small-edit-icon-button,
.musics-small-delete-icon-button {
  font-size: 1.4rem;
  border: none;
  box-shadow: none;
  width: 2rem;
  height: 3.6rem;
  padding: 0;
  color: #b7964e;
}
.action-icon-button:hover {
  color: #eaca96;
}
.buttons-instead-dropdown {
  display: flex;
  align-items: center;
}
.buttons-instead-dropdown.ant-btn-background-ghost {
  color: #000;
  border-color: #d9d9d9;
}
.buttons-instead-dropdown > * {
  margin-right: 20px;
}
.buttons-instead-dropdown > *:last-child {
  margin-right: 0;
}
.collapse__wrapper {
  width: 100%;
}
.collapse-panel__header-wrapper {
  padding: 0.8rem 0;
  font-size: 1.4rem;
  color: #595959;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background: transparent;
  border: none;
  width: 100%;
  align-items: center;
  outline: none;
}
.collapse-panel__expanding-icon.collapse-panel__expanding-icon {
  transition: transform 0.3s ease-in-out;
  color: #595959;
  font-size: 1.4rem;
}
.collapse-panel__expanding-icon.collapse-panel__expanding-icon.opened {
  transform: rotate(180deg);
}
.collapse-panel__expanding-icon-wrapper {
  padding: 0.4rem 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.collapse-panel__expanding-wrapper {
  width: calc(100% - 30px);
  text-align: left;
}
.collapse-panel__expanding-area {
  transition: max-height, padding-bottom 0.5s, 0.2s ease-in-out;
  transform-origin: top;
  padding-bottom: 0;
}
.collapse-panel__expanding-area.opened {
  padding-bottom: 20px;
}
.dn {
  display: none;
}
.tac,
.page-section-title.page-section-title {
  text-align: center !important;
}
.ttu {
  text-transform: uppercase;
}
.ttc {
  text-transform: capitalize;
}
.ma {
  margin: auto;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-40 {
  margin-left: 40px;
}
.pl-10 {
  padding-left: 10px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-10 {
  padding-right: 10px;
}
.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40,
.change-password-button {
  margin-bottom: 40px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-40 {
  margin-right: 40px;
}
.p-8 {
  padding: 8px;
}
.ph-8,
.booking-list-title-wrap {
  padding-left: 8px;
  padding-right: 8px;
}
.ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.ph-20,
.venues-map-wrapper {
  padding-left: 20px;
  padding-right: 20px;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pt-10 {
  padding-top: 10px;
}
.mh-10 {
  margin-left: 10px;
  margin-right: 10px;
}
.mh-20 {
  margin-left: 20px;
  margin-right: 20px;
}
.mh-40 {
  margin-left: 40px;
  margin-right: 40px;
}
.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.mb-40,
.change-password-button {
  margin-bottom: 40px;
}
.h-36 {
  height: 36px;
}
.w-100 {
  width: 100%;
}
.ws-nowrap,
.text-overflow,
.musician-payment-row-name-wrap,
.musician-payment-row-name-wrap b {
  white-space: nowrap;
}
[data-rbd-drag-handle-context-id='0'] {
  outline: none;
}
.text-overflow,
.musician-payment-row-name-wrap,
.musician-payment-row-name-wrap b {
  overflow: hidden;
  text-overflow: ellipsis;
}
.statusbar-styles {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  height: 60px;
  width: calc(100% - 100px);
}
.statusbar-details__wrapper,
.gig-roster-statusbar-wrapper {
  display: none;
}
@media (min-width: 1200px) {
  .statusbar-details__wrapper,
  .gig-roster-statusbar-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    height: 60px;
    width: calc(100% - 100px);
  }
}
.subsection-title {
  background-color: #fafafa;
  padding: 1.4rem 1.6rem 0.7rem;
  border-bottom: thin solid #e6e6e6;
  margin-bottom: 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 60px;
}
@media (min-width: 992px) {
  .subsection-title {
    padding: 2.5rem 1rem 0.3rem;
  }
}
.table-status__cell {
  border: none;
  box-shadow: none;
  outline: none;
  font-size: 1.4rem;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
}
.rk-title-wrap,
.booking-list-title {
  color: #595959;
  font-size: 18px;
  font-family: Josefin Sans, sans-serif;
}
.card-shadow {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
.desktop-item {
  display: none;
}
.mobile-item {
  display: block;
}
@media (min-width: 992px) {
  .desktop-item {
    display: block;
  }
  .mobile-item {
    display: none;
  }
}
.page-section,
.page-header {
  padding: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
@media not all and (min-width: 768px) {
  .page-section,
  .page-header {
    box-shadow: none;
  }
}
.page-section:not(:last-child) {
  margin-bottom: 0;
}
.payment-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
}
.payment-section .page-section {
  flex: 1;
}
.page-section-title.page-section-title {
  font-size: 1.8rem;
  font-family: Josefin Sans, sans-serif;
  color: rgba(0, 0, 0, 0.65);
  user-select: none;
  white-space: nowrap;
}
.page-header {
  margin-bottom: 20px !important;
}
.page-header > .ant-row {
  width: 100%;
  flex-wrap: nowrap;
}
@media not all and (min-width: 1200px) {
  .page-header {
    margin-bottom: 0 !important;
    box-shadow: none;
    border-bottom: thin solid #e6e6e6;
  }
}
@media (min-width: 768px) {
  .page-header {
    padding-right: 1.6rem;
  }
}
@media (min-width: 1200px) {
  .page-header {
    padding-right: 2.6rem;
  }
}
.page-header-scroll-row.page-header-scroll-row {
  overflow: hidden;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.page-header-extra-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.page-header-extra-container .ant-dropdown-trigger {
  height: 36px;
}
.form-wrapper {
  padding: 1rem;
}
@media (min-width: 768px) {
  .form-wrapper {
    padding: 1.6rem;
  }
}
@media (min-width: 1200px) {
  .form-wrapper {
    padding: 2rem;
  }
}
.asterisk {
  color: #ff4d4f;
}
.ant-form-item.ant-form-item-has-error .intl-tel-input input {
  border: 1px solid #ff4d4f;
}
.footer-container {
  padding: 50px 0;
  background-color: #f9f6f1;
  color: #909090;
  text-align: center;
  z-index: 100;
  position: relative;
}
.footer-container-info {
  display: flex;
  justify-content: space-between;
}
.footer-container.has-sticky-nav {
  padding-bottom: 190px;
}
.footer-social_link.footer-social_link {
  width: 48px;
  height: 48px;
  line-height: 52px;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  color: #373737;
  margin: 0 7px;
  font-size: 19px;
}
.footer-social_link.footer-social_link:hover,
.footer-social_link.footer-social_link:focus,
.footer-social_link.footer-social_link:active {
  background-color: #b7e6dc;
  color: #373737;
}
.footer-separator {
  margin-bottom: 0;
  background-color: transparent;
  margin-top: 15px;
}
.footer-copyright {
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  color: #000;
}
.footer-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-list li {
  margin-right: 46px;
  display: inline-block;
  outline: none;
}
.footer-list li:last-child {
  margin-right: 0;
}
.footer-list li:hover,
.footer-list li:focus,
.footer-list li:active {
  outline: none;
}
.footer-list li a {
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 13px;
  transition: color 0.3s ease-in-out;
  outline: none;
}
.footer-list li a:hover,
.footer-list li a:focus,
.footer-list li a:active {
  color: #818181;
  text-decoration: none;
  outline: none;
}
@media only screen and (max-width: 1000px) {
  .footer-list li {
    margin-right: 7px;
  }
  .footer-list li a {
    font-size: 11px;
  }
}
@media only screen and (max-width: 768px) {
  .footer-list li {
    width: 100% !important;
  }
}
.ersatz-table-row,
.select-musician-row {
  padding: 1rem 1rem;
  font-size: 1.4rem;
  height: 4.4rem;
  border-bottom: thin solid #e6e6e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #595959;
  position: relative;
  width: 100%;
}
@media (max-width: 992px) {
  .ersatz-table-row,
  .select-musician-row {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
    width: calc(100% - 32px);
    padding: 0;
  }
}
.navbar-profile-dropdown .ant-dropdown-menu-submenu-title {
  padding-right: 48px;
  padding-left: 16px;
}
.navbar-profile-dropdown .ant-dropdown-menu-submenu-title:hover {
  background-color: #f8f8f8;
}
.navbar-profile-dropdown .ant-dropdown-menu-item {
  padding-left: 16px;
  padding-right: 16px;
}
.navbar-profile-dropdown .ant-dropdown-menu-item:hover {
  background-color: #f8f8f8;
}
.navbar-profile-dropdown .nav-horizontal-role-wrapper + .ant-dropdown-menu-submenu-arrow {
  right: 16px;
}
.modal-subtitle {
  font-size: 2rem;
  color: #595959;
  text-align: center;
}
.modal-subtitle-row {
  margin-bottom: 1rem;
}
@media not all and (min-width: 576px) {
  .modal-subtitle {
    font-size: 1.8rem;
  }
}
.modal-button-row {
  position: relative;
  padding-top: 2rem;
  justify-content: center;
  display: flex;
}
@media not all and (min-width: 480px) {
  .modal-button-row {
    flex-direction: column;
    align-items: center;
  }
}
.modal-button-row .ant-btn:first-child {
  margin-right: 10px;
}
.modal-button-row .ant-btn:last-child {
  margin-left: 10px;
}
@media not all and (min-width: 480px) {
  .modal-button-row .ant-btn {
    margin: 0 10px 10px;
  }
  .modal-button-row .ant-btn:last-child {
    margin-bottom: 0;
  }
}
.modal-logo-wrapper {
  text-align: center;
}
.modal-logo-wrapper img {
  margin-bottom: 30px;
}
.modal-logo-wrapper > h2:last-child,
.modal-logo-wrapper > p:last-child {
  margin-bottom: 30px;
}
.modal-logo-description {
  font-size: 1.4rem;
}
@media (max-width: 1199px) {
  .modal_without_header .ant-modal-body {
    padding: 20px 16px;
    overflow-y: auto;
    max-height: 100vh;
  }
}
.modal_centered.ant-modal {
  height: auto;
  top: 100px;
}
.musician-selection__modal .modal-button-row {
  margin-top: -1.6rem;
}
.ant-modal-root .ant-modal-confirm-btns .ant-btn-primary {
  background: #b7964e;
}
.ant-modal-root .ant-modal-confirm-btns .ant-btn-primary:focus {
  background: #b7964e;
}
.package-modal .ant-btn.ant-btn-background-ghost {
  color: #2f2f2f;
}
.package-modal__time-picker-popup .ant-picker-time-panel-column:last-child::after {
  height: auto;
}
.package-modal-duration-field {
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
}
.package-modal-duration-field .anticon {
  line-height: 0;
}
.package-modal-duration-input {
  border: none !important;
  pointer-events: none;
}
.package-modal-duration-input.ant-picker-focused {
  border: none;
  box-shadow: none;
}
.package-modal-duration-input input {
  text-align: center;
}
.package-modal-duration-button-increase,
.package-modal-duration-button-decrease {
  border-color: #a8a8a8;
  width: 25%;
  text-align: center;
  color: #a9a6a6;
  background-color: transparent;
  border: none;
  transition: all 0.3s ease;
  outline: none;
}
.package-modal-duration-button-increase[disabled],
.package-modal-duration-button-decrease[disabled] {
  pointer-events: none;
}
.package-modal-duration-button-increase:hover,
.package-modal-duration-button-decrease:hover,
.package-modal-duration-button-increase:active,
.package-modal-duration-button-decrease:active,
.package-modal-duration-button-increase:focus,
.package-modal-duration-button-decrease:focus {
  color: #a9a6a6;
  background-color: transparent;
}
.package-modal .ant-modal-content {
  height: auto;
}
.package-modal .ant-modal-content .ant-modal-close-x {
  width: 45px;
  height: 45px;
  line-height: 45px;
}
.package-modal .ant-modal-content .font-awesome-icon {
  color: #595959;
}
.package-modal .ant-modal-content .ant-modal-header {
  padding: 11px 22px;
  border-bottom: 1px solid #707070;
}
.package-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 18px;
  color: #595959;
}
.package-modal .ant-modal-content .ant-modal-body {
  padding: 26px 22px;
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item input {
  font-size: 14px;
  color: #595959;
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item input::placeholder {
  color: #9b9b9b;
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item .ant-form-item-label label {
  color: #595959;
  font-size: 16px;
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item .package-modal-duration-field.disabled {
  border: 1px solid #ebebeb;
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item .package-modal-duration-field button {
  background-color: #fff;
  color: #a9a6a6;
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item .package-modal-duration-field button:hover {
  color: #a9a6a6;
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item .package-modal-duration-field button:disabled {
  background-color: #fff;
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item .package-modal-duration-field .package-modal-duration-input {
  padding: 5px 11px;
  height: 34px;
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item .package-modal-duration-field .package-modal-duration-input.ant-picker-disabled {
  background-color: #fff;
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item .package-modal-duration-field .package-modal-duration-input.ant-picker-disabled input {
  color: rgba(0, 0, 0, 0.25);
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item .package-modal-duration-field .package-modal-duration-input.ant-picker-disabled input::placeholder {
  color: #e6e6e6;
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item .ant-picker-disabled {
  background-color: #fff;
  border: 1px solid #ebebeb;
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item .ant-picker-disabled input {
  color: rgba(0, 0, 0, 0.25);
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item .ant-picker-disabled input::placeholder {
  color: #e6e6e6;
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item .ant-form-item-explain .ant-form-item-explain-error {
  font-size: 14px;
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item .ant-picker {
  height: 36px;
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item:not(.ant-select-customize-input) .ant-select-selector {
  height: 36px;
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  font-size: 14px;
  line-height: 36px;
  color: #595959;
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 36px;
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  font-style: italic;
  line-height: 36px;
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item .ant-select-item {
  font-size: 14px;
}
.package-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item .ant-select-arrow {
  color: #595959;
}
.package-modal .ant-input-affix-wrapper-disabled {
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #ebebeb;
}
.package-modal .ant-input-affix-wrapper-disabled input[type='text'].ant-input.ant-input-disabled:disabled::placeholder {
  color: #e6e6e6;
}
.package-modal .ant-input-affix-wrapper > input.ant-input {
  height: auto;
}
.package-modal .ant-input-affix-wrapper .ant-input-suffix {
  font-size: 12px;
  color: #bfbfbf;
}
.package-modal .gig-detail__title {
  color: #9c9c9c;
  text-align: center;
  font-weight: 600;
  font-size: 17px;
  letter-spacing: 1.1px;
}
.package-modal .gig-detail__fieldset legend {
  color: #d2d2d2;
  font-size: 16px;
  margin-bottom: 25px;
}
.package-modal .gig-detail__fieldset .ant-select {
  color: #d2d2d2;
}
.package-modal .gig-detail__not-sure-when .ant-checkbox-wrapper {
  color: #595959;
  font-size: 14px;
}
.package-modal .gig-detail__location {
  margin-bottom: 0;
}
.package-modal .gig-detail__location-not-sure-where {
  margin-bottom: 10px;
}
.package-modal .gig-detail__location-not-sure-where .ant-radio-group {
  width: 100%;
  display: flex;
}
.package-modal .gig-detail__location-not-sure-where .ant-radio-group .ant-radio-wrapper {
  width: 50%;
  margin-left: 10px;
  color: #000000a6;
  font-size: 14px;
}
.package-modal .gig-detail__location-not-sure-where .ant-radio-group .ant-radio-wrapper .ant-radio-inner {
  border-color: #707070;
}
.package-modal .gig-detail__location-not-sure-where .ant-radio-group .ant-radio-wrapper .ant-radio-disabled .ant-radio-inner {
  border-color: #d9d9d9;
}
.package-modal .gig-detail__location-address {
  margin: 4px 0 16px;
}
.package-modal .gig-detail__location-address input[type='text'].ant-input:disabled {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.25);
}
.sidebar-menu.sidebar-menu {
  height: calc(100vh - 158px);
  overflow: auto;
}
.sidebar-menu.sidebar-menu .ant-menu-item.ant-menu-item-selected {
  font-weight: bold;
}
.sidebar-menu.sidebar-menu.ant-menu-dark,
.sidebar-menu.sidebar-menu.ant-menu-dark .ant-menu-sub {
  background-color: #434343;
}
.sidebar-wrapper.sidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  max-width: 200px;
  min-width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebar-menu .ant-menu-submenu-arrow::before {
  display: none;
}
.sidebar-menu .ant-menu-submenu-arrow::after {
  display: none;
}
.sidebar-menu.customize-sidebar .ant-menu-submenu-open {
  color: rgba(255, 255, 255, 0.65);
}
.musician-booking-switch-wrap {
  display: flex;
  margin-bottom: 7px;
}
.switching-bar-item {
  margin-right: 1rem;
}
.switching-bar-item.active {
  position: relative;
}
.switching-bar-item.active:after {
  position: absolute;
  content: '';
  display: unset;
  width: 100%;
  height: 1px;
  background-color: #9b9b9b;
  top: calc(100% + 0.8rem);
  left: 0;
}
.switching-bar-item.last {
  margin-right: 0;
}
.switching-bar-item-wrap {
  user-select: none;
  line-height: 1;
  font-size: 1.4rem;
  cursor: pointer;
  font-weight: 400;
  color: #b7964e;
  white-space: nowrap;
  padding: 0;
  height: auto;
}
.switching-bar-item-wrap:hover {
  color: #eaca96;
}
.switching-bar-item-wrap.pale {
  color: #9b9b9b;
}
.switching-bar-item-wrap.active {
  font-weight: 700;
  color: #b7964e;
}
.switching-bar-item-wrap.disabled {
  pointer-events: none;
}
.switching-bar-item-wrap.last {
  margin-right: 0;
}
.terms-conditions-link.terms-conditions-link {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1;
  color: #000;
}
.terms-conditions-link.terms-conditions-link:hover {
  color: #707070;
}
.register-form {
  width: 100%;
}
.register-form .button-primary {
  width: 100%;
}
.register-form__performer-select.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  background-color: #faf9f9;
  color: #555;
}
.register-form__performer-select.ant-select-disabled .ant-select-selection {
  background-color: #faf9f9;
  cursor: default;
}
.register-form .google-map-prediction-container {
  text-align: left;
}
.invitation-modal .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.invitation-modal .ant-select-selector {
  height: 36px;
}
.invitation-modal .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-top: 2px;
}
.invitation-modal .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding-top: 2px;
}
.notes-button {
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  padding: 0.5rem 0;
  text-shadow: none;
  width: 200px;
  font-size: 1.4rem;
  height: 3.6rem;
  border-width: thin;
  margin: 2rem auto;
}
.notes-button__wrapper .button-primary {
  margin-left: auto;
  margin-right: auto;
}
.notes-button.edit-notes {
  color: #b7964e;
  margin: 2rem auto 0;
}
.dragged-wrap > .opened[data-rbd-scroll-container-context-id] h4 span {
  color: #ffffff;
}
@media (max-width: 1199px) {
  .booking-mobile-padded-row {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.booking-mobile-padded-row-10-16 {
  padding-left: 10px;
  padding-right: 10px;
}
@media (max-width: 1199px) {
  .booking-mobile-padded-row-10-16 {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.booking-mobile-padded-row-10-0 {
  padding-left: 10px;
  padding-right: 10px;
}
@media (max-width: 1199px) {
  .booking-mobile-padded-row-10-0 {
    padding-left: 0;
    padding-right: 0;
  }
}
.booking-content__wrap {
  flex-wrap: nowrap;
  overflow: hidden;
}
@media (max-width: 480px) {
  .booking-list-title {
    font-size: 16px;
    white-space: nowrap;
  }
}
.booking-list-title-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}
.booking-list-title-wrap .buttons-list-title-wrap {
  display: flex;
  margin-bottom: 3px;
}
.booking-event-timeline.booking-event-timeline {
  margin-left: 5rem;
  padding: 2rem 0 0 1rem;
}
@media (max-width: 1199px) {
  .booking-event-timeline.booking-event-timeline {
    margin-left: 5.3rem;
    padding-right: 0.4rem;
  }
}
.booking-event-timeline-item.booking-event-timeline-item {
  user-select: none;
  position: relative;
  font-size: 1.4rem;
  padding-bottom: 1rem;
  color: #595959;
}
.booking-event-timeline-item.booking-event-timeline-item .rk-delete-icon {
  color: #c8c8c8;
  transform: scale(0.5);
  opacity: 0;
}
.booking-event-timeline-item.booking-event-timeline-item .rk-delete-icon:hover {
  color: #b74e4e;
}
.booking-event-timeline-item.booking-event-timeline-item:hover .rk-delete-icon {
  transform: scale(1);
  opacity: 1;
}
@media (max-width: 991px) {
  .booking-event-timeline-item.booking-event-timeline-item .rk-delete-icon {
    opacity: 1;
    transform: scale(1);
  }
}
.booking-event-timeline-item__wrapper {
  flex-wrap: nowrap;
}
.booking-event-timeline-item__info {
  display: inline-block;
  width: calc(100% - 36px);
}
.booking-event-timeline-item__description {
  line-height: normal;
}
.booking-event-timeline-item__time {
  display: inline-block;
  padding: 0 1rem;
  font-weight: bold;
  margin-bottom: 0.6rem;
  margin-right: 0.5rem;
  position: absolute;
  left: -7.7rem;
  top: 0;
}
.booking-event-timeline .ant-timeline-item-head {
  top: 0.5rem;
}
.booking-event-timeline .ant-timeline-item-content {
  top: -1px;
  margin-left: 20px;
}
.booking-payment__wrapper {
  margin-top: 4rem;
}
.booking-payment__buttons-row {
  flex-direction: column;
  margin-top: 2rem;
}
.booking-musician-offers__text {
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
@media (max-width: 1199px) {
  .booking-musician-offers__text {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.booking-musician-offers__wrapper .send-musician-offer-button {
  margin: auto;
  margin-top: 15px;
}
.booking-status-bar__wrapper {
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  background: #e6e6e6;
}
.booking-status-bar__wrapper_is-musician {
  bottom: 50px;
}
@media (min-width: 768px) {
  .booking-status-bar__wrapper {
    display: none;
  }
}
.booking-status-bar__icon-wrapper {
  margin: 0;
  padding: 1.5rem 1rem;
  background-color: #d8d8d8;
  color: #777;
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}
.booking-status-bar__icon-wrapper_active {
  background-color: #b7964e;
  color: #ffffff;
}
.booking-status-bar__icon-wrapper_disabled {
  pointer-events: none;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
}
.booking-status-bar__icon-wrapper .svg-inline--fa {
  font-size: 16px;
}
.booking_section_between_margin {
  margin-top: 20px;
}
.booking-list-col-collapsed__section {
  position: relative;
  overflow: hidden;
}
.booking-section.modal-section {
  margin: auto;
  pointer-events: auto;
}
.booking-section.modal-section .ant-modal-content {
  overflow-y: scroll;
}
.booking-section.modal-section .ant-modal-body {
  padding: 0;
  max-height: unset;
}
.timeline-event-date__wrapper {
  margin-top: 16px;
  padding-left: 10px;
  padding-right: 10px;
}
@media (max-width: 1199px) {
  .timeline-event-date__wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.timeline-event-date__diff-text {
  color: #777;
}
.musics-panel .collapse-panel__header-wrapper {
  padding-left: 10px;
}
@media (max-width: 1199px) {
  .musics-panel .collapse-panel__header-wrapper {
    padding-left: 0;
  }
}
.musics-panel .collapse-panel__expanding-wrapper span {
  font-weight: 700;
}
.musics-expanded-area-wrapper {
  padding: 1.4rem 4rem 1rem 1rem;
  position: relative;
  border-bottom: thin solid #e6e6e6;
}
.musics-expanded-area-wrapper:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
@media (max-width: 1199px) {
  .musics-expanded-area-wrapper {
    padding: 1.4rem 4rem 1rem 0;
  }
}
.musics-expanded-area-content {
  font-size: 1.4rem;
}
.musics-list-icon {
  margin-right: 0.8rem;
  color: #595959;
}
.musics-key-songs-files-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.musics-top-songs-number {
  color: #9b9b9b;
  margin-right: 0.5rem;
}
.musics-top-songs-number.active {
  color: #595959;
}
.musics-delete-button.musics-delete-button {
  border: thin solid transparent;
  box-shadow: none;
  position: absolute !important;
  left: 0;
  bottom: 0;
  height: 36px;
}
.musics-small-edit-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  max-height: 4rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  max-width: 4rem;
  background: none;
  outline: none;
  cursor: pointer;
}
@media (max-width: 1199px) {
  .musics-small-edit-icon-button {
    right: -1rem;
  }
}
.musics-small-delete-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  max-height: 4rem;
  position: absolute;
  top: 0.5rem;
  right: 0;
  max-width: 4rem;
  background: none;
  outline: none;
  cursor: pointer;
}
@media (max-width: 1199px) {
  .musics-small-delete-icon-button {
    right: -1rem;
  }
}
.musics-delete-icon {
  right: 4rem;
}
.add-price-button {
  padding: 1.1rem 1rem;
  width: auto;
  margin-top: 0;
}
.gig-title-wrap {
  margin-bottom: 1rem;
  position: relative;
  line-height: 1;
}
.gig-title-wrap .rk-button__right {
  position: absolute;
  right: -10px;
  top: -10px;
}
@media not all and (min-width: 992px) {
  .gig-title-wrap .rk-button__right {
    right: 0;
  }
}
.gig-list__item {
  padding-bottom: 1rem;
  padding-top: 1rem;
  position: relative;
  width: 100%;
}
.gig-list__item-musician {
  font-size: 1.4rem;
  color: #595959;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 1200px) {
  .gig-list__item-musician {
    padding-left: 0;
  }
}
.gig-list__item-wrapper {
  border-bottom: thin solid #e6e6e6;
  border-top: none;
  padding: 0;
  width: 100%;
}
.gig-list__item-wrapper.rk-without-border {
  border-bottom: none;
}
.gig-list__item-overflow {
  text-overflow: ellipsis;
  width: 100%;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1;
  margin: auto;
}
@media (max-width: 1199px) {
  .gig-list__item-overflow {
    width: 100%;
    padding: 0;
  }
}
.gig-list__item-overflow:hover {
  overflow: visible;
  text-overflow: unset;
}
.gig-list__item-time {
  font-size: 1rem;
  color: #9b9b9b;
}
@media (max-width: 1200px) {
  .gig-list__item-time {
    padding-left: 0;
  }
}
.gig-list__item-content {
  padding: 8px 20px 4px;
}
@media (max-width: 1199px) {
  .gig-list__item-content {
    padding: 8px 0 4px;
  }
}
.gig-list__item-content-header {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
@media (max-width: 1199px) {
  .gig-list__item-content-header {
    padding-left: 0;
    padding-right: 0;
  }
}
.gig-list__item-content-header-title {
  white-space: nowrap;
  color: #9b9b9b;
  text-transform: uppercase;
  font: normal normal bold 12px/15px Lato;
}
.gig-list__item-content-header-buttons {
  white-space: nowrap;
  line-height: 1;
}
.gig-list__item-content-header-buttons .ant-btn {
  font-size: 12px;
  padding: 0;
  height: auto;
}
.gig-list__item-content-header-payment-button .svg-inline--fa {
  margin-right: 5px;
}
.gig-list__item-content-header-payment-button span {
  color: #b7964e;
}
.gig-list__row-with-primary-bottom {
  padding: 1rem 0;
  position: relative;
  width: 100%;
  border-bottom: thin solid #b7964e;
}
@media (max-width: 1199px) {
  .gig-list__row-with-primary-bottom {
    padding: 1rem;
  }
}
.gig-list__collapse .collapse-panel__expanding-area {
  border-bottom: thin solid #e8e8e8;
  padding-bottom: 0;
}
.gig-list__collapse .gig-list__item {
  padding: 0;
}
.gig-list__collapse .gig-list__item-wrapper {
  border-bottom: 0;
}
.gig-list__collapse .gig-list__item-wrapper .allocate-singer-icon {
  text-align: right;
  color: #b7964e;
  font-size: 16px;
}
.gig-list__collapse .gig-list__item-content-header {
  min-height: 35px;
  align-items: center;
  background-color: #fff;
}
.gig-list__collapse .gig-list__item-musician.archived b {
  color: #004ce6;
}
.gig-list__collapse .ersatz-table-row {
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 1199px) {
  .gig-list__collapse .ersatz-table-row {
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
}
.position-payment-modal {
  position: relative;
}
.position-payment-modal__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.position-payment-modal .ant-modal-header {
  padding: 16px 14px 10px;
}
.position-payment-modal .ant-modal-title {
  font-size: 18px;
}
.position-payment-modal .ant-modal-body {
  padding: 20px 14px;
}
.position-history__item {
  white-space: nowrap;
  font-size: 12px;
  display: flex;
  border-top: thin solid #e8e8e8;
  padding: 6px 10px;
}
@media (max-width: 1199px) {
  .position-history__item {
    padding-left: 0;
    padding-right: 0;
  }
}
.position-history__item-date {
  width: 110px;
  margin-right: 8px;
}
.position-history__item-status {
  width: 60px;
  margin-right: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.position-history__item-fullname {
  width: calc(100% - 186px);
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
}
.musician-payment-table__column-header {
  line-height: 10px;
  vertical-align: bottom;
  opacity: 0.5;
  text-transform: uppercase;
}
.musician-payment-row {
  flex-wrap: nowrap;
}
.musician-payment-row-name-wrap {
  padding-right: 5px;
}
.musician-payment-row-name-wrap b {
  margin-right: 10px;
}
.musician-payment-panel {
  border-bottom: 1px solid #b7964e;
}
.musician-payment-panel .collapse-panel__header-wrapper {
  padding-left: 10px;
}
.musician-payment-add-adjustment-btn {
  width: 100%;
  margin-top: 20px;
}
.price-item-title {
  display: flex;
  align-items: center;
}
.price-item-title__tooltip {
  margin-left: 5px;
  position: relative;
}
.price-item-title__tooltip svg {
  color: #b7964e;
  font-size: 22px;
  max-width: 26px;
}
.price-item-title__tooltip--description {
  display: none;
  position: absolute;
  bottom: calc(100% + 11px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #595959;
  min-width: 180px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding: 8px;
  border-radius: 3px;
  z-index: 10;
}
.price-item-title__tooltip--description::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  width: 100%;
  height: 20px;
}
.price-item-title__tooltip--description::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #595959;
}
.price-item-title__tooltip--description__button button {
  margin: 8px 0 4px;
  background-color: #b7964e;
  border: none;
  outline: none;
  padding: 5px 20px;
  line-height: 1;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
}
.price-item-title__tooltip--description__button button:hover {
  background-color: #edbd54;
}
.price-item-title__tooltip:hover .price-item-title__tooltip--description {
  display: block;
}
.price-item-wrapper {
  border-bottom: thin solid #e8e8e8;
}
.price-item-wrapper .rk-edit-icon {
  opacity: 1;
  transform: scale(1);
  border: none;
  padding: 0 4px;
  height: 24px;
  margin: 4px 8px 4px 0;
}
@media (max-width: 992px) {
  .price-item-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  .price-item-wrapper .rk-edit-icon {
    opacity: 1;
    transform: scale(1);
  }
}
.price-item-wrapper-title {
  font-size: 1.4rem;
  color: #595959;
}
.price-adjustment-modal .adjustment-amount-box {
  height: 36px;
}
.price-adjustment-modal .adjustment-amount-box .ant-input {
  height: 26px;
}
.submit-inquiry-form {
  padding: 12% 17% !important;
}
.qb-text-primary-color {
  color: #000 !important;
}
.qb-go-to-website {
  font-size: 14px !important;
  padding-top: 15px;
  cursor: pointer;
}
.qb-note-information {
  margin-top: 5px;
  margin-bottom: 20px;
}
.reception-modal-item-des.qb-scroll {
  overflow-y: hidden;
}
.reception-modal-item-des.qb-scroll.opened {
  overflow-y: auto;
}
.reception-modal-item-des p {
  color: #909090;
}
.event-note-rich-text-editor {
  width: 100%;
}
.ql-editor {
  min-height: 20em;
}
.rich-text-note__description {
  font: normal normal normal 14px/17px Lato;
  letter-spacing: 0;
  color: #9b9b9b;
  margin-bottom: 16px;
  margin-top: 16px;
}
.rich-text-note__container {
  padding: 1rem 0 0;
}
.rich-text-note__container h1 {
  font-size: 2.5em;
}
.rich-text-note__container h2 {
  font-size: 1.5em;
}
.rich-text-note__container h4 {
  font-size: 0.75em;
}
.rich-text-note__container blockquote {
  border-left: 4px solid #ccc;
  padding-left: 14px;
}
.rich-text-editor-modal .ant-modal-body {
  padding: 18px;
}
.rich-text-note__markDown-placeholder {
  text-align: left;
  color: #9b9b9b;
  font-size: 14px;
  padding: 10px 5px;
}
.unlock-price-modal .ant-form {
  padding: 10% 17% !important;
}
.payment-history__table {
  width: 100%;
}
.payment-history__title {
  font-size: 1.4rem;
  font-weight: 550;
  padding-left: 1rem;
}
@media (max-width: 1199px) {
  .payment-history__title {
    padding-left: 0;
  }
}
.payment-history__row {
  justify-content: left;
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  border-bottom: thin solid #e6e6e6;
  display: flex;
  align-items: center;
  color: #595959;
  width: 100%;
}
@media (max-width: 992px) {
  .payment-history__row {
    padding: 0.5rem 0;
  }
}
.payment-history__row span:not(:last-child) {
  padding-right: 0.8rem;
}
.payment-history__row-date {
  white-space: nowrap;
  width: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.payment-history__row-status {
  white-space: nowrap;
  width: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.payment-history__row-type {
  white-space: nowrap;
  width: calc(100% - 172px);
  text-overflow: ellipsis;
  overflow: hidden;
}
.musician-missed-offer-message {
  margin: 2rem auto;
  text-align: center;
}
.musician-missed-offer-message-title {
  color: #b7964e;
  font-size: 1.8rem;
  font-weight: bold;
}
.ant-input-number.adjustment-amount-box {
  width: 100%;
}
.delete-adjustment-icon {
  color: #9b9b9b;
}
.delete-adjustment-icon:hover {
  color: #b74e4e;
}
.moment-name-input {
  width: 100%;
  margin-top: 1.8rem;
}
.ant-form-item.add-song-radio-group {
  flex-direction: row;
  width: 100%;
  margin-top: 1.8rem;
}
.ant-form-item.add-song-radio-group .ant-form-item-label {
  margin: auto;
  padding-top: 5px;
}
.add-key-song-input {
  width: 100%;
  margin-top: 0.5rem;
}
.timeline-key-song {
  display: block;
}
.timeline-key-song .sub-text {
  color: lightgray;
  font-size: 1.6rem;
}
.confirm-button__wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.firm-price-adjustment-modal .ant-modal-header {
  padding: 1.6rem;
}
.firm-price-adjustment-modal .ant-modal-body {
  padding: 2.4rem 1.6rem;
}
.firm-price-adjustment-modal .position-name-autocomplete {
  height: 36px;
}
.firm-price-adjustment-modal .position-name-autocomplete .ant-input {
  height: 28px;
}
.firm-price-adjustment-modal .position-name-autocomplete .ant-select-selector {
  height: 36px;
}
.firm-price-adjustment-modal .position-name-autocomplete .ant-select-selection-item {
  line-height: 36px;
}
.firm-price-adjustment-modal .position-name-autocomplete .ant-select-selection-placeholder {
  line-height: 36px;
}
.firm-price-adjustment-modal .expanding-checkbox {
  margin-bottom: 1rem;
}
.firm-price-adjustment-modal .collapse-panel__expanding-area {
  border-bottom: none;
  overflow: unset;
}
.firm-price-adjustment-modal .ant-input-number-input {
  height: 36px;
}
.firm-price-adjustment-modal .link-musician-payment-item {
  position: relative;
  border-top: thin solid #e6e6e6;
  margin-top: 12px;
}
.firm-price-adjustment-modal .link-musician-payment-item:first-child {
  border-top: thin solid transparent;
}
.firm-price-adjustment-modal .link-musician-payment-item .deposit-request-fix-amount {
  display: flex;
  margin-top: 1rem;
}
.firm-price-adjustment-modal .link-musician-payment-item .deposit-request-fix-amount > span {
  transform: translateY(3px);
  color: #595959;
}
.firm-price-adjustment-modal .link-musician-payment-item .deposit-request-fix-amount .ant-form-item {
  margin: 0 0 0 8px;
}
.firm-price-adjustment-modal .link-musician-payment-item .close-btn {
  position: absolute;
  right: 0;
  top: 10px;
  color: #b7964e;
  z-index: 10;
}
.firm-price-adjustment-modal .link-musician-payment-item .hidden {
  display: none;
}
.firm-price-adjustment-modal .link-musician-payment .add-more-musician-payment {
  margin-top: 20px;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  color: #474747;
  transition: all 0.3s;
}
.firm-price-adjustment-modal .link-musician-payment .add-more-musician-payment:not([disabled]):hover {
  color: #b7964e;
}
.firm-price-adjustment-modal .link-musician-payment .add-more-musician-payment[disabled] {
  color: rgba(0, 0, 0, 0.25);
}
.firm-price-adjustment-modal .subsection-title {
  align-items: center;
}
.payment-request-modal .ant-modal-body {
  padding: 10px 20px;
}
.payment-request-modal-group {
  margin: 10px auto 24px;
  width: 100%;
}
.payment-request-modal-group .ant-form-item {
  margin-bottom: 0;
}
.payment-request-modal-group .percentage-input {
  display: block;
  width: 80px;
  position: relative;
}
.payment-request-modal-group .percentage-input .ant-input-number {
  line-height: 1;
  font-weight: 700;
  width: 100%;
}
.payment-request-modal-group .percentage-input .ant-input-number input {
  text-align: right;
  padding-right: 25px;
  height: 25px;
}
.payment-request-modal-group .percentage-input .ant-input-number input:disabled {
  color: rgba(0, 0, 0, 0.25);
}
.payment-request-modal-group .currency-input {
  display: block;
  width: 16rem;
  position: relative;
}
.payment-request-modal-group .currency-input .ant-input-number {
  line-height: 1;
  font-weight: 700;
  width: 100%;
}
.payment-request-modal-group .currency-input .ant-input-number input:disabled {
  color: rgba(0, 0, 0, 0.25);
}
.payment-request-modal-group .ant-radio-wrapper {
  display: block;
  margin-top: 1rem;
  font-size: 14px;
}
.payment-request-modal-group__radio {
  display: block;
  line-height: 1;
}
.payment-request-modal-group__radio-label {
  white-space: nowrap;
}
.payment-request-modal-group__radio-wrapper {
  display: flex;
  align-items: center;
}
.payment-request-modal .amount-to-pay__wrapper {
  border-top: thin solid #e6e6e6;
  border-bottom: thin solid #e6e6e6;
  color: #595959;
  font-weight: 700;
  text-align: center;
  padding: 0.5rem 0;
  margin-bottom: 2rem;
}
.add-key-song-input .custom-require-mark {
  margin-left: 2px;
}
.singer-list__subheading {
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.45);
  text-transform: uppercase;
  margin-right: 10px;
}
.price-row {
  padding: 8px 10px 9px;
}
.long-text-button {
  width: 280px !important;
}
.musician-accept-other-gig-warning .ant-tooltip-inner {
  color: black !important;
}
.customer-info-modal .ant-select-selection-search .ant-input-suffix {
  color: #bfbfbf;
  font-size: 12px;
}
.customer-info-modal .ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
  padding: 3px 11px;
}
.customer-info-modal .ant-select-single .ant-select-selector .ant-select-selection-item {
  display: flex;
  align-items: center;
}
.customer__inner {
  border-bottom: thin solid #e6e6e6;
  padding: 1rem 0;
}
.customer__inner:last-child {
  border-bottom: none !important;
  padding-bottom: 0;
}
@media only screen and (max-width: 1200px) {
  .customer__inner {
    padding: 1rem 1.6rem;
  }
}
.customer__buttons-wrapper {
  position: absolute;
  right: 0;
  top: -10px;
}
.parent-option-contain-sub-form {
  height: 30px;
}
.musician-preferences-input {
  width: 100%;
}
.preference-spin-loading {
  margin-right: 0.4rem;
}
.preference-section {
  padding-right: 20px 20px 20px 0;
}
.event-timeline-modal .ant-dropdown-trigger {
  display: none;
}
.event-timeline-modal .ant-list-item-meta-title {
  color: rgba(0, 0, 0, 0.45);
}
.event-timeline-modal .ant-list-item {
  padding: 3px 0;
}
.event-timeline-modal .ant-table-selection .ant-checkbox.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner::after {
  left: 3px;
}
.event-timeline-modal .selected-event-timeline .ant-list-item-meta-title {
  color: white;
  font-weight: 700;
}
.event-timeline-modal .template-warper {
  max-height: 40vh;
  overflow-y: auto;
}
.event-timeline-modal .ant-list-split .ant-list-item {
  cursor: pointer;
  padding-left: 5px;
}
.event-timeline-modal .ant-modal-body {
  padding: 15px;
  min-height: 75vh;
}
.event-timeline-modal .event-timeline-select.ant-select,
.event-timeline-modal .ant-input-group {
  width: 90%;
}
.event-timeline-modal .ant-input.ant-input {
  height: fit-content;
  border: none;
  background-color: transparent;
}
.event-timeline-modal .event-timeline-select.ant-select .ant-select-selector {
  border: none;
  background-color: transparent;
}
.event-timeline-modal .action-text {
  cursor: pointer;
}
.event-timeline-modal .action-text.svg-inline--fa:hover {
  color: #ff4d4f;
}
.event-timeline-modal .ant-picker {
  border: none;
  background-color: transparent;
}
.event-timeline-modal textarea {
  resize: none;
}
.event-timeline-modal .ant-select-selection-overflow-item {
  max-width: 200px;
}
@media only screen and (max-width: 1400px) {
  .event-timeline-modal .ant-select-selection-overflow-item {
    max-width: 150px;
  }
}
@media only screen and (max-width: 1200px) {
  .event-timeline-modal .ant-select-selection-overflow-item {
    max-width: 100px;
  }
}
.primary-text-color {
  color: #b7964e !important;
}
.ant-typography.ant-typography-secondary.selected-number {
  margin-right: 5px;
  background-color: #b7964e;
  color: white !important;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  padding: 4px 8px;
}
.selected-event-timeline {
  cursor: pointer;
  background-color: #b7964e;
}
.selected-event-timeline .ant-typography.ant-typography-secondary {
  color: white !important;
  font-weight: 600;
}
.selected-event-timeline .ant-list-item-meta-description {
  color: white !important;
  font-weight: 600;
}
.selected-event-timeline .ant-typography.ant-typography-secondary.selected-number {
  background-color: white;
  color: #b7964e !important;
}
.selected-event-timeline .musics-expanded-area-wrapper .musics-delete-icon {
  display: none;
}
.selected-event-timeline .musics-expanded-area-wrapper:hover .musics-delete-icon {
  display: flex;
}
.selected-event-timeline .musics-expanded-area-wrapper .musics-delete-icon:hover .button-icon-edit {
  color: #ff4d4f;
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #9b9b9b;
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
#booking-details__section .ant-dropdown-trigger .ant-btn:not([disabled]):hover,
#booking-details__section .ant-dropdown-trigger .ant-btn:focus {
  margin-right: -1px;
}
.adjust-pay-modal p,
.adjust-pay-modal input {
  color: #535252;
  font-size: 14px;
}
.adjust-pay-modal .ant-modal-title {
  font-size: 22px;
  color: #535252;
}
.adjust-pay-modal .ant-modal-body {
  padding: 20px;
}
.adjust-pay-modal .ant-modal-body .new-amount .ant-input-number {
  width: 100%;
}
.adjust-pay-modal-previous-amount {
  height: 100%;
  position: relative;
}
.adjust-pay-modal-previous-amount-title {
  padding-bottom: 8px;
  color: #535252;
}
.adjust-pay-modal-previous-amount-payable {
  margin-left: 14px;
}
.adjust-pay-modal-previous-amount::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #d8d4d4;
  width: 100%;
  height: 1px;
}
.adjust-pay-modal .ant-form-item-required::before {
  top: 2px;
}
.adjust-pay-modal-reason {
  margin-top: 20px;
}
.adjust-pay-modal > .ant-modal-content .ant-modal-body .ant-form-item {
  margin-bottom: 0;
}
.adjust-pay-modal > .ant-modal-content .ant-modal-body .ant-form-item .ant-form-item-label {
  line-height: 1.3;
}
.adjust-pay-modal > .ant-modal-content .ant-modal-body .ant-form-item .ant-form-item-label > label {
  font-size: 14px;
  color: #535252;
}
.adjust-pay-modal .ant-btn-primary {
  margin-top: 20px;
}
.booking_section_between_margin.disabled {
  pointer-events: none;
}
.gig-roster {
  background: white;
}
.gig-roster-statusbar-wrapper {
  display: flex;
}
.gig-roster .collapse-panel__header-wrapper {
  background-color: #fafafa;
  padding: 1.4rem 1.6rem 0.7rem;
  border-bottom: thin solid #e6e6e6;
  margin-bottom: 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 60px;
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 992px) {
  .gig-roster .collapse-panel__header-wrapper {
    padding: 2.5rem 1rem 0.3rem;
  }
}
.gig-roster .collapse-panel-expanding-icon {
  padding: 0.6rem 0;
}
.gig-roster .collapse-panel-expanding-icon svg {
  color: #b7964e;
}
.gig-roster .collapse-panel-expanding-area {
  padding: 0 20px;
}
.gig-roster-mobile-wrapper {
  padding: 0 8px;
}
.gig-roster-mobile-wrapper:first-child {
  border-top: thin solid #e6e6e6;
}
.gig-roster-mobile-wrapper.empty {
  background-color: #fff4dd;
}
.gig-roster-mobile-performance-name {
  margin-bottom: 4px;
}
.gig-roster-mobile-name {
  margin-bottom: 8px;
}
.gig-roster-mobile-time {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.gig-roster-nav-mobile {
  background: #fff;
  border-bottom: thin solid #e6e6e6;
}
.gig-roster-nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  height: 60px;
  width: calc(100% - 100px);
  width: calc(100% - 20px);
}
@media not all and (min-width: 768px) {
  .gig-roster-nav-wrapper {
    width: 100%;
  }
}
@media not all and (min-width: 480px) {
  .gig-roster-nav-wrapper {
    justify-content: space-around;
  }
}
.gig-roster-nav-arrow {
  color: #b7964e;
  cursor: pointer;
  white-space: nowrap;
  display: inline-flex;
  border: none;
  outline: none;
  padding: 0;
  min-width: 32px;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1200px) {
  .gig-roster-nav-arrow {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.gig-roster-nav-arrow .fa-chevron-left {
  margin-left: 1.2rem;
}
@media not all and (min-width: 1200px) {
  .gig-roster-nav-arrow .fa-chevron-left {
    margin-left: 0;
  }
}
.gig-roster-nav-arrow .fa-chevron-right {
  margin-right: 1.2rem;
}
@media not all and (min-width: 1200px) {
  .gig-roster-nav-arrow .fa-chevron-right {
    margin-right: 0;
  }
}
.gig-roster-nav-arrow:hover {
  opacity: 0.7;
}
@media not all and (min-width: 1200px) {
  .gig-roster-nav-buttons-label {
    display: none !important;
  }
}
@media not all and (min-width: 1200px) {
  .gig-roster-nav-form {
    margin: 0 10px !important;
  }
}
@media not all and (min-width: 480px) {
  .gig-roster-nav-form {
    margin: 0 !important;
  }
}
@media not all and (min-width: 992px) {
  .gig-roster-nav-form .ant-form-item-label {
    display: none !important;
  }
}
.gig-roster-nav-form .ant-form-item {
  margin-bottom: 0;
}
.gig-roster-nav-form .ant-form-item-control {
  width: 28rem;
}
@media not all and (min-width: 480px) {
  .gig-roster-nav-form .ant-form-item-control {
    width: 24rem;
  }
}
.artist-details-checkbox__label {
  color: gray;
}
@media (max-width: 575px) {
  .artist-details-checkbox__form-item {
    margin-bottom: 0;
  }
}
.artist-details-checkbox__form-column {
  padding-top: 34px;
}
@media (max-width: 575px) {
  .artist-details-checkbox__form-column {
    padding-top: 0;
  }
}
@media (max-width: 1199px) {
  .artist-details__form .button-primary {
    margin: 0 auto;
  }
}
.artist-details__form-button-wrapper {
  text-align: center;
  margin-top: 1.6rem;
}
@media (max-width: 1199px) {
  .artist-details__form-button-wrapper {
    margin: 1.6rem auto 0;
  }
}
.artist-details__form .ant-checkbox {
  top: 2px;
}
.ant-form-vertical .user-preference-checkbox.ant-form-item {
  flex-direction: row;
}
.musician-preferences-check-list .ant-form-item-control-input-content {
  padding-bottom: 8px;
  float: right;
}
.musician-bio-action-button {
  display: flex;
  margin-left: auto;
}
.musician-bio-page-section {
  height: auto !important;
}
.media-content-wrapper {
  background: #ececec;
  padding: 10px;
  margin-bottom: 10px;
}
@media (max-width: 575px) {
  .ant-form.musician-preferences-check-list .ant-form-item .ant-form-item-control {
    max-width: 15% !important;
  }
  .ant-form.musician-preferences-check-list .ant-form-item.musician-preferences-input .ant-form-item-control {
    max-width: 100% !important;
  }
  .ant-form.musician-preferences-check-list .ant-form-item .ant-form-item-label {
    max-width: 85% !important;
  }
}
.musician-private-note {
  text-align: left;
  font: normal normal normal 12px/15px Lato;
  letter-spacing: 0;
  color: #9b9b9b;
  opacity: 1;
  padding: 2px 0 8px 0;
}
.private-note-label .ant-form-item-label {
  padding: 0;
}
.user-avatar-image {
  background-color: transparent;
}
.musician-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  gap: 8px;
}
.musician-airwallex-button {
  width: auto;
  padding: 0 20px;
}
.musician-outstanding-payment-button.musician-outstanding-payment-button {
  width: auto;
  padding: 0 20px;
}
.offers-wrap {
  flex-direction: column;
}
.offers-modal-buttons {
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  padding: 20px 0;
  border-top: thin solid #e8e8e8 !important;
}
.offer-carousel-empty-container {
  min-height: 300px;
}
.next-gig-empty-container {
  min-height: 60vh;
  margin: 0 auto;
}
@media only screen and (max-width: 1200px) {
  .offer-carousel-empty-container {
    min-height: 180px;
  }
  .next-gig-empty-container {
    min-height: unset;
  }
}
.musician-guide-modal .detail-modal-banner {
  width: 100%;
}
.musician-guide-modal div {
  background-color: white;
}
.musician-guide-modal .ant-modal-close-icon {
  padding: 3px;
  font-size: 1.5rem;
  background-color: lightgray;
}
.musician-guide-carousel-button {
  width: 120px;
  cursor: pointer;
}
.musician-guide-carousel-button:focus {
  background-color: #b7964e;
}
.musician-guide-carousel {
  padding: 2.5rem;
}
.musician-guide-carousel .ant-typography {
  color: #808080;
}
.musician-guide-carousel .slick-dots li.slick-active {
  width: 38px !important;
}
.musician-guide-carousel .slick-dots li {
  width: 28px !important;
}
.musician-guide-carousel .slick-dots {
  margin-bottom: 1rem;
}
.musician-guide-carousel .slick-dots button::before {
  width: 12px;
  height: 12px;
}
.musician-guide-carousel .slick-dots li.slick-active button::before {
  color: #b7964e;
  background-color: #b7964e;
}
.musician-guide-carousel .slick-dots li button::before {
  color: #696969;
  background-color: #696969;
}
.musician-guide-carousel .ant-carousel .slick-dots li button {
  border-radius: 0;
}
.musician-guide-carousel .ant-carousel .slick-dots {
  justify-content: left;
  margin-left: 0;
  bottom: -25px;
}
.musician-guide-carousel .slick-track {
  height: 18rem;
}
@media only screen and (max-width: 480px) {
  .musician-guide-carousel .slick-track {
    height: 25rem;
  }
}
.preferred-musicians-order-item {
  flex-basis: calc(100% - 50px);
  overflow: hidden;
}
.preferred-musicians-order-name {
  flex-basis: calc(100% - 28px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.select-musician-row {
  min-height: 4.4rem;
}
@media (max-width: 992px) {
  .select-musician-row {
    width: calc(100% - 40px);
  }
}
#qbDetails .ant-modal-root .ant-modal-mask,
#qbDetails .ant-modal-root .ant-image-preview-mask {
  background: #f9f6f2 0% 0% no-repeat padding-box;
  opacity: 0.9;
}
#qbDetails .ant-form-item-explain-warning {
  color: #ce2d41;
  font: italic normal 500 10px/14px Montserrat;
  text-align: center;
}
.qb-scroll,
.qb-modal .ant-modal-body {
  overflow-y: auto;
}
.qb-scroll::-webkit-scrollbar {
  margin-top: 0;
  padding-top: 0;
  width: 6px;
  height: 6px;
  border-radius: 10px;
}
.qb-scroll::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #ccc5c2;
}
@media (min-width: 992px) {
  .qb-scroll:hover::-webkit-scrollbar-thumb {
    background: #afacac;
  }
}
.qb-scroll-has-track-piece {
  overflow-y: auto;
}
.qb-scroll-has-track-piece::-webkit-scrollbar {
  margin-top: 0;
  padding-top: 0;
  width: 6px;
  height: 6px;
  border-radius: 10px;
}
.qb-scroll-has-track-piece::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #ccc5c2;
}
@media (min-width: 992px) {
  .qb-scroll-has-track-piece:hover::-webkit-scrollbar-thumb {
    background: #afacac;
  }
}
.qb-scroll-has-track-piece::-webkit-scrollbar-track-piece {
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-radius: 2px;
  background: transparent;
  box-shadow: inset 0 0 5px #ccc5c2;
}
.qb-logo {
  display: none;
}
.qb-button {
  padding: 0 30px;
  text-shadow: none;
  font: normal normal bold 12px/14px Montserrat;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.07px;
  border: 1px solid #ce2d41;
  height: 36px;
  white-space: normal;
  color: #f2f2f2;
}
.qb-button:hover {
  background: #ac2939;
}
.qb-button.ant-btn-primary {
  background: #ce2d41;
  border-radius: 20px;
}
.qb-button.ant-btn-primary:focus {
  background-color: #ac2939;
  border-color: #ac2939;
  color: white;
}
.qb-button.ant-btn-primary:hover {
  background-color: #ac2939;
  border-color: #ac2939;
  color: white;
}
.qb-button.ant-btn-primary.ant-btn-primary[disabled] {
  color: #fff;
  background: #a8a8a8 0% 0% no-repeat padding-box;
  border-color: #a8a8a8;
}
.qb-button.ant-btn-primary.ant-btn-background-ghost:hover,
.qb-button.ant-btn-primary.ant-btn-background-ghost:focus {
  color: #ffffff;
  background: #000 !important;
}
.qb-button.ant-btn-primary.ant-btn-background-ghost:hover span,
.qb-button.ant-btn-primary.ant-btn-background-ghost:focus span,
.qb-button.ant-btn-primary.ant-btn-background-ghost:hover svg,
.qb-button.ant-btn-primary.ant-btn-background-ghost:focus svg {
  color: #ffffff;
}
.qb-button.selected {
  background: none;
  border: 2px solid #ce2d41;
  color: #ce2d41;
  font-weight: bold;
}
.qb-callback-outer-wrapper {
  width: 100%;
  background: #f9f6f1;
  padding-top: 20px;
}
.qb-callback-outer-wrapper .qb-details-inner-wrapper {
  padding: 25px;
  border-top: 1px solid #a8a8a8;
  border-bottom: 1px solid #a8a8a8;
}
.qb-callback-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.qb-callback-title {
  font: normal normal normal 24px/1 Lato;
  color: black;
}
.qb-callback-text {
  font: normal normal normal 16px/1 Lato;
  color: #909090;
  margin-top: 15px;
}
.qb-callback-phone-icon {
  margin-left: 5px;
}
.qb-callback-left .qb-callback-title {
  font: normal normal normal 28px/1 cardo;
}
.qb-callback-right button {
  font-family: montserrat;
  font-size: 13px;
}
.qb-callback-right button:hover {
  background-color: #2c2c2c !important;
  color: white !important;
}
.qb-callback-right .qb-button.ant-btn-primary {
  background-color: black;
  border: none;
  font-weight: bold;
}
.qb-city-dropdown-menu.qb-city-dropdown-menu {
  padding: 5px 0;
  background-color: #f9f6f1;
}
.qb-city-dropdown-menu.qb-city-dropdown-menu .ant-dropdown-menu-item:hover,
.qb-city-dropdown-menu.qb-city-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.qb-city-dropdown-menu.qb-city-dropdown-menu .ant-dropdown-menu-item:hover,
.qb-city-dropdown-menu.qb-city-dropdown-menu .ant-dropdown-menu-submenu-title:focus,
.qb-city-dropdown-menu.qb-city-dropdown-menu .ant-dropdown-menu-item:hover,
.qb-city-dropdown-menu.qb-city-dropdown-menu .ant-dropdown-menu-submenu-title:active {
  background-color: #e4d7cd;
  color: #505050;
}
.qb-city-dropdown-option.qb-city-dropdown-option {
  background-color: #f9f6f1;
  padding: 5px 16px;
}
.qb-city-dropdown-option.qb-city-dropdown-option .text {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 12px;
  color: #505050;
  font-family: montserrat;
}
.qb-city-dropdown-trigger.qb-city-dropdown-trigger {
  border-color: #ccc5c2;
  color: #ffffff;
  padding: 8px 24px;
}
.qb-city-dropdown-trigger.qb-city-dropdown-trigger > .ant-row {
  flex-wrap: nowrap;
}
.qb-city-dropdown-trigger img {
  margin-right: 15px;
}
.qb-city-dropdown-trigger-name {
  margin-right: 20px;
  font: normal normal 600 12px/14px montserrat;
  color: #505050;
}
.qb-city-dropdown-trigger-name.qb-city-dropdown-trigger-name + img {
  margin-right: 10px;
}
.qb-city-dropdown-marker-icon {
  color: #000;
  margin-right: 10px;
}
.qb-city-dropdown-marker-icon.fa-chevron-down {
  margin-right: 0;
  font-size: 12px;
  color: #505050;
}
.qb-details-outer-wrapper .footer-container .terms-conditions-link,
.qb-details-outer-wrapper .footer-container .footer-copyright {
  font: normal normal normal 13px/16px Montserrat;
  color: #909090;
  text-transform: none;
}
.qb-details-event-date {
  color: #909090;
  flex-grow: 1;
}
.qb-details-event-date-section {
  padding: 30px 0 0;
  margin-bottom: 24px;
  font: normal normal normal 14px/20px Lato;
}
.qb-details-event-date-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.qb-details-event-date .ant-picker {
  background: none;
  border: 1px solid #ccc5c2;
  font: normal normal normal 14px/20px Lato;
  color: #bababa;
  padding: 10px;
  box-shadow: none;
}
.qb-details-event-date .ant-picker input {
  font: normal normal normal 11px/18px Montserrat;
  color: #000;
}
.qb-details-event-date .ant-picker input::placeholder {
  font-style: italic;
  color: #505050;
}
.qb-details-event-date .ant-picker-suffix {
  color: #505050;
  margin-top: -4px;
  font-size: 18px;
}
.qb-details-event-date .ant-picker.ant-picker:hover,
.qb-details-event-date .ant-picker.ant-picker-focused {
  border-color: #a8a8a8;
}
.qb-details-event-date .ant-picker.ant-picker-disabled {
  background: transparent;
  border: 1px solid #a8a8a8;
  box-shadow: none;
  color: #909090;
  opacity: 0.8;
}
.qb-details-event-date .ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: #a8a8a8;
}
.qb-details-event-date .ant-picker .ant-picker-input > input[disabled] {
  color: #909090;
}
.qb-details-event-date .ant-form-item {
  margin-bottom: 0;
}
.qb-details-event-date .ant-form-item .ant-picker {
  background: #fff;
  border-radius: 5px;
}
.qb-details-event-date .ant-form-item-has-warning .ant-picker {
  border: 1px solid #be3c46;
}
.qb-details-event-date .ant-form-item-has-warning .ant-picker:not([disabled]):hover,
.qb-details-event-date .ant-form-item-has-warning .ant-picker:not([disabled]):focus {
  border-color: #ce2d41;
}
.qb-details-event-date .ant-form-item-has-warning .ant-form-item-explain-warning .icon {
  margin-right: 4px;
  color: #000;
}
.qb-details-event-date .ant-form-item-explain {
  margin-top: 4px;
  font-size: 14px;
  min-height: unset;
}
@media (max-width: 575px) {
  .qb-details-event-date {
    margin-left: 0;
    max-width: unset;
  }
  .qb-details-event-date-wrapper {
    flex-direction: column;
    align-items: stretch;
  }
}
.qb-details-gig-section {
  margin-bottom: 30px;
}
.qb-details-gig-section .qb-details-gigs-head {
  margin-top: 0;
}
.qb-details-outer-wrapper {
  overscroll-behavior: contain;
  background-color: #f9f6f1;
  min-height: 100%;
}
.qb-details-outer-wrapper.qb-scroll {
  height: 100%;
}
.qb-details-outer-wrapper .qb-details-item-field-control {
  background-color: #fff;
  border-radius: 5px;
}
.qb-details-inner-wrapper {
  max-width: 1400px;
  width: calc(100% - 60px);
  margin: auto;
  padding-top: 50px;
  color: #909090;
  position: relative;
}
.qb-details-inner-wrapper .ant-picker:hover,
.qb-details-inner-wrapper .ant-picker-focused {
  box-shadow: none !important;
}
.qb-details-inner-wrapper.fixed_header {
  padding-top: 118px;
}
.qb-details-inner-wrapper .ant-tabs-nav-wrap {
  padding-left: 30px;
  padding-right: 30px;
}
.qb-details-inner-wrapper .ant-tabs-nav-list {
  width: 100%;
  display: flex;
}
.qb-details-inner-wrapper .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #373737 !important;
}
.qb-details-inner-wrapper .ant-tabs-tab {
  text-transform: uppercase;
  margin-right: 0;
  flex-grow: 1;
}
.qb-details-inner-wrapper .ant-tabs-tab .ant-tabs-tab-btn {
  margin: auto;
  color: #bababa;
  font-family: Syne;
  font-weight: 700;
}
@media (max-width: 1199px) {
  .qb-details-inner-wrapper__first-screen {
    width: auto;
    overflow-x: hidden;
  }
}
.qb-details-title {
  text-align: center;
  font: normal normal normal 53px/57px Cardo;
  color: black;
  text-transform: capitalize;
  margin-bottom: 40px;
  margin-top: -25px;
}
.qb-details-subtitle {
  max-width: 940px;
  margin: -26px auto 0;
  font: normal normal normal 18px/23px Lato;
  color: #bababa;
}
@media (max-width: 1199px) {
  .qb-details-subtitle {
    padding: 0 30px;
    margin-bottom: 14px;
    font-size: 18px;
  }
}
.qb-details-subtitle__wrapper {
  position: relative;
  margin-bottom: 20px;
}
@media (max-width: 1330px) {
  .qb-details-subtitle__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.qb-details-subtitle__button {
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 1330px) {
  .qb-details-subtitle__button {
    position: static;
    margin-top: 20px;
  }
}
.qb-details-gigs-title {
  font: normal normal bold 12px/14px Syne;
  color: black;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}
.qb-details-gigs-head {
  margin-bottom: 12px;
  margin-top: -8px;
}
.qb-details-gigs-item .reception-modal-item-video-list {
  display: flex;
}
.qb-details-gigs-item .reception-modal-item-video-list .swiper {
  width: 100%;
}
.qb-details-item {
  color: #000;
  width: 100%;
}
.qb-details-item__address .qb-details-item-field-control {
  border: none;
  height: auto;
  background: transparent;
}
.qb-details-item__address .ant-form-item {
  margin-bottom: 0;
  width: 100%;
}
.qb-details-item__address .ant-form-item .ant-input {
  background: #fff;
  border: 1px solid #ccc5c2;
  border-radius: 5px;
}
.qb-details-item__address .ant-form-item-control-input {
  border: none;
}
.qb-details-item__address .ant-form-item-has-warning .ant-input {
  border: thin solid #bababa;
  transition: border 0.3s, box-shadow 0.3s;
  background: #fff;
}
.qb-details-item__address .ant-form-item-has-warning .ant-input:hover {
  background: #fff;
  border-color: #b7964e;
}
.qb-details-item__address .ant-form-item-has-warning .ant-input:focus {
  border-color: #eaca96;
}
.qb-details-item__address .ant-form-item-has-warning .ant-input:not([disabled]):hover,
.qb-details-item__address .ant-form-item-has-warning .ant-input:not([disabled]):focus {
  background: #fff;
  border-color: #bababa;
}
.qb-details-item__address .ant-form-item-has-warning .ant-input-affix-wrapper.ant-input-affix-wrapper-status-warning {
  border-color: #ce2d41;
}
.qb-details-item__address .ant-form-item-has-warning .ant-input-affix-wrapper.ant-input-affix-wrapper-status-warning #auto-complete-ceremony,
.qb-details-item__address .ant-form-item-has-warning .ant-input-affix-wrapper.ant-input-affix-wrapper-status-warning #auto-complete-reception {
  border-color: #ce2d41;
}
.qb-details-item__address .ant-form-item-has-warning .ant-form-item-explain-warning {
  color: #ce2d41;
  font: italic normal 500 10px/14px Montserrat;
  text-align: center;
}
.qb-details-item__address .ant-form-item-has-warning .ant-form-item-explain-warning .icon {
  margin-right: 4px;
  color: #ce2d41;
}
.qb-details-item__address .ant-form-item-explain {
  margin-top: 4px;
  min-height: unset;
  font: normal normal normal 14px/20px Josefin Sans;
}
.qb-details-item__address textarea.ant-input {
  background: none;
  resize: none;
  border: none;
  color: #000;
  font: normal normal normal 11px/18px Montserrat;
  padding: 8px 46px 8px 10px;
  height: auto;
}
.qb-details-item__address textarea.ant-input:focus,
.qb-details-item__address textarea.ant-input:hover {
  border-color: #a8a8a8;
  box-shadow: none;
}
.qb-details-item__address textarea.ant-input[disabled] {
  cursor: default;
}
.qb-details-item__address textarea.ant-input::placeholder {
  color: #505050;
  font-style: italic;
}
.qb-details-item-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.qb-details-item-title {
  font-family: Syne;
  font-weight: 700;
  font-size: 13px;
  color: #000;
  text-transform: uppercase;
  margin-right: 10px;
}
.qb-details-item-price-text {
  color: #000;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 14px;
  font-family: Syne;
  font-weight: bold;
  font-size: 12px;
}
.qb-details-item-price-number {
  font-family: Syne;
  font-weight: bold;
  font-size: 12px;
  color: #000;
}
.qb-details-item-location-kind {
  color: #909090;
  font-weight: bold;
  text-transform: uppercase;
}
.qb-details-item-location-name {
  font: normal 18px/23px Lato;
}
.qb-details-item-field-name {
  color: black;
  margin-bottom: 5px;
  font: normal normal bold 10px/14px Syne;
  letter-spacing: 0.5px;
}
.qb-details-item-field-name.address-title,
.qb-details-item-field-name.duration-title {
  margin-top: 15px;
}
.qb-details-item-field-name::first-letter {
  text-transform: uppercase;
}
.qb-details-item-field-control {
  display: flex;
  align-items: center;
  border: 1px solid #ccc5c2;
  height: 58px;
  position: relative;
  background: #f9f6f1 0 0 no-repeat padding-box;
}
.qb-details-item-field-control .ant-picker-dropdown {
  display: none;
}
.qb-details-item-field-control.name-field {
  height: 36px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 5px;
}
.qb-details-item-field-control.name-field:focus,
.qb-details-item-field-control.name-field:hover {
  border-color: #a8a8a8;
}
.qb-details-item-field-control.name-field .qb-details-gig-select {
  flex: 1;
  text-align: left;
  font: normal normal normal 11px/18px Montserrat;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.qb-details-item-field-control.name-field .qb-details-gig-select svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.qb-details-item-field-control.name-field .qb-details-gig-select.ant-btn {
  width: 100%;
  padding: 0 10px;
}
.qb-details-item-field-control.name-field .qb-details-gig-select-placeholder {
  color: #505050;
  font-style: italic;
  user-select: none;
}
.qb-details-item-field-control.name-field .qb-details-gig-name {
  font: normal normal normal 11px/18px Montserrat;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.qb-details-item-field-control.name-field .qb-details-gig-name.ant-btn {
  padding-left: 0;
  padding-right: 0;
}
.qb-details-item-field-control.name-field .qb-details-gig-name-placeholder {
  color: #505050;
  font-style: italic;
  user-select: none;
}
.qb-details-item-field-control.name-field .qb-details-gig-name-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 10px;
}
.qb-details-item-field-control.name-field .qb-details-gig-name-container-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  width: 50%;
}
.qb-details-item-field-control.name-field .qb-details-gig-name-container .icon-primary {
  flex-shrink: 1;
  height: auto;
  width: auto;
  padding: 0;
  border: none;
  margin: 0 8px;
  color: #000;
}
.qb-details-item-field-control.duration-field {
  height: 36px;
}
.qb-details-item-field-control.duration-field:hover {
  border-color: #a8a8a8;
}
.qb-details-item-field-control.duration-field a {
  color: #ce2d41;
}
.qb-details-item-field-control.duration-field .ant-btn-primary {
  color: #505050;
}
.qb-details-item-field-control.duration-field svg {
  font-size: 14px;
  shape-rendering: auto !important;
}
.qb-details-item-field-control.filled:hover > .font-awesome-icon,
.qb-details-item-field-control.focus.qb-details-item-field-control.filled > .font-awesome-icon {
  display: none;
}
.qb-details-item-field-control.filled:hover .ant-input-affix-wrapper .anticon-close-circle,
.qb-details-item-field-control.focus.qb-details-item-field-control.filled .ant-input-affix-wrapper .anticon-close-circle {
  opacity: 1;
  cursor: pointer;
}
.qb-details-item-field-control .ant-input-affix-wrapper {
  background: none;
  border: none;
  height: auto;
  box-shadow: none !important;
}
.qb-details-item-field-control .ant-input-affix-wrapper .anticon {
  color: #000;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
}
.qb-details-item-field-control-duration-decrease,
.qb-details-item-field-control-duration-increase {
  width: 25%;
  text-align: center;
  background: #f9f6f1;
  color: #b39659;
  border: none;
}
.qb-details-item-field-control .ant-form-item-control-input-content {
  display: flex;
}
.qb-details-item-field-control .qb-details-item-field-control-duration-increase,
.qb-details-item-field-control .qb-details-item-field-control-duration-decrease {
  border-color: #a8a8a8;
}
.qb-details-item-field-control .qb-details-item-field-control-duration-increase:hover,
.qb-details-item-field-control .qb-details-item-field-control-duration-decrease:hover,
.qb-details-item-field-control .qb-details-item-field-control-duration-increase:active,
.qb-details-item-field-control .qb-details-item-field-control-duration-decrease:active,
.qb-details-item-field-control .qb-details-item-field-control-duration-increase:focus,
.qb-details-item-field-control .qb-details-item-field-control-duration-decrease:focus {
  border-color: #a8a8a8;
}
.qb-details-item-field-control .qb-details-item-field-control-duration-increase:active,
.qb-details-item-field-control .qb-details-item-field-control-duration-decrease:active {
  background: #a8a8a8 !important;
  transition: all 0.3s ease;
}
.qb-details-item-field-control .qb-details-item-field-control-duration-input {
  background: none;
  border: none;
  pointer-events: none;
  height: auto;
}
.qb-details-item-field-control .qb-details-item-field-control-duration-input input {
  text-align: center;
  color: #000;
  font: italic normal normal 11px/18px Montserrat;
}
.qb-details-item-field-control .ant-picker.ant-picker-disabled,
.qb-details-item-field-control .ant-btn-primary[disabled],
.qb-details-item-field-control .ant-btn-primary[disabled]:hover,
.qb-details-item-field-control .ant-btn-primary[disabled]:focus,
.qb-details-item-field-control .ant-btn-primary[disabled]:active {
  background: none;
  color: #5d5d5d;
  cursor: default;
  border-color: #a8a8a8;
}
.qb-details-item-field-control .ant-picker.ant-picker-disabled input[disabled],
.qb-details-item-field-control .ant-btn-primary[disabled] input[disabled],
.qb-details-item-field-control .ant-btn-primary[disabled]:hover input[disabled],
.qb-details-item-field-control .ant-btn-primary[disabled]:focus input[disabled],
.qb-details-item-field-control .ant-btn-primary[disabled]:active input[disabled] {
  color: #5d5d5d;
  cursor: default;
}
.qb-details-item-field-control .ant-picker.ant-picker-disabled {
  background: #fff;
}
.qb-details-item-empty-placeholder {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  background: #f9f6f1;
}
.qb-details-item-empty-placeholder .qb-button {
  margin-top: 20px;
}
@media (max-width: 575px) {
  .qb-details-item-empty-placeholder {
    font-size: 14px;
  }
  .qb-details-item-empty-placeholder .qb-button {
    display: none;
  }
}
.qb-details-item-empty-placeholder .svg-inline--fa {
  font-size: 46px;
  color: #b7964e;
  margin-bottom: 20px;
}
.qb-details-item-main-img img {
  display: block;
  width: 100%;
}
.qb-details-item-1st-view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
}
.qb-details-item-1st-view-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  padding: 30px 40px;
  display: flex;
  width: 100%;
}
.qb-details-item-1st-view-detail {
  font: normal normal normal 12px/14px Syne;
  margin: 0 auto;
}
.qb-details-item-1st-view-detail-title {
  font: normal normal bold 12px/16px Syne;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  text-align: center;
}
.qb-details-item-1st-view-icon {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.qb-details-item-1st-view-icon .svg-inline--fa {
  transform: rotate(-45deg);
  font-size: 23px;
}
.qb-details-singers-wrapper .qb-singer-preferences-top .qb-details-singers-subtitle--desktop {
  margin: 10px 0 -10px;
  font: normal normal normal 10px/18px Montserrat;
  color: #505050;
}
.qb-details-singers-wrapper .qb-singer-preferences-top .qb-button {
  margin: 20px 0 28px;
}
.qb-details-section {
  background-color: #f9f6f1;
  padding: 20px 25px;
  margin-bottom: 25px;
  border: 1px solid #a8a8a8;
  width: 100%;
}
.qb-details-section .qb-singer-list {
  max-height: 800px;
  margin: 0 -10px 0 0;
  padding: 0 10px 0 0;
}
.qb-details-section-title {
  font: normal normal bold 26px/30px Syne;
  letter-spacing: 1.1px;
  color: black;
  text-transform: uppercase;
  margin: 0;
}
.qb-details-section-title.qb-details-question {
  text-align: center;
  margin-bottom: 30px;
}
.qb-details-section-gigs-head {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.qb-details-section .font-awesome-icon {
  margin: 0 10px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.qb-details-section .font-awesome-icon.fa-pen {
  color: #505050;
  padding-bottom: 2px;
  border-bottom: 1px solid #000;
  font-size: 14px;
  transform: translateY(-18px);
}
.qb-details-section .google-map-prediction-container {
  color: #909090;
}
.qb-details-section .google-map-prediction-container:hover {
  background: #f5f5f5;
}
@media (max-width: 575px) {
  .qb-details-singers-wrapper .qb-button {
    width: 100%;
  }
}
.qb-details-singers-subtitle {
  font: normal normal normal 11px/18px Montserrat;
  color: #505050;
  margin: 30px 0;
}
.qb-group-modal {
  background: #f9f6f1;
  padding-bottom: 0;
}
.qb-group-modal .ant-modal-content {
  background: #f9f6f1;
}
.qb-group-modal .ant-modal-content .ant-modal-header {
  background: #f9f6f1;
  padding: 16px 0 18px;
  border: none;
}
.qb-group-modal .ant-modal-content .ant-modal-body {
  overflow: hidden;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.qb-group-modal .ant-modal-content .ant-modal-body .qb-group-modal-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.qb-group-modal-title {
  font-size: 13px;
  font-weight: 700;
  font-family: Syne;
  text-transform: capitalize;
}
.qb-group-modal-title-left {
  position: absolute;
  top: -2px;
  left: 0;
}
.qb-group-modal-title-left .qb-button {
  color: #ffffff;
}
.qb-group-modal-title-center {
  text-align: center;
  color: #ffffff;
}
.qb-group-modal-content .band-modal-content-ceremony-list-item {
  background: #f9f6f1;
  padding-bottom: 20px;
}
.qb-group-modal-content .band-modal-content-ceremony-list-item__title {
  padding: 0 10px 0 30px;
  text-transform: uppercase;
}
.qb-group-modal-content .band-modal-content-ceremony-list-item__price {
  padding: 0 10px 0 30px;
  margin-bottom: 20px;
}
.qb-group-modal-content .band-modal-content-ceremony-list-item__description {
  padding: 0 30px;
  margin: 0 0 30px;
}
.qb-navbar-wrapper {
  height: 68px;
  padding: 15px 50px;
  display: flex;
  justify-content: space-between;
  background: #f9f6f1;
  top: -111px;
}
.qb-navbar-wrapper.fixed_header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: top 0.3s ease-out;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}
.qb-navbar-wrapper .qb-city-dropdown-trigger {
  padding: 0 25px;
  height: 100%;
}
.qb-navbar-wrapper .qb-telephone-city-wrapper {
  align-items: center;
}
.qb-navbar-wrapper .qb-telephone-city-wrapper .tel {
  font: normal normal 600 14px/16px Montserrat;
  color: black;
  text-transform: uppercase;
  margin-right: 50px;
}
.qb-navbar-wrapper .qb-navbar-logo img {
  height: 100%;
  margin: 0;
  display: block;
}
.qb-navbar-logo {
  padding: 0;
  height: 100%;
}
.qb-notes-textarea.qb-notes-textarea {
  padding: 10px 5px;
  margin: 0 0 20px;
  background: #fff 0 0% no-repeat padding-box;
  border: 1px solid #ccc5c2;
  border-radius: 5px;
  resize: vertical;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 11px;
  color: #505050;
  height: 82px;
  min-height: 82px;
}
.qb-notes-textarea::placeholder {
  color: #505050;
  font-style: italic;
}
.qb-notes-button.qb-notes-button {
  margin: auto;
  display: block;
}
.qb-notes-button.saved {
  border-color: #000;
  background-color: transparent;
}
.qb-notes-button.saved:hover {
  background: #2c2c2c 0% 0% no-repeat padding-box;
  border-color: #000;
}
.qb-notes-button.saved:hover span,
.qb-notes-button.saved:hover svg {
  color: #ffffff;
}
.qb-notes-button.saved:focus {
  background: transparent;
  border-color: #000;
}
.qb-notes-button.saved:focus span,
.qb-notes-button.saved:focus svg {
  color: #000;
}
.qb-notes-button.saved .svg-inline--fa {
  margin-left: 15px;
}
.qb-notes-button.saved span,
.qb-notes-button.saved svg {
  color: #000;
}
.qb-notification-icon {
  color: #000 !important;
  font-size: 30px;
}
.qb-notification .ant-modal-content {
  background: #f9f6f1;
}
.qb-notification .ant-modal-confirm-title {
  color: #000;
  font-size: 16px;
}
.qb-notification .ant-modal-confirm-content {
  color: #000;
  font-size: 14px;
  margin-top: 15px;
}
.qb-notification .ant-modal-confirm-btns .ant-btn {
  padding: 9px 32px 10px;
  font-size: 16px;
  border: 1px solid #ac2939;
  background: #ce2d41;
  height: 36px;
  border-radius: 20px;
}
.qb-notification .ant-modal-confirm-btns .ant-btn:hover {
  background-color: #ac2939;
  border-color: #ac2939;
}
.qb-modal {
  padding-bottom: 0;
  width: calc(100vw - 200px) !important;
  max-width: 1625px;
}
.qb-modal-wrapper {
  overflow-y: auto;
}
.qb-modal-wrapper::-webkit-scrollbar {
  margin-top: 0;
  padding-top: 0;
  width: 6px;
  height: 6px;
  border-radius: 10px;
}
.qb-modal-wrapper::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #ccc5c2;
}
@media (min-width: 992px) {
  .qb-modal-wrapper:hover::-webkit-scrollbar-thumb {
    background: #afacac;
  }
}
.qb-modal .ant-form-item-label > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
      )::before {
  display: none;
}
.qb-modal .intl-tel-input {
  display: flex;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ccc5c2;
  height: 28px;
}
.qb-modal .intl-tel-input .ant-input {
  padding-left: 0 !important;
  border: none !important;
  padding-top: 0;
  padding-bottom: 0;
  height: unset !important;
}
.qb-modal .intl-tel-input .flag-container {
  width: auto !important;
  position: unset !important;
}
.qb-modal .intl-tel-input .flag-container .selected-flag {
  width: max-content !important;
  background-color: unset !important;
}
.qb-modal .intl-tel-input .flag-container .selected-flag .arrow {
  display: none;
}
.qb-modal .intl-tel-input .flag-container .selected-flag .iti-flag {
  background-image: unset;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: unset;
  box-shadow: none;
}
.qb-modal .intl-tel-input .flag-container .selected-flag .selected-dial-code {
  padding-left: 10px !important;
  padding-right: 2px !important;
  font: italic normal normal 11px/18px Montserrat;
}
.qb-modal .intl-tel-input .country-list {
  width: 100%;
  font: normal normal normal 11px/18px Montserrat;
  white-space: unset;
}
.qb-modal .intl-tel-input .country-list .country {
  align-items: center;
  justify-content: left;
  display: flex;
}
.qb-modal .intl-tel-input .country-list .country .flag-box {
  display: none;
}
.qb-modal .intl-tel-input .country-list .country .country-name {
  order: 1;
}
.qb-modal .intl-tel-input .country-list .country .dial-code {
  order: 0;
  width: 46px;
}
.qb-modal .intl-tel-input .country-list::-webkit-scrollbar {
  margin-top: 0;
  padding-top: 0;
  width: 5px;
  height: 8px;
}
.qb-modal .intl-tel-input .country-list::-webkit-scrollbar-thumb:vertical {
  border-radius: 2px;
}
.qb-modal .intl-tel-input .country-list::-webkit-scrollbar-thumb:horizontal {
  border-radius: 2px;
}
.qb-modal-title {
  text-transform: uppercase;
}
.qb-modal-notification {
  max-width: 100%;
}
.qb-modal-notification .ant-modal-close svg {
  font-size: 22px;
}
.qb-modal-notification > .ant-modal-content {
  padding: 48px;
  background: #f9f6f1;
  height: 100%;
  border: 1px solid #a8a8a8;
}
.qb-modal-notification > .ant-modal-content > .ant-modal-body {
  padding: 0;
}
.qb-modal-notification > .ant-modal-content > .ant-modal-body > .ant-row {
  align-items: center;
}
.qb-modal-notification-image {
  width: 100%;
}
.qb-modal-notification-title {
  font: normal normal bold 26px/28px Syne;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  color: #000;
}
.qb-modal-notification-title.mobile {
  display: none;
}
.qb-modal-notification-content {
  font: normal normal normal 14px/28px Montserrat;
  margin: 28px 0;
  color: #000;
}
.qb-modal-request-callback {
  width: 800px !important;
}
.qb-modal-request-callback-image {
  width: 100%;
}
.qb-modal-request-callback-title {
  font: normal normal bold 26px/28px Syne;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  color: #000;
}
.qb-modal-request-callback-title.mobile {
  display: none;
}
.qb-modal-request-callback-content {
  font: normal normal normal 11px/18px Montserrat;
  margin: 20px 0 28px;
  color: #505050;
}
.qb-modal-request-callback > .ant-modal-content > .ant-modal-body {
  padding: 44px 46px;
  overflow: unset;
}
.qb-modal-request-callback > .ant-modal-content > .ant-modal-body > .ant-row {
  align-items: center;
}
.qb-modal-request-callback__form .ant-form-item-explain {
  min-height: unset;
}
.qb-modal-request-callback__form .ant-form-item-explain-error {
  font: italic normal 600 10px/14px Montserrat;
  text-align: center;
}
.qb-modal-request-callback__form .ant-form-item-label > label {
  font: normal normal bold 12px/16px Syne;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #000;
}
.qb-modal-request-callback__form .ant-form-item {
  margin-bottom: 16px;
}
.qb-modal-request-callback__form .ant-form-item-has-error .ant-input {
  border-color: #ff4d4f;
}
.qb-modal-request-callback__form .ant-input {
  border: 1px solid #ccc5c2;
  border-radius: 5px;
  font: italic normal normal 11px/18px Montserrat;
  height: 28px;
}
.qb-modal-request-callback__form .ant-input::placeholder {
  color: #505050;
}
.qb-modal-request-callback__form .ant-form-item-has-error-phone-number .intl-tel-input {
  border-color: #ff4d4f;
}
.qb-modal-request-callback__form-phone-number {
  display: flex;
}
.qb-modal-successful-notification {
  max-width: 100%;
}
.qb-modal-successful-notification--enquiry.qb-modal-successful-notification--enquiry {
  text-align: center;
}
.qb-modal-successful-notification--enquiry-title {
  font: normal normal bold 26px/35px Syne;
  letter-spacing: 1.3px;
  color: #000;
  text-transform: uppercase;
}
.qb-modal-successful-notification--enquiry-title.mobile {
  display: none;
}
.qb-modal-successful-notification--enquiry-content {
  margin: 20px 0 25px;
  font: normal normal normal 14px/28px Montserrat;
  color: #000;
}
.qb-modal-successful-notification--request-callback-title {
  font: normal normal bold 26px/35px Syne;
  letter-spacing: 1.3px;
  color: #000;
  text-transform: uppercase;
}
.qb-modal-successful-notification--request-callback-title.mobile {
  display: none;
}
.qb-modal-successful-notification--request-callback-content {
  font: normal normal normal 11px/18px Montserrat;
  color: #505050;
  margin: 20px 0;
}
.qb-modal-successful-notification-image {
  width: 100%;
}
.qb-modal-successful-notification > .ant-modal-content {
  padding: 48px;
  background: #f9f6f1;
  height: 100%;
  border: 1px solid #a8a8a8;
}
.qb-modal-successful-notification > .ant-modal-content > .ant-modal-body {
  padding: 0;
}
.qb-modal-successful-notification > .ant-modal-content > .ant-modal-body > .ant-row {
  align-items: center;
}
.qb-modal-unlock-price {
  width: 800px !important;
}
.qb-modal-unlock-price-note {
  font: normal normal normal 11px/18px Montserrat;
  color: #505050;
}
.qb-modal-unlock-price-note-link {
  color: #505050;
  font-weight: 600;
}
.qb-modal-unlock-price-note-link:hover {
  color: #505050;
}
.qb-modal-unlock-price__form .ant-form-item-explain {
  min-height: unset;
}
.qb-modal-unlock-price__form .ant-form-item-explain-error {
  font: italic normal 600 10px/18px Montserrat;
  text-align: center;
}
.qb-modal-unlock-price__form-phone-number {
  display: flex;
}
.qb-modal-unlock-price__form .ant-form-item-label {
  padding: 0 0 2px;
}
.qb-modal-unlock-price__form .ant-form-item-label > label {
  font: normal normal bold 12px/16px Syne;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #000;
}
.qb-modal-unlock-price__form .ant-form-item {
  margin-bottom: 14px;
}
.qb-modal-unlock-price__form .ant-form-item.ant-form-item-has-error .ant-input-affix-wrapper,
.qb-modal-unlock-price__form .ant-form-item.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f;
}
.qb-modal-unlock-price__form .ant-form-item.ant-form-item-has-error .intl-tel-input {
  border-color: #ff4d4f;
}
.qb-modal-unlock-price__form .ant-form-item .ant-input-affix-wrapper {
  padding: 0 11px;
  border: 1px solid #ccc5c2;
  border-radius: 5px;
  box-shadow: none;
  height: auto;
}
.qb-modal-unlock-price__form .ant-form-item .ant-input-affix-wrapper .ant-input {
  border: none;
}
.qb-modal-unlock-price__form .ant-form-item .ant-input {
  border: 1px solid #ccc5c2;
  border-radius: 5px;
  font: normal normal normal 11px/18px Montserrat;
  height: 26px;
  color: #505050;
}
.qb-modal-unlock-price__form .ant-form-item .ant-input::placeholder {
  color: #505050;
  font-style: italic;
}
.qb-modal-unlock-price__form .qb-button {
  margin-top: 14px;
}
.qb-modal-unlock-price-title {
  text-align: center;
  font: normal normal bold 26px/35px Syne;
  letter-spacing: 1.3px;
  color: #000;
}
.qb-modal-unlock-price-image {
  width: 100%;
}
.qb-modal-unlock-price > .ant-modal-content > .ant-modal-body {
  padding: 40px 38px;
  overflow: unset;
}
.qb-modal-unlock-price > .ant-modal-content > .ant-modal-body > .ant-row {
  margin: 20px 0;
  align-items: center;
}
.qb-modal.qb-modal-singers {
  width: calc(100vw - 60px) !important;
}
.qb-modal.qb-modal-singers .qb-modal-singers__row {
  max-height: 915px;
  height: calc(100vh - 200px);
  overflow: unset;
}
.qb-modal.qb-modal-singers .qb-modal-singers__row .qb-modal-singers__col {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.qb-modal.qb-modal-singers .qb-modal-singers__row .qb-modal-singers__col .qb-modal-singers-col {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.qb-modal.qb-modal-singers .qb-modal-singers__row .qb-modal-singers__col .qb-modal-singers-col .qb-scroll-has-track-piece {
  margin: 0 -10px 60px;
  padding: 0 5px 0 10px;
}
.qb-modal.qb-modal-singers .qb-modal-singers__row .qb-modal-singers__col .qb-singer-info {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.qb-modal.qb-modal-singers .qb-apply-singer-button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.qb-modal.ceremony-modal {
  width: calc(100vw - 60px) !important;
}
.qb-modal.ceremony-modal .ant-modal-body {
  overflow: hidden;
}
.qb-modal.ceremony-modal .band-modal-content {
  min-height: 500px;
  height: calc(100vh - 150px);
  max-height: 850px;
}
.qb-modal.ceremony-modal .band-modal-content .band-modal-content-ceremony {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.qb-modal.ceremony-modal .band-modal-content .band-modal-content-ceremony .band-modal-content-ceremony-list-wrapper {
  overflow-x: hidden;
}
.qb-modal.reception-modal {
  max-width: 1500px;
}
.qb-modal-title-left {
  display: flex;
  align-items: center;
}
.qb-modal-title-left > * {
  margin-right: 20px;
}
.qb-modal-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.qb-modal-title-right .ant-btn-text {
  display: flex;
  align-items: center;
}
.qb-modal-title-right .ant-btn-text .font-awesome-icon {
  margin-left: 5px;
  font-size: 18px;
}
.qb-modal-content-subtitle.qb-modal-content-subtitle {
  font: normal normal bold 13px/16px Syne;
  color: #000;
  letter-spacing: 0.65px;
  text-transform: uppercase;
}
.qb-modal-content-tabs {
  display: flex;
  flex-direction: column;
}
.qb-modal-content-tabs-title {
  font: normal normal bold 12px/16px Syne;
  color: #000;
  text-transform: uppercase;
}
.qb-modal-content-tabs-buttons .ant-btn {
  font-size: 10px !important;
  text-transform: capitalize;
  padding-left: 0;
  padding-right: 0;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 3px;
  margin-bottom: -3px;
  color: #000 !important;
}
.qb-modal-content-tabs-buttons .ant-btn:first-child {
  margin-left: 0;
}
.qb-modal-content-tabs-buttons .ant-btn:last-child {
  margin-right: 0;
}
.qb-modal-content-tabs-buttons .ant-btn > span:focus {
  outline: none;
}
.qb-modal-content-tabs-buttons .ant-btn.active {
  position: relative;
  color: #373737 !important;
}
.qb-modal-content-tabs-buttons .ant-btn.active::after {
  content: '';
  position: absolute;
  left: 0;
  top: calc(100% - 10px);
  height: 1px;
  width: 100%;
  background: #373737;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.qb-modal-content-tabs-buttons .ant-btn.active:hover {
  color: #373737 !important;
}
.qb-modal-content-tabs-buttons .ant-btn.active:hover::after {
  background: #373737;
}
.qb-modal-singers-list.qb-modal-singers-list {
  margin: 0 -10px;
  padding: 3px 5px 5px 10px;
}
.qb-modal-singers-list.ant-list-split .ant-list-item {
  border-bottom: none;
}
.qb-modal-singers-list-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  transform: translateY(-24px);
}
.qb-modal-singers-list-choose {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: transparent linear-gradient(121deg, #5d5d5d 0, #414141 100%) 0 0 no-repeat padding-box;
}
.qb-modal-singers-list-choose-text {
  text-align: center;
  font: normal normal normal 14px/16px Lato;
  color: #ffffff;
}
.qb-modal-singers-list-choose .qb-modal-singers-music-icon {
  font-size: 35px;
  margin-bottom: 20px;
  color: #000;
}
.qb-modal-singers-list .ant-spin-container {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  gap: 22px;
}
.qb-modal-singers-list .ant-list-item {
  align-items: flex-start;
  padding: 0;
  border: none;
}
.qb-modal-singers-list .ant-list-item.selected {
  box-shadow: 0 0 0 3px #be3c46;
}
.qb-modal-singers-list .ant-list-item.highlighted {
  box-shadow: 0 0 0 3px #ccc5c2;
}
.qb-modal-singers-list .ant-image {
  width: 100%;
}
.qb-modal-singers-list .ant-card {
  height: 100%;
  background: #f9f6f1;
  cursor: pointer;
  width: 100%;
  border: 1px solid #ccc5c2;
}
.qb-modal-singers-list .ant-card-body {
  padding: 10px;
  color: #505050;
  font: normal normal bold 13px/16px Syne;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  letter-spacing: 0.65px;
  justify-content: space-between;
}
.qb-modal-singers-list .ant-card-body::before,
.qb-modal-singers-list .ant-card-body::after {
  display: none;
}
.qb-modal-singers-plus-icon {
  color: #000;
  cursor: pointer;
}
.qb-modal-singers-plus-icon.disabled {
  color: #909090;
}
.qb-modal-location {
  color: #000;
  padding-bottom: 0;
}
.qb-modal-location-content-btns {
  margin: 20px 0 30px;
}
.qb-modal .ant-modal-content {
  background: #f9f6f1;
  height: 100%;
  border: 1px solid #a8a8a8;
}
.qb-modal .ant-modal-body {
  padding: 40px 30px;
  height: auto;
  overflow: auto;
}
.qb-modal .ant-modal-header {
  background: #f9f6f1;
  color: #ffffff;
  padding: 12px 30px;
  border: none;
  border-bottom: 1px solid #a8a8a8;
}
.qb-modal .ant-modal-title {
  color: #000;
  font: normal normal bold 26px/30px Syne;
  top: 0;
  letter-spacing: 1.3px;
}
.qb-modal .ant-btn-text {
  font-size: 13px;
  line-height: 16px;
  font-family: Syne;
  font-weight: 700;
  padding-top: 5px;
  line-height: 1;
  color: #a8a8a8;
  text-transform: uppercase;
}
.qb-modal .ant-btn-text:hover {
  background: transparent;
  color: #bababa;
}
.qb-package-item {
  display: flex;
  padding: 20px;
  background: #f9f6f1;
  margin-bottom: 20px;
  cursor: pointer;
}
@media (max-width: 1199px) {
  .qb-package-item {
    padding: 30px 0;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .qb-package-item:last-child {
    margin-bottom: 0;
  }
}
.qb-package-item_selected {
  outline: 4px solid #707070;
}
.qb-package-item__left {
  width: 60%;
}
@media (max-width: 1199px) {
  .qb-package-item__left {
    width: 100%;
  }
}
.qb-package-item__right {
  width: 40%;
  padding-left: 15px;
  overflow-y: hidden;
  margin-bottom: 10px;
}
@media (max-width: 1199px) {
  .qb-package-item__right {
    width: 100%;
    overflow-y: auto;
    padding: 30px 30px 0;
    margin-bottom: 0;
  }
}
.qb-package-item__name {
  font: normal normal bold 20px/24px syne;
  color: #ffffff;
  text-transform: capitalize;
}
.qb-package-item__moreInfo {
  font: italic normal normal 11px/18px Montserrat;
  color: #e8e8e8;
  margin-top: 10px;
}
.qb-package-item__button {
  display: block;
  margin-top: 30px;
}
.qb-package-item__button .qb-button {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
}
.qb-package-video {
  padding: 20px;
  background: #f9f6f1;
}
.qb-package-video__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.qb-package-video__name {
  font: normal normal bold 26px/28px Lato;
  color: #ffffff;
  text-transform: capitalize;
}
.qb-package-video__buttons {
  margin-top: 4px;
  display: flex;
  align-items: center;
}
.qb-package-video__buttons .qb-city-dropdown-trigger {
  margin-right: 40px;
}
.qb-performance-list__video-outer {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  background: inherit;
}
.qb-performance-list__video-inner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.qb-performance-list__video-img {
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}
.qb-prev-button {
  color: #ffffff;
}
.qb-preference-item {
  margin-bottom: 20px;
}
.qb-preference-item:last-child {
  margin-bottom: 0;
}
.qb-preference-item-wrapper--has-performer {
  border: 1px solid #ccc5c2;
  background-color: #f9f6f1;
}
.qb-preference-item-wrapper.black {
  background: #ffffff;
}
.qb-preference-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
  text-transform: uppercase;
  font: normal normal bold 12px/16px Syne;
  letter-spacing: 0.6px;
  color: #000;
}
.qb-preference-item-header--has-performer {
  border: 1px solid #ccc5c2;
  border-bottom: none;
}
.qb-preference-item-header > svg {
  font-size: 24px;
}
.qb-preference-item-choose {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #343434 0 0 no-repeat padding-box;
}
.qb-preference-item-choose-icon {
  color: #000;
  font-size: 45px;
}
.qb-preference-item-choose .ant-image {
  width: 100%;
}
.qb-preference-item-footer {
  display: flex;
  padding: 20px 12px;
  justify-content: space-between;
  align-items: center;
}
.qb-preference-item-footer-alias {
  font: normal normal bold 12px/16px Syne;
  letter-spacing: 0.6px;
  color: #505050;
  text-transform: uppercase;
}
.qb-preference-item-footer .svg-inline--fa {
  cursor: pointer;
  font-size: 18px;
  color: #909090;
}
.qb-preference-item-surcharge {
  padding: 0 12px 20px;
  margin-top: -10px;
  font: italic normal normal 12px/20px Montserrat;
  color: #505050;
}
.qb-preference-placeholder {
  background: #fff;
  height: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.qb-preference-placeholder__icon {
  font-size: 45px;
  color: #000;
}
.qb-preference-placeholder__text {
  font: normal normal bold 12px/16px Syne;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.3;
  letter-spacing: 0.6px;
}
.qb-price-wrapper {
  font-size: 1.6rem;
  flex: 1;
}
.qb-price-item-wrapper {
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  color: #505050;
  margin: 10px 15px;
}
.qb-price-item .svg-inline--fa {
  display: none;
}
.qb-price-tax-information {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 9px;
  color: #505050;
  margin: 0 15px 20px;
  font-style: italic;
}
.qb-price-total {
  text-transform: capitalize;
}
.qb-price-total-wrapper {
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 600;
  color: #505050;
  border-color: #a8a8a8;
  border-top: 1px solid;
  border-bottom: 1px solid;
  padding: 12px 0;
  margin: 15px 15px 10px;
  text-transform: uppercase;
}
.qb-price-bonus-title {
  font: normal normal bold 20px/24px Lato;
  color: #ffffff;
  text-transform: capitalize;
}
.qb-price-bonus-value {
  margin-top: 10px;
}
.qb-price-bonus-wrapper {
  margin-top: 40px;
}
.qb-price-button-wrapper {
  text-align: center;
  margin: 20px auto 20px;
}
.qb-price-title.qb-price-title {
  margin-bottom: 30px;
}
.qb-price-blur {
  background: #fff;
  border: 1px solid #ccc5c2;
  border-radius: 5px;
}
.qb-price-blur .qb-price-item .qb-price-bluring {
  filter: blur(4px);
}
.qb-price-blur .qb-price-item .qb-price-bluring-wrapper {
  position: relative;
}
.qb-price-blur .qb-price-item .svg-inline--fa {
  position: absolute;
  left: calc(50% - 24px);
  top: calc(50% - 21px);
  font-size: 48px;
  color: #909090;
  display: block;
}
.qb-price-blur .qb-price-item.qb-price-button-wrapper {
  filter: none;
}
.qb-singer-info-card.qb-singer-info-card {
  width: 100%;
  background: transparent;
  color: #909090;
  margin: 24px -10px 0;
  padding: 0 5px 0 10px;
}
.qb-singer-info-card.qb-singer-info-card .ant-card-body:empty {
  background-color: #ffffff;
}
.qb-singer-info-card.qb-singer-info-card .ant-card-body {
  background-color: #f9f6f1;
  padding: 18px 0 24px;
}
.qb-singer-info-card .video-stream-container {
  background: none;
}
.qb-singer-info-card-text {
  margin-top: 15px;
  font: normal normal normal 11px/18px Montserrat;
}
.qb-singer-info-card .testimonial-item {
  padding: 10px 0 0;
  margin: 0;
  background: #f9f6f1;
}
.qb-singer-info-card .testimonial-item-info-des {
  font: italic normal normal 14px/20px Lato;
  padding-right: 20px;
}
.qb-singer-info-card .testimonial-item-info-decor.bottom-decor {
  margin-right: 10px;
}
.qb-singer-info-card .testimonial-item-square {
  margin-top: 5px;
  padding: 10px 0 0 10px;
}
.qb-singer-info-card .testimonial-item-avatar.testimonial-item-avatar.testimonial-item-avatar {
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  padding: 0;
}
.qb-singer-info-card .slick-list {
  margin-left: -7px;
  margin-right: -7px;
  position: relative;
}
.qb-singer-info-card .slick-list::before,
.qb-singer-info-card .slick-list::after {
  width: 7px;
  height: 100%;
  content: '';
  display: block;
  background: #f9f6f1;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.qb-singer-info-card .slick-list::after {
  left: auto;
  right: 0;
}
.qb-singer-info-card .slick-slide {
  padding: 0 5px;
}
.qb-singer-info-card .slick-arrow {
  width: 24px;
  height: 24px;
  background: #b7964e;
}
.qb-singer-info-card .slick-arrow.slick-disabled {
  background: #5d5d5d;
}
.qb-singer-info-card .slick-arrow.slick-disabled::before {
  border-color: #ffffff;
  opacity: 1;
}
.qb-singer-info-card .slick-arrow::before {
  content: '';
  width: 10px;
  height: 10px;
  display: block;
  left: 9px;
  position: absolute;
  top: 7px;
  transform: rotate(45deg);
}
.qb-singer-info-card .slick-prev {
  left: -12px;
  z-index: 1;
}
.qb-singer-info-card .slick-prev::before {
  border-bottom: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
}
.qb-singer-info-card .slick-next {
  right: -12px;
  z-index: 1;
}
.qb-singer-info-card .slick-next::before {
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  left: auto;
  right: 9px;
}
.qb-singer-info-template .ant-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  text-align: center;
  height: 18rem;
}
.qb-singer-info-template__icon {
  font-size: 50px;
  margin-bottom: 40px;
  color: #000;
}
.qb-singer-info-template__title {
  font: normal normal bold 16px/20px Lato;
  color: #000;
}
.qb-singer-info-template__subtitle {
  font: normal normal normal 14px/16px Lato;
  color: #000;
  margin-bottom: 8px;
}
.qb-singer-info-template__text {
  font: normal normal normal 12px/16px Lato;
  color: #bababa;
}
.qb-singer-info-photos .ant-image img {
  width: 100%;
}
.qb-singer-info-title {
  font: normal normal bold 13px/16px Syne;
  color: #000;
  text-transform: capitalize;
}
.qb-singer-info-title-highlight {
  color: #000;
}
.qb-singer-info-title-wrapper {
  display: flex;
  justify-content: space-between;
}
.qb-singer-info-hashtag {
  text-transform: uppercase;
  background: #f9f6f1;
  font: normal normal bold 16px Lato;
  color: #ffffff;
  padding: 5px 10px;
  margin: 18px 0 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.qb-singer-info-add-btn {
  margin-top: 14px;
}
.qb-singer-info-add-btn.un-select {
  background-color: transparent;
  border: 2px solid #be3c46;
  color: #be3c46;
}
.qb-singer-info-choose-text {
  margin-top: 20px;
  font: normal normal normal 14px/16px Lato;
  color: #909090;
}
.qb-singer-info-bio {
  font: normal normal normal 11px/18px Montserrat;
  color: #505050;
  margin-top: 20px;
}
.qb-singer-info-surcharge-wrapper {
  display: flex;
  font: italic normal normal 11px/18px Montserrat;
  color: #505050;
  align-items: flex-end;
}
.qb-singer-info-surcharge-value {
  margin-right: 5px;
}
.qb-singer-info-surcharge-text {
  text-transform: capitalize;
}
.qb-singer-modal-preferences-choose .qb-singer-info-template__title {
  margin-bottom: 15px;
}
.qb-singer-preferences-explainer {
  display: flex;
  background-color: #f9f6f1;
  border: 1px solid #ccc5c2;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 10px;
  margin-bottom: 20px;
  text-align: center;
}
.qb-singer-preferences-explainer__icon {
  font-size: 50px;
  color: #000;
}
.qb-singer-preferences-explainer__title {
  font: normal normal bold 16px/20px Syne;
  color: black;
  text-transform: capitalize;
  margin-top: 40px;
}
.qb-singer-preferences-explainer__subtitle {
  font: normal normal normal 14px/16px Syne;
  color: #000;
  margin-top: 14px;
}
.qb-singer-preferences-explainer__text {
  font: normal normal normal 12px/16px Montserrat;
  color: #bababa;
  margin-top: 8px;
}
.qb-singer-preferences-minified {
  list-style: none;
  padding: 0;
}
.qb-singer-preferences-minified__wrapper {
  border: 1px solid #ccc5c2;
  margin-top: 12px;
  background-color: #fff;
  font: italic normal normal 11px/18px Montserrat;
  border-radius: 5px;
  overflow: hidden;
}
.qb-singer-preferences-minified__wrapper:hover {
  border-color: #a8a8a8;
}
.qb-singer-preferences-minified__wrapper .qb-preference-item {
  border: none;
}
.qb-singer-preferences-minified__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px;
  background-color: #fff;
  margin-bottom: 10px;
}
.qb-singer-preferences-minified__item:last-child {
  margin-bottom: 0;
}
.qb-singer-preferences-minified__item_left {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  max-width: calc(100% - 20px);
}
.qb-singer-preferences-minified__button_add {
  text-align: start;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  color: #505050;
}
.qb-singer-preferences-minified__button_add > span {
  font: italic normal normal 11px/28px Montserrat;
  color: #505050;
}
.qb-singer-preferences-minified__button_add svg {
  font-size: 14px;
}
.qb-singer-preferences-minified__button_add-more {
  text-align: start;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  color: #505050;
}
.qb-singer-preferences-minified__button_add-more span {
  font: normal normal 600 11px/28px Montserrat;
  color: #505050;
}
.qb-singer-preferences-minified__button_add-more svg {
  font-size: 14px;
  shape-rendering: auto !important;
}
.qb-singer-preferences-minified__icon_grip-lines {
  margin-right: 16px;
  color: #000;
}
.qb-singer-preferences-minified__icon_plus {
  margin-right: 8px;
}
.qb-singer-preferences-minified .ordinal-index {
  margin-right: 5px;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 11px;
  line-height: 1.2;
  font-style: normal;
  color: #505050;
}
.qb-singer-preferences-minified .alias {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 11px;
  line-height: 1.2;
  font-style: normal;
  color: #505050;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.qb-singer-preferences-minified .qb-modal-singers__icon_plus {
  color: #bababa;
  cursor: pointer;
  font-size: 14px;
}
.qb-singer-preferences-minified .qb-modal-singers__icon_plus:active {
  color: #ffffff;
}
.qb-summary-section {
  counter-reset: section-title-counter;
}
.qb-summary-section .qb-details-section-title {
  text-align: center;
}
.qb-summary-section .qb-price-item > .qb-price-bluring-wrapper {
  background-color: #ffffff;
  border: 1px solid #ccc5c2;
  border-radius: 5px;
}
.qb-summary-title {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 18px;
}
.qb-summary-title::before {
  counter-increment: section-title-counter;
  content: counter(section-title-counter) '. ';
}
.qb-summary-subtitle {
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  margin-left: 18px;
}
.qb-summary-text {
  margin-left: 18px;
  font-size: 16px;
}
.qb-summary__qb-singer-preferences-minified .qb-preference-item {
  margin-bottom: 0;
}
.qb-telephone-city-wrapper {
  display: flex;
}
.band-modal-header {
  background: #f9f6f1;
  padding: 30px;
}
.band-modal-header-title {
  font: normal normal normal 26px/28px Lato;
  margin-right: 40px;
  float: left;
}
.band-modal-close {
  float: right;
}
.band-modal-content.qb-scroll {
  display: flex;
}
.band-modal-content-ceremony-group {
  background: #f9f6f1;
  padding-top: 50px;
}
.band-modal-content-ceremony-group-item {
  list-style-type: none;
  margin: 0 25px;
}
.band-modal-content-ceremony-group-item__wrapper {
  background: #f9f6f1;
  padding: 10px 8px;
  border: 1px solid #ccc5c2;
  border-bottom: 3px solid #707070;
  position: relative;
  cursor: pointer;
}
.band-modal-content-ceremony-group-item__name {
  padding: 20px 0 5px;
  text-align: center;
  font: normal normal bold 18px/18px Syne;
  color: #a8a8a8;
  text-transform: uppercase;
  letter-spacing: 1.3px;
}
.band-modal-content-ceremony-group-item__name .font-awesome-icon {
  display: none;
}
.band-modal-content-ceremony-group-item__name .font-awesome-icon.active-icon {
  margin-left: 5px;
}
.band-modal-content-ceremony-group-item__button {
  display: none;
}
.band-modal-content-ceremony-group-item.has-current .band-modal-content-ceremony-group-item__name {
  color: #000;
}
.band-modal-content-ceremony-group-item.has-current .band-modal-content-ceremony-group-item__name .font-awesome-icon.active-icon {
  display: inline-block;
}
.band-modal-content-ceremony-group-wrapper.desktop {
  position: relative;
}
.band-modal-content-ceremony-group-wrapper.desktop .band-modal-content-ceremony-group {
  width: 82%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}
.band-modal-content-ceremony-group-wrapper.desktop .band-modal-content-ceremony-group-item__wrapper {
  height: 100%;
}
.band-modal-content-ceremony-group-wrapper.desktop .band-modal-content-ceremony-group-item__thumb {
  width: 100%;
  height: auto;
  position: relative;
}
.band-modal-content-ceremony-group-wrapper.desktop .band-modal-content-ceremony-group-item__thumb img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  transform: translate(-50%, -50%);
}
.band-modal-content-ceremony-group-wrapper.desktop .band-modal-content-ceremony-group-item__thumb.no-cat {
  padding-top: 52.9%;
}
.band-modal-content-ceremony-group-wrapper.desktop .band-modal-content-ceremony-group-item__thumb.no-cat > .band-modal-content-ceremony-group-item__thumb {
  opacity: 0;
}
.band-modal-content-ceremony-group-wrapper.desktop .band-modal-content-ceremony-group-item.has-current .band-modal-content-ceremony-group-item__wrapper {
  border: 3px solid #707070;
  border-bottom: none;
  z-index: 2;
}
.band-modal-content-ceremony-group.mobile {
  display: none;
}
.band-modal-content-ceremony-group::before {
  content: '';
  height: 3px;
  width: 100%;
  background: #707070;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.band-modal-content-ceremony-list {
  padding: 0 15px;
  justify-content: center;
  height: 100%;
  display: none;
}
.band-modal-content-ceremony-list-wrapper {
  background: #f9f6f1;
  margin: 50px 10px 20px;
  position: relative;
  flex: 1;
}
.band-modal-content-ceremony-list-wrapper .no-cat__title {
  color: #000;
  font: normal normal bold 26px/35px Syne;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}
.band-modal-content-ceremony-list-item {
  background: #f9f6f1;
  padding-bottom: 20px;
  border: 1px solid #ccc5c2;
}
.band-modal-content-ceremony-list-item__header {
  display: flex;
  flex-wrap: wrap;
}
.band-modal-content-ceremony-list-item__video {
  margin-bottom: 10px;
}
.band-modal-content-ceremony-list-item__video .video-stream-container {
  background: none;
}
.band-modal-content-ceremony-list-item__title {
  font: normal normal bold 13px/16px Syne;
  margin-top: 5px;
  color: #000;
  padding: 0 10px;
  height: 34px;
  text-transform: uppercase;
}
.band-modal-content-ceremony-list-item__description {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin: 0 10px 20px;
  height: 100px;
  overflow-y: auto;
}
.band-modal-content-ceremony-list-item__price {
  display: flex;
  flex-direction: column;
}
.band-modal-content-ceremony-list-item__price.mobile {
  display: none;
}
.band-modal-content-ceremony-list-item__price-text {
  font: normal normal bold 12px/16px Syne;
  letter-spacing: 0.6px;
  color: #000;
  text-transform: uppercase;
  padding-right: 5px;
}
.band-modal-content-ceremony-list-item__price-number {
  font: normal normal bold 12px/16px Syne;
  color: #000;
}
.band-modal-content-ceremony-list-item__price-line {
  padding: 0 10px;
}
.band-modal-content-ceremony-list-item__button {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: flex-end;
}
.band-modal-content-ceremony-list-item__button button.selected {
  background-color: transparent;
}
.band-modal-content-ceremony-list-item__button .selected:hover {
  color: white;
}
.band-modal-content-ceremony-list.active {
  display: flex;
  z-index: 10;
  opacity: 1;
}
.band-modal-content-no-cat {
  margin: 0 auto;
  padding: 40px 0 80px;
  background-color: #f9f6f1 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.band-modal-content-no-cat__icon {
  margin: 0 auto 50px;
}
.band-modal-content-no-cat__title {
  margin-bottom: 15px;
}
.band-modal-content-no-cat__des {
  width: 500px;
  max-width: calc(100% - 20px);
  margin: 14px auto 20px;
  color: #5d5d5d;
  font: normal normal normal 14px/28px Montserrat;
}
.band-modal .ant-modal-body {
  padding: 0;
  overflow: auto;
}
.testimonial {
  background: #f9f6f1;
  padding: 0 10px 40px;
}
.testimonial .ant-carousel .slick-slider {
  z-index: 123;
}
.testimonial .ant-carousel .slick-slider .slick-prev,
.testimonial .ant-carousel .slick-slider .slick-next {
  width: 24px;
  height: 24px;
  z-index: 10;
  background: #b39659;
}
.testimonial .ant-carousel .slick-slider .slick-prev::before,
.testimonial .ant-carousel .slick-slider .slick-next::before {
  color: #ffffff;
  font-size: 24px;
  position: absolute;
  top: -4px;
  left: 6px;
}
.testimonial .ant-carousel .slick-slider .slick-prev.slick-disabled,
.testimonial .ant-carousel .slick-slider .slick-next.slick-disabled {
  background: #5d5d5d;
}
.testimonial .ant-carousel .slick-slider .slick-prev.slick-disabled::before,
.testimonial .ant-carousel .slick-slider .slick-next.slick-disabled::before {
  opacity: 1;
}
.testimonial .swiper-testimonials-arrow {
  position: absolute;
  width: 24px;
  height: 24px;
  background: #b39659;
  color: #ffffff;
  transform: translate(0, -50%);
  z-index: 10;
  top: 50%;
  padding: 4px 0 0 8px;
}
.testimonial .swiper-testimonials-arrow.swiper-button-disabled {
  background: #5d5d5d;
}
.testimonial .swiper-testimonials-arrow.swiper-arrow-next {
  left: auto;
  right: 0;
}
.testimonial .swiper-testimonials-arrow .svg-inline--fa {
  display: block;
  vertical-align: unset;
}
.testimonial-title {
  font: bold 26px/28px Lato;
  color: #ffffff;
  margin-bottom: 10px;
  padding: 0 10px;
}
.testimonial-item {
  padding: 20px 20px 5px;
  background: #f9f6f1;
  margin: 0 10px;
}
.testimonial-item-col-square {
  display: flex;
  align-items: center;
}
.testimonial-item-square {
  width: 100%;
  position: relative;
}
.testimonial-item-square::after {
  content: '';
  display: block;
  padding-bottom: 100%;
}
.testimonial-item img {
  width: 100%;
  height: 100%;
}
.testimonial-item-avatar {
  border-radius: 50%;
  position: absolute;
}
.testimonial-item-info-decor {
  font-size: 40px;
  color: #b39659;
  font-weight: bold;
  line-height: 13px;
  display: block;
  margin: 13px 0 -13px;
}
.testimonial-item-info-decor.bottom-decor {
  float: right;
  margin-bottom: -8px;
}
.testimonial-item-info-des {
  font: italic 20px/27px Lato;
  color: #ffffff;
  padding: 5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.testimonial-item-info-name {
  clear: both;
  color: #bababa;
  font: normal 14px/14px Lato;
}
.end-same-line {
  display: flex;
  justify-content: flex-end;
}
.swiper-controller {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 40px;
  width: 75px;
  z-index: 10;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.swiper-controller .swiper-pagination {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  max-height: 375px;
}
.swiper-controller .swiper-pagination .swiper-pagination-bullet {
  background: #ccc5c2;
  margin-bottom: 1rem;
  opacity: 1;
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  cursor: pointer;
}
.swiper-controller .swiper-pagination .swiper-pagination-bullet.current:not(.swiper-pagination-bullet-active) {
  border: 1px solid #b39659;
  width: 12px;
  height: 12px;
  padding: 1px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}
.swiper-controller .swiper-pagination .swiper-pagination-bullet.current:not(.swiper-pagination-bullet-active)::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border: 1px solid black;
  content: '';
  border-radius: 50%;
}
.swiper-controller .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #ce2d41;
  border-radius: 50%;
  border: 4px solid rgba(179, 150, 89, 0.17);
  box-sizing: content-box;
  background-clip: padding-box;
  width: 12px;
  height: 12px;
}
.swiper-controller .swiper-arrow {
  color: #000;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  align-items: center;
  cursor: pointer;
  margin-bottom: 35px;
  text-align: center;
  user-select: none;
}
.swiper-controller .swiper-arrow-bottom,
.swiper-controller .swiper-arrow-top {
  font: normal normal bold 12px/16px Syne;
}
.swiper-controller .swiper-arrow svg {
  color: #ccc5c2;
}
.swiper-controller .swiper-arrow.swiper-button-disabled {
  color: #373737;
  cursor: default;
}
.swiper-controller .swiper-arrow .svg-inline--fa {
  margin-bottom: 10px;
}
.swiper-controller .swiper-arrow.swiper-arrow-prev {
  margin-top: 35px;
  margin-bottom: 0;
}
.swiper-controller .swiper-arrow .fa-chevron-right,
.swiper-controller .swiper-arrow .fa-chevron-down {
  margin-bottom: 0;
  margin-top: 10px;
}
.swiper-controller .swiper-arrow .fa-chevron-left,
.swiper-controller .swiper-arrow .fa-chevron-right {
  display: none;
}
.swiper-controller .swiper-arrow-left,
.swiper-controller .swiper-arrow-right {
  display: none;
}
.swiper-controller .swiper-arrow-top,
.swiper-controller .swiper-arrow-bottom {
  display: block;
}
.reception-modal-content {
  position: relative;
  height: calc(100vh - 140px);
  min-height: 650px;
  max-height: 780px;
  padding: 30px 0;
  overflow: hidden;
}
.reception-modal-content > .swiper {
  width: calc(100% - 155px);
  height: 100%;
  margin-left: 30px;
  overflow: hidden;
}
.reception-modal-content .swiper-slide .reception-modal-item {
  top: 50%;
  bottom: unset;
}
.reception-modal-content .swiper-slide-active {
  z-index: 1;
}
.reception-modal-content .swiper-slide-active.swiper-slide .reception-modal-item {
  max-width: 100%;
  transform: translateY(-50%);
  top: 50%;
  z-index: 1;
}
.reception-modal-content .swiper-slide-active.swiper-slide .reception-modal-item.expanded {
  height: auto;
}
.reception-modal-content .swiper-slide-active.swiper-slide .reception-modal-item::before,
.reception-modal-content .swiper-slide-active.swiper-slide .reception-modal-item::after {
  content: '';
  position: absolute;
  width: 90%;
  height: 30%;
  background: transparent linear-gradient(0deg, #000 -90%, rgba(242, 242, 242, 0) 30%) 0 0 no-repeat padding-box;
  top: -30%;
  left: 0;
  right: 0;
  margin: -1px auto;
}
.reception-modal-content .swiper-slide-active.swiper-slide .reception-modal-item::after {
  transform: matrix(-1, 0, 0, -1, 0, 0);
  bottom: -30%;
  top: auto;
}
.reception-modal-content .swiper-slide-active.swiper-slide:first-child ::before {
  display: none;
}
.reception-modal-content .swiper-slide-active.swiper-slide:last-child ::after {
  display: none;
}
.reception-modal-content .swiper-slide-active ~ .swiper-slide .reception-modal-item {
  top: -50%;
  height: 100%;
  overflow: hidden;
}
.reception-modal-content .swiper-arrow {
  line-height: 1.2;
}
.reception-modal-item {
  border: 1px solid #ccc5c2;
  background: #f9f6f1;
  padding: 30px;
  display: flex;
  position: relative;
  max-width: 90%;
  margin: 0 auto;
}
.reception-modal-item-video {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
}
.reception-modal-item-video-wrapper {
  width: calc((2 / 3) * 100%);
  position: relative;
  margin-right: 30px;
}
.reception-modal-item-video-wrapper .dVjtWD {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.reception-modal-item-video-placeholder {
  padding-top: 56%;
  display: block;
  width: 100%;
  position: relative;
}
.reception-modal-item-video-placeholder span {
  display: none;
}
.reception-modal-item .control-video-slider {
  display: none;
}
.reception-modal-item .video-list-swiper {
  display: none;
  margin: 40px 0 10px;
}
.reception-modal-item .video-list-swiper .swiper-video-arrow {
  display: none;
}
.reception-modal-item-main-img {
  display: block;
  width: 100%;
  position: absolute;
}
.reception-modal-item-title {
  font: normal normal bold 16px/16px Syne;
  letter-spacing: 0.8px;
  color: #000;
  padding-right: 5px;
  text-transform: uppercase;
  margin-top: 7px;
}
.reception-modal-item-price {
  font: normal normal bold 12px/16px Syne;
  color: #000;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.reception-modal-item-price-number {
  color: #000;
  margin-top: 8px;
  margin-left: 5px;
}
.reception-modal-item-detail {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  width: calc((1 / 3) * 100%);
  height: calc(100% - 60px);
  margin: 30px 0;
  padding: 0 30px;
}
.reception-modal-item-detail-scroll {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 30px;
}
.reception-modal-item-detail-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.reception-modal-item-des {
  font: normal normal normal 14px/20px Montserrat;
  height: 100%;
  overflow: hidden;
}
.reception-modal-item-des p {
  margin-bottom: 20px;
  font: normal normal normal 14px/20px Montserrat;
  color: #000;
}
.reception-modal-item-des strong {
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1.12px;
}
.reception-modal-item-show-more-box {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 132px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #000;
  text-transform: uppercase;
  font: normal normal bold 12px/16px Syne;
  letter-spacing: 0.6px;
  line-height: 18px;
  cursor: pointer;
}
.reception-modal-item-show-more-box svg {
  color: #ccc5c2;
  margin-left: 10px;
}
.reception-modal-item-show-more-box.show-more {
  display: flex;
}
.reception-modal-item-show-more-box-button {
  display: flex;
  align-items: center;
}
.reception-modal-item-show-more-box.show-less {
  display: none;
}
.reception-modal-item .testimonial {
  display: none;
}
.reception-modal-item.expanded .video-list-swiper {
  display: block;
}
.reception-modal-item.expanded .video-list-swiper:hover .swiper-video-arrow {
  display: flex;
}
.reception-modal-item.expanded .reception-modal-item-video-placeholder .control-video-slider {
  display: flex;
}
.reception-modal-item.expanded .testimonial {
  background: #f9f6f1;
  display: block;
  padding: 30px 0 0;
  margin: 0 -10px;
}
.reception-modal-item.expanded .testimonial-title {
  display: none;
}
.reception-modal-item.expanded .testimonial-item {
  background: #f9f6f1;
}
.reception-modal-item.expanded .reception-modal-item-show-more-box.show-more {
  display: none;
}
.reception-modal-item.expanded .reception-modal-item-show-more-box.show-less {
  display: flex;
  height: 30px;
}
.reception-modal-item.expanded .reception-modal-item-video-list {
  display: flex;
}
.reception-modal-item.expanded .reception-modal-item-des {
  overflow-y: auto;
}
.reception-modal-item .band-modal-content-no-cat {
  min-height: 546px;
  width: 100%;
  background: #f9f6f1;
  padding: 2% 0;
}
.reception-modal-item .band-modal-content-no-cat__centering.mobile {
  display: none;
}
.reception-modal-item .band-modal-content-no-cat__des {
  font: normal normal normal 14px/28px Montserrat;
  color: #000;
}
.detail-modal {
  height: auto;
  font: normal 18px/23px Lato;
}
.detail-modal .ant-modal-close {
  top: 30px;
  right: 30px;
}
.detail-modal .ant-modal-close-icon {
  color: #262626;
}
.detail-modal .ant-modal-close:hover .ant-modal-close-icon {
  color: #bababa;
}
.detail-modal .ant-modal-body {
  padding: 0;
}
.detail-modal .ant-modal-content {
  background: #f9f6f1;
}
.detail-modal-banner {
  position: relative;
  height: 100%;
}
.detail-modal-banner-wrapper {
  display: flex;
  flex-direction: column;
}
.detail-modal-banner img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.detail-modal-banner img.desktop {
  display: block;
}
.detail-modal-banner img.mobile {
  display: none;
}
.detail-modal-banner-quote {
  position: absolute;
  width: 405px;
  height: 175px;
  bottom: 85px;
  padding: 32px 148px 32px 17px;
  display: none;
}
.detail-modal-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.detail-modal-form-title {
  color: #000;
  margin-bottom: 20px;
  font: normal normal normal 18px/22px Lato;
  text-transform: uppercase;
}
.detail-modal-form-des {
  color: #000;
  margin-bottom: 30px;
  font: normal 14px/23px Lato;
}
.detail-modal .ant-form {
  padding: 0 17%;
}
.detail-modal .ant-form-item {
  margin-bottom: 20px;
}
.detail-modal .ant-form-item.form-button-item {
  margin-bottom: 0;
}
.detail-modal .ant-form-item-label > label {
  color: #bababa;
  text-transform: uppercase;
  font: normal normal normal 14px/14px Lato;
}
.detail-modal .ant-form-item-label > label.ant-form-item-required:not(
              .ant-form-item-required-mark-optional
            )::before {
  color: #ce2d41;
  right: 0;
}
.detail-modal .ant-form-item .ant-input {
  background: #f9f6f1;
  border: 1px solid #a8a8a8;
  font: italic normal normal 11px/18px Montserrat;
  color: #000;
  padding: 8px 10px;
}
.detail-modal .ant-form-item .ant-input::placeholder {
  color: #bababa;
}
.detail-modal .ant-form-item .ant-input-affix-wrapper {
  background: none;
  border: 1px solid #a8a8a8;
  padding: 0;
}
.detail-modal .ant-form-item .ant-input-affix-wrapper .ant-input {
  border: none;
  font: italic normal normal 11px/18px Montserrat;
  color: #000;
}
.detail-modal .ant-form-item .ant-input-affix-wrapper .ant-input-suffix {
  margin: 0;
  padding: 0 15px;
}
.detail-modal .ant-form-item .ant-input-affix-wrapper:focus,
.detail-modal .ant-form-item .ant-input-affix-wrapper::selection {
  box-shadow: none;
}
.detail-modal .ant-form-item .ant-select-selection-placeholder {
  color: #bababa;
  font: italic normal normal 11px/18px Montserrat;
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.detail-modal .ant-form-item-has-error .ant-input,
.detail-modal .ant-form-item-has-error .ant-input-affix-wrapper,
.detail-modal .ant-form-item-has-error .ant-input:hover,
.detail-modal .ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background: none;
}
.detail-modal .ant-form-item input:-webkit-autofill,
.detail-modal .ant-form-item input:-webkit-autofill:hover,
.detail-modal .ant-form-item input:-webkit-autofill:focus,
.detail-modal .ant-form-item textarea:-webkit-autofill,
.detail-modal .ant-form-item textarea:-webkit-autofill:hover,
.detail-modal .ant-form-item textarea:-webkit-autofill:focus,
.detail-modal .ant-form-item select:-webkit-autofill,
.detail-modal .ant-form-item select:-webkit-autofill:hover,
.detail-modal .ant-form-item select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
.detail-modal .ant-form-item .qb-button {
  margin: 10px auto 0;
  width: 214px;
  display: flex;
  justify-content: center;
  padding-top: 11px;
  padding-bottom: 9px;
}
.detail-modal.unlock-price-modal {
  height: auto;
  padding-bottom: 0;
}
.detail-modal.unlock-price-modal .ant-modal-content {
  background: #262626;
}
.detail-modal .ant-btn-primary[disabled],
.detail-modal .ant-btn-primary[disabled]:hover,
.detail-modal .ant-btn-primary[disabled]:focus,
.detail-modal .ant-btn-primary[disabled]:active {
  color: #5d5d5d;
  background-color: transparent;
  border-color: #5d5d5d;
}
.ant-modal-root .ant-modal-mask,
.ant-modal-root .ant-image-preview-mask {
  background-color: rgba(57, 57, 57, 0.72);
  backdrop-filter: blur(3px);
}
.blur-item {
  filter: blur(5px);
  user-select: none;
}
.no-cat {
  text-align: center;
  display: block;
  background-color: #cdc5c2;
}
.no-cat__wrapper {
  height: 100%;
  background-color: #cdc5c2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-cat__title {
  font: normal normal bold 26px/35px Syne;
  letter-spacing: 1.3px;
  color: #000;
  text-transform: uppercase;
  margin: 8px 0;
}
.no-cat__icon {
  display: block;
  position: relative;
  margin: 0 auto;
}
.no-cat__icon img {
  width: 60px;
  margin: 0 auto;
}
@media only screen and (max-height: 650px) and (min-width: 1200px) {
  .qb-modal.qd-customize-small-size-popup {
    padding-bottom: 0;
    min-height: 100%;
  }
  .qb-modal-singers-list.qb-modal-singers-list {
    max-height: calc(100vh - 190px);
  }
  .qb-singer-info-card.qb-singer-info-card {
    max-height: calc(100vh - 200px);
  }
  .ant-modal-wrap {
    height: 100%;
  }
  .ant-modal-body {
    max-height: 100% !important;
  }
}
.ant-form-item-has-error .qb-callback__mobile-wrapper .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background: #f9f6f1;
}
.video-stream-container {
  height: 100%;
}
.video-stream-container > div {
  height: 100%;
}
.video-stream-container stream {
  height: 100%;
}
.unlock-price-modal .detail-modal-form-wrapper {
  background: #f9f6f1;
}
.unlock-price-modal .detail-modal-form-wrapper .ant-input-affix-wrapper-focused {
  box-shadow: none;
}
.qb-details-outer-wrapper .ant-btn-link {
  color: #000;
}
.qb-details-outer-wrapper ::selection {
  background: #ce2d41 !important;
}
.qb-details-event-date .ant-picker-input > input::placeholder {
  color: #505050;
}
.qb-price-blur .qb-price-button-wrapper {
  margin: 50px auto 75px;
}
.qb-details-section-item-price-number {
  font: italic normal normal 13px/18px Montserrat;
  display: block;
}
.reception-modal-content {
  font-family: Syne;
  flex: 1;
}
.qb-modal-singers-list-header .ant-btn-text:not(.active) {
  color: #909090 !important;
}
.footer-container.qb-details-inner-wrapper {
  font: normal normal normal 14px/18px Montserrat;
}
.qb-details-inner-wrapper .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.qb-details-inner-wrapper .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.qb-details-inner-wrapper .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #ce2d41;
}
#qb-package-details-preferences > div {
  background: transparent !important;
}
#qb-singer-modal-preferences > div {
  background: transparent !important;
}
.qb-singer-preferences-minified.qb-summary__qb-singer-preferences-minified {
  background: transparent !important;
}
.qb-page .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.submit-inquiry-form .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.unlock-price-modal .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: red !important;
}
.qb-page ::selection,
.submit-inquiry-form ::selection,
.unlock-price-modal ::selection {
  background: #ce2d41 !important;
}
.qb-page .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.submit-inquiry-form .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.unlock-price-modal .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #ce2d41;
  color: #ffffff;
}
.qb-page .ant-modal.ant-modal-confirm .ant-modal-body {
  background-color: #f9f6f1;
}
.qb-page .ant-modal.ant-modal-confirm .ant-modal-confirm-body > .anticon {
  color: #ce2d41;
}
.qb-page .ant-modal.ant-modal-confirm .ant-btn-primary {
  color: #ffffff;
  background: #ce2d41;
  background-color: #ce2d41;
  padding: 11px 30px 8px;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  font-family: 'Josefin Sans', sans-serif;
  text-transform: uppercase;
  border: 1px solid #ce2d41;
  border-radius: 20px;
  height: 36px;
}
.qb-page .ant-modal.ant-modal-confirm .ant-btn-primary:hover,
.qb-page .ant-modal.ant-modal-confirm .ant-btn-primary:focus {
  background: #ce2d41;
  background-color: #ce2d41;
}
.qb-page div::-moz-selection,
.qb-page p::-moz-selection {
  /* Code for Firefox */
  color: #ffffff;
  background: #ce2d41;
}
.qb-page div::selection,
.qb-page p::selection {
  color: #ffffff;
  background: #ce2d41;
}
.qb-page .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    ) .ant-select-selector {
  border: 1px solid #ce2d41;
  outline: 0;
  box-shadow: 0 0 2px #ce2d41;
}
.quote-loading-wrapper .ant-spin {
  color: #ce2d41;
}
.quote-loading-wrapper .ant-spin-dot-item {
  background-color: #ce2d41;
}
.video-list-swiper {
  display: block;
  position: relative;
  margin: 10px 0;
  overflow: hidden;
}
.video-list-swiper-thumb {
  cursor: pointer;
  width: 100%;
}
.video-list-swiper-thumb img {
  display: block;
  width: 100%;
  max-height: 100%;
}
.video-list-swiper-thumb-wrap {
  position: relative;
  width: 100%;
}
.video-list-swiper-thumb.active .video-list-swiper-thumb-wrap::after {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid #ccc5c2;
  content: '';
  top: 0;
}
.video-list-swiper .swiper-video-arrow {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  padding: 10px;
  background: #ffffff;
  color: #ccc5c2;
  border: none;
  outline: none;
  transition: all 0.3s;
}
.video-list-swiper .swiper-video-arrow.swiper-button-disabled {
  opacity: 0.6;
  cursor: default;
}
.video-list-swiper .swiper-video-arrow.swiper-button-disabled:hover {
  background: #ffffff;
}
.video-list-swiper .swiper-video-arrow-next {
  right: 0;
}
.video-list-swiper:hover .swiper-video-arrow {
  display: flex;
}
.control-video-slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  padding: 12px 4px;
  background: #ffffff;
  align-items: center;
  justify-content: center;
  color: #ccc5c2;
  border: none;
  outline: none;
  transition: all 0.3s;
}
.control-video-slider-arrow.swiper-button-disabled {
  opacity: 0.6;
  cursor: default;
}
.control-video-slider-arrow.swiper-button-disabled:hover {
  background: #ffffff;
}
.control-video-slider-arrow-next {
  right: 0;
}
.singer-modal-container {
  overflow: unset;
}
@media (max-width: 1599px) {
  .qb-callback-text {
    font-size: 15px;
  }
}
@media (max-width: 1199px) {
  .qb-button {
    font-size: 14px;
    padding: 0 26px;
  }
  .qb-button-change {
    width: 100%;
  }
  .qb-button.saved {
    border: thin solid #000;
    padding: 0 20px;
  }
  .qb-button.saved .svg-inline--fa {
    margin-top: -3px;
    margin-left: 15px;
  }
  .qb-callback-outer-wrapper {
    padding-top: 0;
  }
  .qb-callback-outer-wrapper .qb-details-inner-wrapper {
    width: auto;
    border: none;
    padding: 0;
    margin: 30px 24px;
  }
  .qb-details-title {
    margin-top: -15px;
    font-size: 39px;
  }
  .qb-details-gigs-head .qb-button {
    margin: 25px 0 10px;
  }
  .qb-details-outer-wrapper {
    display: flex;
    flex-direction: column;
  }
  .qb-details-outer-wrapper .ant-tabs-top > .ant-tabs-nav,
  .qb-details-outer-wrapper .ant-tabs-bottom > .ant-tabs-nav {
    margin-top: 0;
    position: relative;
  }
  .qb-details-outer-wrapper .ant-tabs-top > .ant-tabs-nav::before,
  .qb-details-outer-wrapper .ant-tabs-bottom > .ant-tabs-nav::before {
    display: block;
    bottom: 100%;
    border-width: 2px;
    border-color: #ccc5c2;
  }
  .qb-details-outer-wrapper .footer-container {
    width: auto;
    margin: 0 24px;
    padding: 18px 0;
  }
  .qb-details-outer-wrapper .footer-container .terms-conditions-link {
    font-family: Montserrat;
    font-size: 11px;
    line-height: 14px;
    color: #909090;
  }
  .qb-details-outer-wrapper .rk-default-footer-row {
    flex-direction: column;
  }
  .qb-details-outer-wrapper .footer-social_links,
  .qb-details-outer-wrapper .footer-list,
  .qb-details-outer-wrapper .footer-separator {
    display: none;
  }
  .qb-details-outer-wrapper .footer-copyright {
    font-size: 11px;
    color: #909090;
    line-height: 1;
  }
  .qb-details-inner-wrapper.mobile {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
  }
  .qb-details-inner-wrapper.mobile .qb-details-event-date-section {
    margin-bottom: 30px;
    padding-top: 10px;
  }
  .qb-details-inner-wrapper.mobile .ant-tabs {
    flex-grow: 1;
    background: #f9f6f1;
  }
  .qb-details-inner-wrapper.mobile .ant-tabs-nav {
    background: #f9f6f1;
    position: relative;
  }
  .qb-details-inner-wrapper.mobile .ant-tabs.fixed_tabbar {
    padding-bottom: 49px;
  }
  .qb-details-inner-wrapper.mobile .ant-tabs.fixed_tabbar .ant-tabs-nav {
    position: fixed;
    bottom: 0;
    top: auto;
    width: 100%;
    max-width: 768px;
    z-index: 999;
  }
  .qb-details-inner-wrapper.mobile .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 0;
  }
  .qb-details-section {
    padding-bottom: 20px;
    margin: 0 22px 25px;
    width: auto;
  }
  .qb-details-section .qb-singer-preferences-top {
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: center;
  }
  .qb-details-section .qb-singer-preferences-top .qb-button {
    width: max-content;
    margin: 0;
  }
  .qb-details-section .qb-singer-list {
    max-height: unset;
  }
  .qb-details-section .qb-preference-items .qb-preference-placeholder__text {
    font: normal normal bold 12px/17px Syne;
    letter-spacing: 0.6px;
    margin-top: 6px;
  }
  .qb-details-section-title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
  }
  .qb-details-section-title.qb-price-title {
    margin-bottom: 10px;
  }
  .qb-details-section-title.qb-details-question {
    margin-bottom: 10px;
  }
  .qb-details-section-item-title {
    font-size: 20px;
  }
  .qb-details-section-item-price-text {
    display: block;
    font-size: 12px;
  }
  .qb-details-section-item-price-number {
    font: normal 16px/18px Lato;
  }
  .qb-details-section-item-location-kind {
    font-size: 12px;
  }
  .qb-details-section-item-location-name {
    font: normal 14px/16px Lato;
  }
  .qb-details-section-item-field {
    margin-bottom: 15px;
  }
  .qb-details-section-item-field-name {
    font: normal 12px/15px Lato;
  }
  .qb-details-section-item-field-control {
    font: normal 14px/14px Lato;
    height: 30px;
  }
  .qb-details-section-item-field-control .ant-picker,
  .qb-details-section-item-field-control .ant-btn {
    height: 28px;
  }
  .qb-details-section-item-field-control textarea.ant-input {
    font: normal 14px/14px Lato;
    padding: 9px 0 0 14px;
    height: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 40px);
  }
  .qb-details-section-item-field-control.address-field {
    height: 44px;
  }
  .qb-details-singers {
    height: 90vh;
  }
  .qb-details-singers-subtitle {
    margin-top: 20px;
  }
  .qb-details-singers-col .qb-scroll {
    overflow: auto;
  }
  .qb-group-modal .ant-modal-content .ant-modal-header {
    padding: 10px 20px;
    border-bottom: 1px solid #a8a8a8;
  }
  .qb-group-modal .ant-modal-content .ant-modal-header .ant-modal-title .qb-group-modal-title-wrapper {
    display: flex;
    align-items: center;
  }
  .qb-group-modal .ant-modal-content .ant-modal-header .ant-modal-title .qb-group-modal-title-wrapper .qb-group-modal-title-left {
    position: unset;
  }
  .qb-group-modal .ant-modal-content .ant-modal-header .ant-modal-title .qb-group-modal-title-wrapper .qb-group-modal-title-left .qb-button {
    border: none;
    padding: 10px 20px 10px 0;
  }
  .qb-group-modal .ant-modal-content .ant-modal-header .ant-modal-title .qb-group-modal-title-wrapper .qb-group-modal-title-center {
    color: #000;
  }
  .qb-group-modal-title {
    font: normal normal bold 13px/16px Syne;
    letter-spacing: 0.65px;
    text-transform: uppercase;
  }
  .qb-group-modal .band-modal-content-ceremony-list-item {
    display: block;
    padding: 30px 0;
    border: none;
    border-bottom: 1px solid #ccc5c2;
  }
  .qb-group-modal .band-modal-content-ceremony-list-item-no-cat__icon {
    padding-top: 50%;
    position: relative;
    background-color: #dad3d0;
  }
  .qb-group-modal .band-modal-content-ceremony-list-item-no-cat__icon img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .qb-group-modal .band-modal-content-ceremony-list-item-no-cat__info {
    padding: 20px 36px;
    text-align: left;
  }
  .qb-group-modal .band-modal-content-ceremony-list-item-no-cat__info .band-modal-content-no-cat__title {
    font: normal normal bold 13px/16px Syne;
  }
  .qb-group-modal .band-modal-content-ceremony-list-item-no-cat__info .band-modal-content-no-cat__des {
    width: 100%;
    max-width: none;
    font: normal normal normal 11px/18px Montserrat;
    margin: 22px auto 30px;
  }
  .qb-logo {
    display: block;
    margin-top: 14px;
  }
  .qb-logo img {
    margin: auto;
    height: 47px;
    display: block;
  }
  .qb-logo.fixed_header {
    padding-top: 64px;
  }
  .qb-modal {
    width: 100% !important;
    top: 0;
    max-width: 768px;
    margin: 0 auto;
  }
  .qb-modal .intl-tel-input .country-list {
    bottom: 100%;
  }
  .qb-modal-title-left {
    flex-direction: column;
    align-items: flex-start;
    margin: -4px 0;
  }
  .qb-modal-title-left .qb-city-dropdown-trigger.qb-city-dropdown-trigger {
    margin: 0 0 10px;
  }
  .qb-modal.ant-modal {
    height: 850px;
    width: 100% !important;
    max-height: 100vh;
    max-height: -webkit-fill-available;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
  }
  .qb-modal.ant-modal .ant-modal-content {
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
  }
  .qb-modal.band-modal .ant-modal-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .qb-modal.band-modal .ant-modal-content .ant-modal-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
  .qb-modal.band-modal .ant-modal-content .ant-modal-body .band-modal-content:not(.qb-scroll) {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .qb-modal.band-modal .ant-modal-content .ant-modal-body .band-modal-content:not(.qb-scroll) .reception-modal-content.qb-scroll {
    overflow-y: auto;
    min-height: auto;
    max-height: auto;
  }
  .qb-modal.band-modal .ant-modal-content .ant-modal-body .band-modal-content:not(.qb-scroll) .reception-modal-content .swiper .swiper-slide.swiper-slide-active .reception-modal-item {
    position: unset;
  }
  .qb-modal.ceremony-modal.ant-modal {
    max-width: 600px;
  }
  .qb-modal.ceremony-modal .ant-modal-content {
    display: flex;
    flex-direction: column;
  }
  .qb-modal.ceremony-modal .band-modal-content {
    flex: 1;
  }
  .qb-modal-singers .ant-modal-body .qb-modal-singers__row {
    height: auto;
    overflow-y: auto;
  }
  .qb-modal-singers .ant-modal-body .qb-modal-singers__row .qb-modal-singers__col {
    height: auto;
  }
  .qb-modal-singers.ant-modal .qb-modal-title-left .qb-modal-title {
    font-size: 26px;
  }
  .qb-modal-singers.ant-modal .qb-modal-title-left .qb-city-dropdown-trigger.qb-city-dropdown-trigger {
    margin: 10px 0;
  }
  .qb-modal-singers.ant-modal .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: unset;
    flex: 1;
    max-height: unset;
    overflow: hidden;
  }
  .qb-modal-singers .ant-modal-body {
    display: flex;
    flex: 1;
    overflow: hidden;
    padding: 0;
  }
  .qb-modal-singers.qb-modal-singer-info .ant-modal-body {
    overflow: hidden;
  }
  .qb-modal-singers.qb-modal-singer-info .ant-modal-body .qb-singer-info {
    display: flex;
    overflow: hidden;
  }
  .qb-modal-singers.qb-modal-singer-info .ant-modal-body > div {
    flex: 1;
  }
  .qb-modal-singers-list.qb-modal-singers-list {
    padding: 0;
    max-height: unset;
    margin: 0;
  }
  .qb-modal-singers-list-choose-text {
    font: normal normal normal 10px/12px Lato;
  }
  .qb-modal-singers-list-choose .qb-modal-singers-music-icon {
    font-size: 25px;
    margin-bottom: 10px;
  }
  .qb-modal-singers-list-header {
    padding: 0 30px;
    transform: translateY(0);
    align-items: flex-start;
  }
  .qb-modal-singers-list-header .qb-modal-content-tabs {
    transform: translateY(-8px);
  }
  .qb-modal-singers-list-header .qb-modal-content-tabs .qb-modal-content-tabs-buttons span {
    font-size: 10px;
  }
  .qb-modal-singers-list.qb-scroll {
    padding-right: 0 !important;
    max-height: unset;
    min-height: unset;
    margin-right: 0;
    padding-left: 1px;
    padding-bottom: 30px;
  }
  .qb-modal-singers-list .ant-spin-container {
    grid-template-columns: 1fr 1fr;
    gap: 0;
  }
  .qb-modal-singers-list .ant-list-item {
    margin: 0;
  }
  .qb-modal-singers-list .ant-list-item.selected {
    box-shadow: none;
  }
  .qb-modal-singers-list .ant-list-item.selected .qb-singers-card.selected {
    border: 2px solid #ce2d41;
  }
  .qb-modal-singers-list-mobile {
    margin-bottom: 30px !important;
  }
  .qb-modal-singers-list-mobile .ant-list-item {
    padding: 12px 0 12px 12px;
    background: #f9f6f1;
    border-bottom: none !important;
    margin-bottom: 10px;
  }
  .qb-modal-singers-list-mobile .ant-list-item:last-child {
    margin-bottom: 0;
  }
  .qb-modal-singers-list-mobile .ant-list-item .ordinal-index {
    margin-right: 10px;
  }
  .qb-modal-singers-list-mobile .ant-list-item .alias {
    color: #ffffff;
  }
  .qb-modal-singers-list-mobile .ant-list-item .ant-btn-link {
    color: #ffffff;
  }
  .qb-modal-singers-list-mobile .ant-empty {
    display: none;
  }
  .qb-modal-singers-list .qb-singers-card .ant-card-body {
    justify-content: left;
    font: normal normal bold 8px/10px Syne;
  }
  .qb-modal-singers-check-icon {
    font-size: 17px;
    color: #ce2d41;
    margin-left: 10px;
  }
  .qb-modal-singers .ant-btn-text:hover {
    border-color: transparent;
  }
  .qb-modal-singers .qb-details-singers-subtitle {
    margin-top: 0;
  }
  .qb-modal-singer-info .qb-modal-content-subtitle {
    display: none;
  }
  .qb-modal-singer-info .ant-modal-body {
    padding: 0;
  }
  .qb-modal-content-subtitle.qb-modal-content-subtitle {
    font: normal normal bold 13px/16px Syne;
  }
  .qb-modal-notification {
    max-width: 500px;
    padding: 25px;
    text-align: center !important;
  }
  .qb-modal-notification .ant-modal-content {
    padding: 48px 20px;
  }
  .qb-modal-notification-image {
    width: 80%;
  }
  .qb-modal-notification-title {
    margin-bottom: 20px;
  }
  .qb-modal-notification-title.desktop {
    display: none;
  }
  .qb-modal-notification-title.mobile {
    display: block;
  }
  .qb-modal-notification-content {
    margin: 18px 0;
    font: normal normal normal 13px/22px Montserrat;
  }
  .qb-modal-notification .ant-modal-close svg {
    color: #a8a8a8;
  }
  .qb-modal-successful-notification {
    max-width: 500px;
    padding: 20px;
    text-align: center !important;
  }
  .qb-modal-successful-notification-image {
    width: 80%;
  }
  .qb-modal-successful-notification .ant-modal-content {
    padding: 48px 20px;
  }
  .qb-modal-successful-notification--request-callback-content {
    font: normal normal normal 13px/22px Montserrat;
  }
  .qb-modal-successful-notification--request-callback-title {
    margin-bottom: 20px;
  }
  .qb-modal-successful-notification--request-callback-title.mobile {
    display: block;
  }
  .qb-modal-successful-notification--request-callback-title.desktop {
    display: none;
  }
  .qb-modal-successful-notification--enquiry-content {
    font: normal normal normal 13px/22px Montserrat;
    margin: 30px 0;
  }
  .qb-modal-successful-notification--enquiry-title {
    margin-bottom: 20px;
  }
  .qb-modal-successful-notification--enquiry-title.mobile {
    display: block;
  }
  .qb-modal-successful-notification--enquiry-title.desktop {
    display: none;
  }
  .qb-modal-location-content-btns {
    margin: 14px 0;
  }
  .qb-modal-request-callback {
    max-width: 400px !important;
    padding: 20px !important;
    text-align: center !important;
    height: 760px !important;
  }
  .qb-modal-request-callback-image {
    width: 80%;
  }
  .qb-modal-request-callback-title {
    margin-bottom: 20px;
  }
  .qb-modal-request-callback-title.desktop {
    display: none;
  }
  .qb-modal-request-callback-title.mobile {
    display: block;
  }
  .qb-modal-request-callback-content {
    display: none;
  }
  .qb-modal-request-callback > .ant-modal-content > .ant-modal-body {
    padding: 30px 20px;
    overflow-y: auto;
  }
  .qb-modal-request-callback__form {
    margin: 20px 0;
  }
  .qb-modal-request-callback__form .ant-form-item-label {
    padding: 0;
  }
  .qb-modal-unlock-price {
    max-width: 400px !important;
    padding: 20px !important;
    text-align: center !important;
    height: 760px !important;
  }
  .qb-modal-unlock-price-note {
    text-align: left;
  }
  .qb-modal-unlock-price .qb-button {
    width: 100%;
  }
  .qb-modal-unlock-price > .ant-modal-content > .ant-modal-body {
    padding: 30px 20px;
    overflow-y: auto;
  }
  .qb-modal .ant-modal-header {
    padding: 10px 20px 10px 36px;
  }
  .qb-modal .ant-modal-header .qb-city-dropdown-trigger-name {
    margin-right: 30px;
  }
  .qb-modal .ant-modal-content {
    max-height: 100vh;
  }
  .qb-modal .ant-modal-title {
    font-size: 18px;
    line-height: 35px;
    letter-spacing: 1.3px;
  }
  .qb-modal .ant-btn-text {
    padding: 0;
    align-items: flex-start;
  }
  .qb-modal .ant-btn-text .font-awesome-icon.fa-times {
    font-size: 26px;
  }
  .qb-navbar-wrapper {
    justify-content: center;
    flex-direction: column-reverse;
    height: auto;
    align-items: center;
    padding: 0;
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
  }
  .qb-navbar-wrapper .qb-telephone-city-wrapper {
    width: 100%;
    justify-content: space-between;
    padding: 10px 15px;
    flex-wrap: wrap;
  }
  .qb-navbar-wrapper .qb-telephone-city-wrapper .tel {
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 600;
    margin: 5px 10px 5px 0;
  }
  .qb-navbar-wrapper .qb-telephone-city-wrapper button {
    margin: 5px 0;
  }
  .qb-navbar-wrapper .qb-city-dropdown-trigger {
    padding: 4px 18px;
    height: auto;
  }
  .qb-navbar-wrapper .qb-city-dropdown-trigger-name {
    font-size: 12px;
    font-weight: 600;
  }
  .qb-navbar-wrapper .qb-navbar-logo {
    padding: 10px;
    display: none;
  }
  .qb-navbar-wrapper .qb-navbar-logo img {
    margin: auto;
    height: 47px;
  }
  .qb-singer-info-card.qb-singer-info-card {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  .qb-singer-info-card.qb-singer-info-card .ant-card-body {
    padding: 16px 36px;
    flex: 1;
  }
  .qb-singer-info-card.qb-singer-info-card .qb-singer-info-add-btn {
    width: 100%;
  }
  .qb-singer-info-card.qb-singer-info-card .qb-singer-info-add-btn.un-select {
    background-color: #f9f6f1;
    color: #ce2d41;
  }
  .qb-singer-info-template__icon {
    margin-bottom: 0;
  }
  .qb-singer-modal-preferences-choose {
    padding: 15px 30px;
    display: flex;
    margin: 30px 0;
  }
  .qb-singer-modal-preferences-choose .qb-singer-info-template__icon {
    margin-right: 30px;
  }
  .qb-singer-modal-preferences-mobile {
    margin-bottom: 30px;
    background-color: #f9f6f1 !important;
  }
  .qb-singer-modal-preferences-mobile .qb-preference-item {
    margin-bottom: 10px;
  }
  .qb-singer-modal-preferences-mobile .qb-singer-preferences-minified__item {
    background-color: #f9f6f1;
    border: 1px solid #ccc5c2;
  }
  .qb-singer-modal-preferences-mobile .qb-singer-preferences-minified__item_left .ordinal-index,
  .qb-singer-modal-preferences-mobile .qb-singer-preferences-minified__item_left .alias {
    font: normal normal bold 9px/11px Syne;
    text-transform: uppercase;
    letter-spacing: 0.45px;
    color: #505050;
  }
  .qb-singer-modal-preferences-mobile-wrapper {
    padding: 30px 30px 0 30px;
  }
  .qb-singer-modal-preferences-mobile-wrapper .qb-details-singers-subtitle {
    min-width: 80%;
    width: 260px;
    margin-bottom: 20px;
  }
  .qb-singer-modal-preferences-mobile .qb-modal-singers__icon_plus {
    color: #bababa;
    cursor: pointer;
  }
  .qb-singer-modal-preferences-mobile .qb-modal-singers__icon_plus:active {
    color: #ffffff;
  }
  .band-modal-content-ceremony .ant-carousel .slick-slider.band-modal-content-ceremony-group.desktop {
    display: none;
  }
  .band-modal-content-ceremony-group {
    padding-top: 30px;
  }
  .band-modal-content-ceremony-group-item {
    padding: 0;
    margin: 0;
  }
  .band-modal-content-ceremony-group-item.has-current .band-modal-content-ceremony-group-item__button span,
  .band-modal-content-ceremony-group-item.has-current .band-modal-content-ceremony-group-item__button svg {
    color: #ce2d41;
  }
  .band-modal-content-ceremony-group-item.has-current .band-modal-content-ceremony-group-item__button span {
    font-style: normal;
    font-weight: 600;
  }
  .band-modal-content-ceremony-group-item__wrapper {
    border: none;
    padding: 0;
    background: #f9f6f1;
  }
  .band-modal-content-ceremony-group-item__thumb {
    width: 100%;
    height: auto;
    object-fit: cover;
    min-height: 161px;
    position: relative;
  }
  .band-modal-content-ceremony-group-item__thumb .font-awesome-icon {
    position: absolute;
    width: 50px;
    height: 50px;
    border: 1px solid #909090;
    border-radius: 50%;
    color: #909090;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
    display: block;
  }
  .band-modal-content-ceremony-group-item__name {
    text-align: left;
    padding: 15px 0;
    font-size: 13px;
    line-height: 1;
    color: #000;
  }
  .band-modal-content-ceremony-group-item__name span,
  .band-modal-content-ceremony-group-item__name svg {
    color: #a8a8a8;
  }
  .band-modal-content-ceremony-group-item__button {
    display: flex;
    float: right;
    padding: 0;
    height: auto;
    transform: translateY(-2px);
  }
  .band-modal-content-ceremony-group-item__button > span {
    margin-right: 8px;
    font-family: Montserrat;
    font-size: 11px;
    font-style: italic;
    color: #505050;
  }
  .band-modal-content-ceremony-group-item__button .font-awesome-icon {
    display: block;
    font-size: 10px;
  }
  .band-modal-content-ceremony-group-item-no-cat {
    background: #f9f6f1;
    display: flex;
    flex-direction: column;
    padding: 0;
    border: none;
    cursor: pointer;
  }
  .band-modal-content-ceremony-group-item-no-cat .band-modal-content-no-cat {
    display: flex;
    flex-direction: column;
  }
  .band-modal-content-ceremony-group-item-no-cat .band-modal-content-no-cat__icon-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dad3d0;
    min-height: 161px;
    width: 100%;
  }
  .band-modal-content-ceremony-group-item-no-cat .band-modal-content-no-cat__icon {
    margin: 0;
  }
  .band-modal-content-ceremony-group-item-no-cat .band-modal-content-no-cat__wrap-icon {
    border-radius: unset;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    height: 105px;
    padding: 0;
  }
  .band-modal-content-ceremony-group-item-no-cat .band-modal-content-no-cat__text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    width: 100%;
  }
  .band-modal-content-ceremony-group-item-no-cat .band-modal-content-no-cat__selected-label {
    color: #ce2d41;
    font-size: 11px;
    font-weight: 700;
  }
  .band-modal-content-ceremony-group-item-no-cat .band-modal-content-no-cat__selected-label span {
    margin-right: 6px;
  }
  .band-modal-content-ceremony-group-item-no-cat .band-modal-content-no-cat__title {
    text-transform: uppercase;
    font-size: 13px;
    color: #000;
    line-height: 1.4;
    margin: 0;
  }
  .band-modal-content-ceremony-group-item-no-cat .band-modal-content-no-cat__title .font-awesome-icon.active-icon {
    margin-left: 5px;
    display: none;
  }
  .band-modal-content-ceremony-group-item-no-cat.has-current .band-modal-content-no-cat__title {
    color: #000;
  }
  .band-modal-content-ceremony-group-item-no-cat.has-current .band-modal-content-no-cat__title .font-awesome-icon.active-icon {
    display: inline-block;
  }
  .band-modal-content-ceremony-group-item-no-cat .qb-button {
    padding: 0 15px;
  }
  .band-modal-content-ceremony-group-wrapper.desktop {
    display: none;
  }
  .band-modal-content-ceremony-group.mobile {
    display: grid;
    padding: 30px 36px 50px;
    grid-template-rows: repeat(4, 1fr);
  }
  .band-modal-content-ceremony-group.mobile::before {
    display: none;
  }
  .band-modal-content-ceremony-list-wrapper {
    display: none;
  }
  .band-modal-content-ceremony-list-item {
    display: none;
  }
  .band-modal-content-ceremony-list-item__header {
    display: block;
  }
  .band-modal-content-ceremony-list-item__video {
    margin-bottom: 10px;
  }
  .band-modal-content-ceremony-list-item__title {
    font-weight: 700;
    font-size: 13px;
    color: #000;
    font-family: Syne;
    margin: 20px 0 0;
    height: auto;
  }
  .band-modal-content-ceremony-list-item__price {
    align-items: flex-end;
  }
  .band-modal-content-ceremony-list-item__price-text {
    font: normal normal bold 12px/16px Syne;
  }
  .band-modal-content-ceremony-list-item__price-number {
    font: normal normal bold 12px/16px Syne;
    color: #000;
  }
  .band-modal-content-ceremony-list-item__price.mobile {
    display: block;
  }
  .band-modal-content-ceremony-list-item__description {
    font-size: 11px;
    line-height: 18px;
    color: #505050;
    height: auto;
  }
  .band-modal-content-ceremony-list-item__button {
    padding: 0 30px;
  }
  .band-modal-content-ceremony-list-item__button button.selected {
    background-color: white;
  }
  .band-modal-content-ceremony-no-cat {
    display: none;
  }
  .band-modal-content.qb-scroll {
    height: auto !important;
    min-height: unset !important;
  }
  .reception-modal-content {
    height: auto;
    padding: 0;
  }
  .reception-modal-content .swiper-slide-active.swiper-slide .reception-modal-item {
    margin-top: 0;
    top: unset;
    bottom: unset;
    transform: none;
  }
  .reception-modal-content > .swiper {
    width: 100%;
    height: auto;
    margin: 0 0 110px;
  }
  .reception-modal-content > .swiper .video-list-swiper {
    margin: 10px 0;
  }
  .reception-modal-content .swiper-controller {
    margin: 0 auto;
    max-width: 600px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #f9f6f1;
    border-top: 2px solid #6a6a6a;
  }
  .reception-modal-item {
    display: block;
    padding: 36px;
    min-width: 100%;
    border: none;
    border-bottom: 1px solid #ccc5c2;
  }
  .reception-modal-item::after {
    display: none;
  }
  .reception-modal-item .band-modal-content-no-cat {
    min-height: unset;
    padding: 0;
  }
  .reception-modal-item .band-modal-content-no-cat__centering {
    display: none;
    width: 100%;
  }
  .reception-modal-item .band-modal-content-no-cat__centering.mobile {
    display: block;
  }
  .reception-modal-item .band-modal-content-no-cat__centering.mobile .band-modal-content-no-cat__icon {
    width: 100%;
    position: relative;
    background-color: #dad3d0;
    max-width: unset;
    border-radius: 0;
    padding-top: 56.2313%;
  }
  .reception-modal-item .band-modal-content-no-cat__centering.mobile .band-modal-content-no-cat__icon img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .reception-modal-item .band-modal-content-no-cat__centering.mobile .band-modal-content-no-cat__title {
    text-align: left;
    font: normal normal bold 13px/16px Syne;
    letter-spacing: 0.65px;
    margin: 15px 0;
  }
  .reception-modal-item .band-modal-content-no-cat__centering.mobile .band-modal-content-no-cat__des {
    text-align: left;
    width: 100%;
    font: normal normal normal 11px/18px Montserrat;
    color: #505050;
    margin: 22px 0;
    max-width: unset;
  }
  .reception-modal-item-title {
    font: normal normal bold 13px/17px Syne;
    letter-spacing: 0.65px;
    margin-top: 0;
  }
  .reception-modal-item-price {
    font: normal normal bold 9px/18px Syne;
    margin-bottom: 20px;
    text-align: right;
  }
  .reception-modal-item-video-wrapper {
    width: 100%;
    margin: 0 auto;
  }
  .reception-modal-item .testimonial {
    display: block;
    padding: 0;
    background: #f9f6f1;
  }
  .reception-modal-item .testimonial-title {
    display: none;
  }
  .reception-modal-item .testimonial-item {
    margin: 0;
    background: #262626;
  }
  .reception-modal-item-detail {
    overflow: unset;
    position: relative;
    width: 100%;
    padding: 0;
    margin: 10px 0;
  }
  .reception-modal-item-detail-scroll {
    max-height: 500px;
    overflow: unset;
  }
  .reception-modal-item-detail-scroll .reception-modal-item-detail-header {
    display: flex;
    flex-direction: column;
  }
  .reception-modal-item-detail-scroll .reception-modal-item-detail-header .qb-button-change {
    width: 100%;
    margin-bottom: 20px;
    padding: 11px 10px 8px;
    font: normal normal bold 12px/14px Montserrat;
    letter-spacing: 0.72px;
  }
  .reception-modal-item-detail-scroll .reception-modal-item-des {
    margin: 0 -10px;
    padding: 0 10px;
  }
  .reception-modal-item.expanded .reception-modal-item-detail {
    padding-bottom: 0;
  }
  .reception-modal-item-show-more-box.show-more,
  .reception-modal-item.expanded-show-more-box.show-more,
  .reception-modal-item-show-more-box.show-less,
  .reception-modal-item.expanded-show-more-box.show-less {
    display: none;
  }
  .swiper-controller {
    position: relative;
    transform: none;
    width: 100%;
    right: auto;
    top: auto;
    padding: 20px 30px;
    height: 110px;
    display: block;
  }
  .swiper-controller .swiper-pagination {
    flex-direction: row;
    width: 100%;
    height: 20px;
    justify-content: space-between;
  }
  .swiper-controller .swiper-pagination .swiper-pagination-bullet {
    margin: 0;
  }
  .swiper-controller .swiper-arrow {
    position: absolute;
    bottom: 0;
    flex-direction: row;
    align-items: center;
    right: 30px;
  }
  .swiper-controller .swiper-arrow.swiper-arrow-prev {
    left: 30px;
    right: auto;
    margin-top: 0;
    margin-bottom: 35px;
  }
  .swiper-controller .swiper-arrow .fa-chevron-left {
    display: block;
    margin: 0 25px 0 0;
  }
  .swiper-controller .swiper-arrow .fa-chevron-right {
    display: block;
    margin: 0 0 0 25px;
  }
  .swiper-controller .swiper-arrow .fa-chevron-down,
  .swiper-controller .swiper-arrow .fa-chevron-up {
    display: none;
  }
  .swiper-controller .swiper-arrow-left,
  .swiper-controller .swiper-arrow-right {
    display: block;
  }
  .swiper-controller .swiper-arrow-top,
  .swiper-controller .swiper-arrow-bottom {
    display: none;
  }
  .swiper-video-arrow {
    display: none !important;
  }
}
@media (max-width: 1199px) and (min-width: 1024px) and (orientation: landscape) {
  .band-modal-content-ceremony-list-item__price {
    display: none;
  }
  .band-modal-content-ceremony-list-item__price-line .ant-col-lg-12 {
    max-width: 100% !important;
    flex: 0 0 100% !important;
    margin-bottom: 1rem;
  }
  .band-modal-content-ceremony-list-item__description {
    margin-bottom: 0 !important;
    height: 90px;
  }
}
@media (max-width: 991px) {
  .detail-modal {
    height: auto;
    max-width: 576px;
  }
  .detail-modal.unlock-price-modal {
    height: auto;
    width: 100% !important;
  }
  .detail-modal.unlock-price-modal .ant-modal-close-x {
    color: black;
  }
  .detail-modal .ant-form {
    padding: 40px 30px 25px;
  }
  .detail-modal .ant-form-item .qb-button {
    margin: 0;
    width: 100%;
    font: normal normal bold 14px/16px Lato;
    height: auto;
    padding: 8px 10px;
  }
  .qb-details-section-gigs-head {
    margin-top: 0;
  }
  .qb-details-section-no-cat__icon {
    left: 50%;
    transform: translateX(-50%);
  }
  .qb-details-section.qb-scroll {
    height: auto;
  }
  .qb-details-item-1st-view-icon .svg-inline--fa {
    transform: rotate(-90deg);
  }
}
@media (max-width: 767px) {
  .detail-modal {
    max-width: 100%;
  }
  .ant-modal {
    margin: 0;
  }
}
@media (max-width: 575px) {
  .qb-details-title {
    padding: 0 30px;
    margin: 4px 0 24px;
  }
  .qb-details-section-title {
    margin-bottom: 10px;
  }
  .qb-details-section .mobile-item {
    width: 95%;
  }
  .qb-details-section-gigs-head {
    flex-direction: column;
  }
  .qb-details-section .qb-singer-preferences-top {
    display: block;
    margin-bottom: 15px;
  }
  .qb-details-section .qb-singer-preferences-top .qb-button {
    width: 100%;
  }
  .qb-details-section .qb-price-submitted .qb-price-button-wrapper .qb-button {
    width: 100%;
  }
  .qb-details-item-price {
    display: flex;
    align-items: center;
  }
  .qb-details-item-price-text {
    font-size: 9px;
    margin-right: 4px;
    color: #505050;
  }
  .qb-details-item-price-number {
    font-size: 9px;
    color: #505050;
  }
  .qb-details-item-field-control-duration-decrease,
  .qb-details-item-field-control-duration-increase {
    width: 20%;
  }
  .qb-callback-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .qb-callback-text {
    font-size: 14px;
    line-height: 16px;
  }
  .qb-callback-right .qb-button {
    margin: 0 auto;
    padding: 0 40px;
  }
  .qb-modal-notification {
    max-width: 100%;
  }
  .qb-modal-successful-notification {
    max-width: 100%;
  }
  .reception-modal-content > .swiper {
    margin: 0 0 64px;
  }
  .detail-modal-banner img.desktop {
    display: none;
  }
  .detail-modal-banner img.mobile {
    display: block;
  }
  .detail-modal-form-title {
    font: normal normal normal 18px/36px Lato;
  }
  .detail-modal-form-des {
    font: normal normal normal 14px/16px Lato;
    margin-bottom: 30px;
  }
  .control-video-slider .control-video-slider-arrow {
    padding: 2px;
  }
  .control-video-slider .control-video-slider-arrow svg {
    font-size: 14px;
  }
  .swiper-controller {
    padding: 10px 20px 20px;
    height: 64px;
  }
  .swiper-controller .swiper-arrow {
    font: normal normal bold 10px/12px Syne;
  }
  .swiper-controller .swiper-arrow.swiper-arrow-prev {
    left: 20px;
    margin-bottom: 10px;
  }
  .swiper-controller .swiper-arrow.swiper-arrow-prev .fa-chevron-left {
    margin-right: 5px;
  }
  .swiper-controller .swiper-arrow.swiper-arrow-next {
    right: 20px;
    margin-bottom: 10px;
  }
  .swiper-controller .swiper-arrow.swiper-arrow-next .fa-chevron-right {
    margin-left: 5px;
  }
  .swiper-controller .swiper-pagination {
    height: 12px;
  }
  .swiper-controller .swiper-pagination .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
  }
  .band-modal-content-ceremony-group-item-no-cat .band-modal-content-no-cat__icon {
    background-color: transparent;
  }
  .band-modal-content-ceremony-group-item-no-cat .band-modal-content-no-cat__icon .font-awesome-icon {
    border-width: 3px;
    height: 35px;
    width: 35px;
  }
  .band-modal-content-ceremony-group-item-no-cat .band-modal-content-no-cat__text .qb-button {
    height: 28px;
  }
  .no-cat__title {
    font-size: 12px;
    line-height: 1.4;
  }
}
#qbDetails .ant-tabs-ink-bar {
  background: #ce2d41;
  height: 3px;
}
.qb-modal-unlock-price .ant-modal-body::-webkit-scrollbar,
.qb-modal-notification .ant-modal-body::-webkit-scrollbar,
.qb-modal-request-callback .ant-modal-body::-webkit-scrollbar,
.qb-modal-successful-notification .ant-modal-body::-webkit-scrollbar {
  margin-top: 0;
  padding-top: 0;
  width: 6px;
  height: 6px;
  border-radius: 10px;
}
.qb-modal-unlock-price .ant-modal-body::-webkit-scrollbar-thumb,
.qb-modal-notification .ant-modal-body::-webkit-scrollbar-thumb,
.qb-modal-request-callback .ant-modal-body::-webkit-scrollbar-thumb,
.qb-modal-successful-notification .ant-modal-body::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #ccc5c2;
}
@media (min-width: 992px) {
  .qb-modal-unlock-price .ant-modal-body:hover::-webkit-scrollbar-thumb,
  .qb-modal-notification .ant-modal-body:hover::-webkit-scrollbar-thumb,
  .qb-modal-request-callback .ant-modal-body:hover::-webkit-scrollbar-thumb,
  .qb-modal-successful-notification .ant-modal-body:hover::-webkit-scrollbar-thumb {
    background: #afacac;
  }
}
.video-list-swiper .swiper-video-arrow {
  padding: 6px;
}
.tc-back-button {
  margin-left: 20px;
}
.tc-button__wrapper {
  padding-left: 36px;
  text-align: center;
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.tc-button__wrapper .button-primary {
  margin: 4px;
}
.tc-header-tac-border-bottom {
  text-align: center;
  border-bottom: 1pt solid black;
  font-size: 16px;
  font-weight: 700;
}
.tc-header {
  margin-left: 35.45pt;
  text-indent: -0.25in;
}
.tc-header + .tc-list-item__2 {
  margin-top: 0;
}
.tc-horizontal-tab {
  margin: 0;
  font-size: 1.2rem;
}
.tc-list-item-us__1 {
  margin-top: 12pt;
  margin-left: 20pt;
  margin-bottom: 3pt;
  text-indent: -15pt;
  page-break-after: avoid;
  font-size: 1.2rem;
}
.tc-list-item__1 {
  margin-top: 12pt;
  margin-left: 35.45pt;
  margin-bottom: 3pt;
  text-indent: -18pt;
  page-break-after: avoid;
  font-size: 1.2rem;
}
.tc-list-item__2 {
  margin: 6pt 0 0 58pt;
  text-align: left;
  text-indent: -20pt;
  line-height: 12pt;
}
.tc-list-item__3 {
  margin: 6pt 0 0 78pt;
  text-align: left;
  text-indent: -0.25in;
  line-height: 12pt;
}
.tc-modal {
  top: 100px;
  height: auto;
}
.tc-modal .ant-modal-body {
  max-height: unset;
  padding-left: 15px;
}
@media only screen and (max-width: 1199px) {
  .tc-modal {
    top: 80px;
  }
}
.tc-plain-text {
  font-size: 9pt;
  font-family: 'Arial', sans-serif;
  color: black;
}
.tc-plain-text-bold {
  font-weight: bold;
}
.tc-paragraph {
  margin-left: 17pt;
  text-align: justify;
  line-height: 12pt;
}
.tc-page {
  background-color: #ffffff;
  width: 100%;
  min-height: 100vh;
  max-width: 120rem;
  padding: 2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.tc-tabulation {
  font: 7pt 'Times New Roman';
}
.tc-wrapper h1 {
  font-size: 16px;
  font-weight: 700;
}
.tc-wrapper h2 {
  font-size: 16px;
  font-weight: 700;
}
.tc-wrapper table td {
  border: 1px solid black;
  vertical-align: top;
}
.download-tc {
  color: #b7964e;
}
.open-invites-buttons-wrapper {
  padding-left: 20px;
  white-space: nowrap;
}
.open-invites-buttons-wrapper .action-icon-button {
  background-color: transparent;
}
.update-active-statuses-modal__button-primary {
  margin: auto;
}
.skill-selector .ant-select-selector {
  height: auto;
  min-height: 36px;
}
.user-avatar-image {
  border-radius: 50%;
  height: 120px;
  vertical-align: middle;
  line-height: 120px;
  width: 120px;
}
.user-avatar-button {
  margin-left: 16px;
  vertical-align: middle;
}
.user-status {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 24px;
  line-height: 1;
}
@media not all and (min-width: 576px) {
  .venues-map-wrapper {
    flex-direction: column-reverse;
  }
}
@media not all and (min-width: 576px) {
  .recent-column {
    margin-top: 20px;
  }
  .recent-column.empty {
    display: none;
  }
}
.recent-title {
  background-color: #fafafa;
  border-bottom: thin solid #e6e6e6;
  text-align: center;
  padding: 1rem;
  font-size: 1.4rem;
  color: #595959;
}
/* containers/Artists/SingerContainer.jsx */
.singers {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.singers-board {
  flex: 1;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0 1px 15px #0003;
  padding: 16px;
  overflow: hidden;
}
.singers-board > .ant-row {
  height: 100%;
}
.singers-board > .ant-row > .ant-col {
  height: 100%;
}
@media screen and (max-width: 1199px) {
  .singers-board {
    box-shadow: none;
  }
}
@media screen and (max-width: 1199px) {
  .singers {
    height: calc(100vh - 6rem - 4px);
  }
}
@media screen and (max-width: 575px) {
  .singers {
    height: 100%;
  }
  .singers-board {
    height: auto;
  }
  .singers-board > .ant-row {
    height: auto;
  }
}
/* components/Artists/Singers/PublishedSingerProfiles.jsx */
.published-singer-profiles {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.published-singer-profiles ul {
  list-style-type: none;
  padding: 0 10px 0 0;
  height: calc(100% - 100px);
  margin: 10px 10px 0 0;
  overflow: auto;
}
.published-singer-profiles ul .ant-checkbox-wrapper {
  line-height: 1;
}
.published-singer-profiles ul::-webkit-scrollbar-thumb {
  background: #b7964e;
}
.published-singer-profiles ul > li {
  display: flex;
  border-bottom: thin solid #e6e6e6;
  padding: 10px 10px;
}
.published-singer-profiles ul > li > p {
  margin-left: 14px;
  color: #2f2f2f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 1.5;
}
@media screen and (max-width: 575px) {
  .published-singer-profiles {
    margin-bottom: 20px;
  }
  .published-singer-profiles ul {
    height: 50vh;
    margin: 0;
    padding: 0;
  }
}
/* components/Artists/Singers/DndDisplayedSinger */
.singers-displayed {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.singers-displayed__dnd {
  margin: 10px 10px 0 0;
  padding: 0 10px 0 0;
  height: calc(100% - 100px);
  overflow: auto;
}
.singers-displayed__dnd::-webkit-scrollbar-thumb {
  background: #b7964e;
}
.singers-displayed__dnd__item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  padding: 10px 0 10px;
}
.singers-displayed__dnd__item--name {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.singers-displayed__dnd__item--name-alias {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2f2f2f;
  font-size: 14px;
}
.singers-displayed__dnd__item--name svg {
  margin-right: 10px;
}
.singers-displayed__dnd__item--close-btn {
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  cursor: pointer;
  padding: 0 20px;
}
.singers-displayed__dnd__item--close-btn svg {
  color: #4a4a4a;
}
@media screen and (max-width: 575px) {
  .singers-displayed__dnd {
    height: 50vh;
    margin: 0;
    padding: 0;
  }
}
html {
  font-size: 62.5%;
}
body {
  background: transparent;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}
.rk-scrollbar-horizontal.rk-scrollbar-horizontal {
  padding-bottom: 2rem;
}
.rk-scrollbar-horizontal.rk-scrollbar-horizontal::-webkit-scrollbar {
  height: 12px;
  background-color: #d9d9d9;
  margin-top: 2rem;
  padding-top: 2rem;
}
.rk-scrollbar-horizontal.rk-scrollbar-horizontal::-webkit-scrollbar-thumb {
  background-color: #b7964e;
  border-top: 4px solid white;
  border-bottom: 4px solid white;
}
.rk-scrollbar-horizontal.rk-scrollbar-horizontal::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-top: 4px solid white;
  border-bottom: 4px solid white;
}
.rk-scrollbar-horizontal.rk-scrollbar-horizontal::-webkit-scrollbar-button {
  background-color: white;
  display: block;
  height: 0.5rem;
  width: 100%;
}
.rk-scrollbar-horizontal.rk-scrollbar-horizontal:hover::-webkit-scrollbar-thumb {
  border-top: 4px solid #b7964e;
  border-bottom: 4px solid #b7964e;
}
.rk-scrollbar-horizontal.rk-scrollbar-horizontal:hover::-webkit-scrollbar-track {
  border-top: 4px solid #f1f1f1;
  border-bottom: 4px solid #f1f1f1;
}
.rk-scrollbar-vertical.rk-scrollbar-vertical {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  padding-right: 1.8rem;
  overflow-y: auto;
  flex: 1;
}
.rk-scrollbar-vertical.rk-scrollbar-vertical::-webkit-scrollbar {
  width: 0.2rem;
  height: 0.2rem;
  background-color: #d9d9d9;
}
.rk-scrollbar-vertical.rk-scrollbar-vertical::-webkit-scrollbar-thumb {
  background-color: #b7964e;
}
.rk-scrollbar-vertical.rk-scrollbar-vertical::-webkit-scrollbar-button {
  background-color: white;
  display: block;
  height: 0.5rem;
  width: 100%;
}
.rk-scrollbar-vertical.rk-scrollbar-vertical:hover::-webkit-scrollbar {
  width: 0.8rem;
}
@media (max-width: 1200px) {
  .rk-scrollbar-vertical.rk-scrollbar-vertical {
    margin-right: 0;
    padding-right: 0;
  }
}
@media (max-width: 992px) {
  .rk-scrollbar-vertical.rk-scrollbar-vertical {
    width: 98%;
  }
  .rk-scrollbar-vertical.rk-scrollbar-vertical:hover::-webkit-scrollbar {
    width: 0.2rem;
  }
}
@media screen and (max-width: 991px) {
  #freshworks-container {
    visibility: hidden;
    overflow: hidden;
    width: 0;
    height: 0;
  }
  #freshworks-container iframe {
    visibility: hidden !important;
    overflow: hidden !important;
    width: 0 !important;
    height: 0 !important;
  }
}
