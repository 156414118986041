.footer {
  &-container {
    padding: 50px 0;
    background-color: @QuoteBuilderBackground;
    color: @QuoteBuilderGreyFont;
    text-align: center;
    z-index: 100;
    position: relative;

    &-info {
      display: flex;
      justify-content: space-between;
    }

    &.has-sticky-nav {
      padding-bottom: 190px;
    }
  }

  &-social_link {
    && {
      width: 48px;
      height: 48px;
      line-height: 52px;
      background-color: @White;
      border-radius: 50%;
      display: inline-block;
      color: #373737;
      margin: 0 7px;
      font-size: 19px;

      &:hover,
      &:focus,
      &:active {
        background-color: #b7e6dc;
        color: #373737;
      }
    }
  }

  &-separator {
    margin-bottom: 0;
    background-color: transparent;
    margin-top: 15px;
  }

  &-copyright {
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
    color: @Black;
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-right: 46px;
      display: inline-block;
      outline: none;

      &:last-child {
        margin-right: 0;
      }

      &:hover,
      &:focus,
      &:active {
        outline: none;
      }

      a {
        color: @White;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1px;
        font-size: 13px;
        transition: color 0.3s ease-in-out;
        outline: none;
      }

      a:hover,
      a:focus,
      a:active {
        color: #818181;
        text-decoration: none;
        outline: none;
      }

      @media only screen and (max-width: 1000px) {
        margin-right: 7px;

        a {
          font-size: 11px;
        }
      }

      @media only screen and (max-width: @screen-md) {
        width: 100% !important;
      }
    }
  }
}
