.gig-roster {
  background: white;

  &-statusbar-wrapper {
    &:extend(.statusbar-details__wrapper);
    display: flex;
  }

  .collapse-panel__header-wrapper {
    .subsection-title();
    .padding-horizontal(10px);
  }

  .collapse-panel-expanding-icon {
    padding: 0.6rem 0;

    svg {
      color: @Primary;
    }
  }

  .collapse-panel-expanding-area {
    padding: 0 20px;
  }

  &-mobile {
    &-wrapper {
      padding: 0 8px;
      &:first-child {
        border-top: thin solid @Grey;
      }

      &.empty {
        background-color: @LightYellow;
      }
    }

    &-performance-name {
      margin-bottom: 4px;
    }

    &-name {
      margin-bottom: 8px;
    }

    &-time {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }

  &-nav {
    &-mobile {
      background: #fff;
      border-bottom: thin solid #e6e6e6;
    }

    &-wrapper {
      .statusbar-styles();
      width: calc(100% - 20px);
      @media not all and (min-width: @screen-md) {
        width: 100%;
      }

      @media not all and (min-width: @screen-xs) {
        justify-content: space-around;
      }
    }

    &-arrow {
      color: @Primary;
      cursor: pointer;
      white-space: nowrap;
      display: inline-flex;
      border: none;
      outline: none;
      padding: 0;
      min-width: 32px;
      align-items: center;
      justify-content: center;

      @media (min-width: @screen-xl) {
        padding-left: 15px;
        padding-right: 15px;
      }

      .fa-chevron-left {
        margin-left: 1.2rem;
        @media not all and (min-width: @screen-xl) {
          margin-left: 0;
        }
      }

      .fa-chevron-right {
        margin-right: 1.2rem;
        @media not all and (min-width: @screen-xl) {
          margin-right: 0;
        }
      }

      &:hover {
        opacity: 0.7;
      }
    }

    &-buttons-label {
      @media not all and (min-width: @screen-xl) {
        display: none !important;
      }
    }

    &-form {
      @media not all and (min-width: @screen-xl) {
        margin: 0 10px !important;
      }

      @media not all and (min-width: @screen-xs) {
        margin: 0 !important;
      }

      .ant-form-item-label {
        @media not all and (min-width: @screen-lg) {
          display: none !important;
        }
      }

      .ant-form-item {
        margin-bottom: 0;
      }

      .ant-form-item-control {
        width: 28rem;

        @media not all and (min-width: @screen-xs) {
          width: 24rem;
        }
      }
    }
  }
}
