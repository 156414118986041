.musician-booking-switch-wrap {
  display: flex;
  margin-bottom: 7px;
}

.switching-bar {
  &-item {
    margin-right: 1rem;

    &.active {
      position: relative;

      &:after {
        position: absolute;
        content: '';
        display: unset;
        width: 100%;
        height: 1px;
        background-color: @DarkGrey;
        top: calc(100% + 0.8rem);
        left: 0;
      }
    }

    &.last {
      margin-right: 0;
    }

    &-wrap {
      user-select: none;
      line-height: 1;
      font-size: @UsualBookingFZ;
      cursor: pointer;
      font-weight: 400;
      color: @Primary;
      white-space: nowrap;
      padding: 0;
      height: auto;

      &:hover {
        color: @PrimaryHover;
      }

      &.pale {
        color: @DarkGrey;
      }

      &.active {
        font-weight: 700;
        color: @Primary;
      }

      &.disabled {
        pointer-events: none;
      }

      &.last {
        margin-right: 0;
      }
    }
  }
}
