.sidebar {
  &-menu {
    && {
      height: calc(100vh - 158px);
      overflow: auto;

      .ant-menu-item.ant-menu-item-selected {
        font-weight: bold;
      }

      &.ant-menu-dark,
      &.ant-menu-dark .ant-menu-sub {
        background-color: @Sidebar;
      }
    }
  }

  &-wrapper {
    && {
      position: fixed;
      top: 0;
      left: 0;
      min-height: 100vh;
      max-width: 200px;
      min-width: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.sidebar-menu {
  .ant-menu-submenu-arrow::before {
    display: none;
  }

  .ant-menu-submenu-arrow::after {
    display: none;
  }
}

.sidebar-menu.customize-sidebar .ant-menu-submenu-open {
  color: rgba(255, 255, 255, 0.65);
}
