.artist-details {
  &-checkbox {
    &__label {
      color: gray;
    }

    &__form {
      &-item {
        @media (max-width: @screen-xs-max) {
          margin-bottom: 0;
        }
      }

      &-column {
        padding-top: 34px;

        @media (max-width: @screen-xs-max) {
          padding-top: 0;
        }
      }
    }
  }

  &__form {
    .button-primary {
      @media (max-width: @screen-lg-max) {
        margin: 0 auto;
      }
    }

    &-button-wrapper {
      text-align: center;
      margin-top: 1.6rem;

      @media (max-width: @screen-lg-max) {
        margin: 1.6rem auto 0;
      }
    }

    .ant-checkbox {
      top: 2px;
    }
  }
}

.ant-form-vertical .user-preference-checkbox.ant-form-item {
  flex-direction: row;
}

.musician-preferences-check-list .ant-form-item-control-input-content {
  padding-bottom: 8px;
  float: right;
}

.musician-bio-action-button {
  display: flex;
  margin-left: auto;
}

.musician-bio-page-section {
  height: auto !important;
}

.media-content-wrapper {
  background: #ececec;
  padding: 10px;
  margin-bottom: 10px;
}

@media (max-width: 575px) {
  .ant-form.musician-preferences-check-list {
    .ant-form-item .ant-form-item-control {
      max-width: 15% !important;
    }

    .ant-form-item.musician-preferences-input .ant-form-item-control {
      max-width: 100% !important;
    }

    .ant-form-item .ant-form-item-label {
      max-width: 85% !important;
    }
  }
}

.musician-private-note {
  text-align: left;
  font: normal normal normal 12px/15px Lato;
  letter-spacing: 0;
  color: #9b9b9b;
  opacity: 1;
  padding: 2px 0 8px 0;
}

.private-note-label .ant-form-item-label {
  padding: 0;
}

.user-avatar-image {
  background-color: transparent;
}
