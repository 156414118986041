.sidebar {
  &-splitted {
    &-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;
    }
  }
}
