.dn {
  display: none;
}

.tac {
  text-align: center !important;
}

.ttu {
  text-transform: uppercase;
}

.ttc {
  text-transform: capitalize;
}

.ma {
  margin: auto;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-40 {
  margin-left: 40px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-10 {
  padding-right: 10px;
}

.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-40 {
  margin-right: 40px;
}

.p-8 {
  padding: 8px;
}

.ph-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.ph-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pt-10 {
  padding-top: 10px;
}

.mh-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mh-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mh-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mb-40 {
  margin-bottom: 40px;
}

.h-36 {
  height: 36px;
}

.w-100 {
  width: 100%;
}

.ws-nowrap {
  white-space: nowrap;
}

[data-rbd-drag-handle-context-id='0'] {
  outline: none;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  &:extend(.ws-nowrap);
}

.statusbar-styles {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  height: 60px;
  width: calc(100% - 100px);
}

.statusbar-details__wrapper {
  display: none;

  @media (min-width: @screen-xl) {
    .statusbar-styles();
  }
}

.subsection-title {
  background-color: @DeartyWhite;
  padding: 1.4rem 1.6rem 0.7rem;
  border-bottom: thin solid @Grey;
  margin-bottom: 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 60px;

  @media (min-width: @screen-lg) {
    padding: 2.5rem 1rem 0.3rem;
  }
}

.table-status__cell {
  border: none;
  box-shadow: none;
  outline: none;
  font-size: @UsualBookingFZ;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.rk-title-wrap {
  color: @TransparentBlack;
  font-size: 18px;
  font-family:
    Josefin Sans,
    sans-serif;
}

.card-shadow {
  box-shadow: 0 5px 10px @BoxShadowColor;
}

.desktop-item {
  display: none;
}

.mobile-item {
  display: block;
}

@media (min-width: @screen-lg) {
  .desktop-item {
    display: block;
  }

  .mobile-item {
    display: none;
  }
}
